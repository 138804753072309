import React from 'react'
import './conponents.css'

const UrlBtnTitle = (props) => {


  return (
      <div className="UrlBtnTitle">
        <div className="subTitle">TheBitcoinStandardDAO NFT</div>
        <a className="subButton" href="https://opensea.io/collection/thebitcoinstandarddao-arb">NFTs on Opensea</a>
      </div>
  )
}
export default UrlBtnTitle
