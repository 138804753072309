import './Show.css'

import React, {useCallback, useEffect, useState} from 'react'
import {close, DOG_HOST_URL,} from '../../../constants/addresses'
import {useWeb3React} from '@web3-react/core'
import flow from '../../../assets/img/flow.png'
import logo from "../../../assets/img/logo.png";
import {NavLink} from "react-router-dom";
import {shortenAddress} from "../../../utils";
import {CopyToClipboard} from "react-copy-to-clipboard";
import { message } from 'antd'
import useInterval from "../../../hooks/useInterval";

const Home = () => {
  const isDidOpen = true;
  const { account } = useWeb3React()

  const copyOk = () => {
    if(close)return;
    message.success('Copy successfully')
  }

    const [myAvater, setMyAvater] = useState(false);

    const getEnsList = useCallback(async () => {
        if(!account)return;
        try{
            const list = await fetch(DOG_HOST_URL + '/api/did/myUid?address=' + account.toString().toLowerCase())
            const resList = await list.json()
            if (resList['code'] === 1) {
                const listRow = resList['data'];
                if(listRow.length > 0){
                    const image = listRow[0].mate;
                    setMyAvater(image);
                }
            }
        }catch (e) {
            console.error(e);
        }
    }, [account, setMyAvater, ]);
    useInterval(getEnsList, 2000);

  return (
    <div className="show">
      <div className="text">Metadesocial</div>

      <div className="fast-border">
        <div className="fast-item">
          <div className="box_logo">
            <img src={myAvater ? myAvater : logo} alt="" />
          </div>
          <p className="text"> </p>
          <NavLink
              to={'/MintUnifiedDIDPassandairdropTheBitcoinStandardDaoNFT'}
              onClick={
                isDidOpen
                    ? null
                    : (e) => {
                      e.preventDefault()
                    }
              }
          >
            <div className={isDidOpen ? 'btn' : 'btn btn_dis'}>
              {isDidOpen ? 'Name/Earn' : 'Comming soon'}
            </div>
          </NavLink>

          <NavLink
              to={'/ethAll'}
              onClick={
                isDidOpen
                    ? null
                    : (e) => {
                      e.preventDefault()
                    }
              }
          >
            <div className={isDidOpen ? 'btn' : 'btn btn_dis'}>
              {isDidOpen ? 'Metaverse' : 'Comming soon'}
            </div>
          </NavLink>


        </div>
      </div>
    </div>
  )
}
export default Home
