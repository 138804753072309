import React, {useCallback, useState} from "react";
import useInterval from "../../../hooks/useInterval";
import {NavLink} from "react-router-dom";
import TwtLogin from "../../../components/TwtLogin";

const Collections = () => {

    const API_SERVER = process.env.REACT_APP_API_SERVER;
    const [facetInscList, setFacetInscList] = useState([]);


    const getInscList = useCallback(async () => {
        try{
            console.info('API_SERVER=' + process.env.REACT_APP_API_SERVER);
            console.info('API_SERVE2R=' + process.env.REACT_APP_ENABLE_TESTNETS);

            const list = await fetch(API_SERVER + '/ethscription/allPoint/topFacetInsc')
            const resList = await list.json()
            if (resList['code'] === 200) {
                const listRow = resList['data'];
                setFacetInscList(listRow);
            }
        }catch (e) {
            console.error(e);
        }
    }, [setFacetInscList, ]);

    const fetchLastTime = useCallback(async () => {
        try {
            await getInscList();
        } catch (e) {
            console.log(e);
        }
    }, [ getInscList,]);
    useInterval(fetchLastTime);

  return (
    <>
      <div className='sm:px-16 flex flex-col w-full  items-center h-full body-content'>
          <TwtLogin project={"allPoint"}/>

          <div className="flex flex-col w-full items-center py-8 gap-8">
                  <section className="flex flex-col w-full gap-8">
                      <h1 className="font-black tracking-tight mb-0 text-4xl md:text-4xl">Rubbings collections</h1>
                      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                              <div className="overflow-hidden">
                                  <table className="min-w-full text-left">
                                      <thead className="border-b dark:border-neutral-500">
                                      <tr>
                                          <th scope="col"
                                              className="px-6 py-4 whitespace-nowrap text-gray-600 text-sm font-normal">Collection
                                          </th>
                                          <th scope="col"
                                              className="px-6 py-4 whitespace-nowrap text-gray-600 text-sm font-normal">24h
                                              Volume
                                          </th>
                                          <th scope="col"
                                              className="px-6 py-4 whitespace-nowrap text-gray-600 text-sm font-normal">24h
                                              Sales
                                          </th>
                                          <th scope="col"
                                              className="px-6 py-4 whitespace-nowrap text-gray-600 text-sm font-normal">Floor
                                              Price
                                          </th>
                                          <th scope="col"
                                              className="px-6 py-4 whitespace-nowrap text-gray-600 text-sm font-normal">Total Amount
                                          </th>
                                          <th scope="col"
                                              className="px-6 py-4 whitespace-nowrap text-gray-600 text-sm font-normal">Unique
                                              Owners
                                          </th>
                                          <th scope="col"
                                              className="px-6 py-4 whitespace-nowrap text-gray-600 text-sm font-normal">Total
                                              Volume
                                          </th>
                                          <th scope="col"
                                              className="px-6 py-4 whitespace-nowrap text-gray-600 text-sm font-normal">Total
                                              Sales
                                          </th>
                                      </tr>
                                      </thead>
                                      <tbody>

                                      {facetInscList.map((item, i) => {
                                          return (
                                      <tr key={'id_' + i} className="border-b dark:border-neutral-500 cursor-pointer hover:bg-gray-100">
                                          <td className="whitespace-nowrap px-6 py-4">
                                              <NavLink key={i}  to={'/Rubbings/'+item['groupId']}>
                                              <div className="flex flex-row items-center gap-6">
                                                  <div className="w-16">
                                                      <img
                                                         src={item['image']}
                                                         className="w-full aspect-[1/1]"
                                                         id="image-rendering: pixelated;"/>
                                                      </div>
                                                  <div
                                                      className="font-bold max-w-[100px] sm:max-w-none truncate overflow-hidden">{item['type']}
                                                  </div>
                                              </div>
                                              </NavLink>
                                          </td>
                                          <td className="whitespace-nowrap px-6 py-4">
                                              <NavLink key={i}  to={'/Rubbings/'+item['groupId']}>
                                              <div className="font-medium text-right">{item['dayVolume']} ETH</div>
                                              </NavLink>
                                          </td>
                                          <td className="whitespace-nowrap px-6 py-4">
                                              <NavLink key={i}  to={'/Rubbings/'+item['groupId']}>
                                              <div className="font-medium text-right">{item['daySales']}</div>
                                              </NavLink>
                                          </td>
                                          <td className="whitespace-nowrap px-6 py-4">
                                              <NavLink key={i}  to={'/Rubbings/'+item['groupId']}>
                                              <div className="font-medium text-right">{item['floorPrice']} ETH</div>
                                              </NavLink>
                                          </td>
                                          <td className="whitespace-nowrap px-6 py-4">
                                              <NavLink key={i}  to={'/Rubbings/'+item['groupId']}>
                                              <div className="font-medium text-right">{item['totalAmount']}</div>
                                              </NavLink>
                                          </td>
                                          <td className="whitespace-nowrap px-6 py-4">
                                              <NavLink key={i}  to={'/list/'+item['groupId']}>
                                              <div className="font-medium text-right flex flex-col">
                                                  {/*<div>{item['totalAmount']}%</div>*/}
                                                  <div className="text-xs">{item['uniqueOwner']} owners</div>
                                              </div>
                                              </NavLink>
                                          </td>
                                          <td className="whitespace-nowrap px-6 py-4">
                                              <NavLink key={i}  to={'/Rubbings/'+item['groupId']}>
                                              <div className="font-medium text-right">{item['totalVolume']} ETH</div>
                                              </NavLink>
                                          </td>
                                          <td className="whitespace-nowrap px-6 py-4">
                                              <NavLink key={i}  to={'/Rubbings/'+item['groupId']}>
                                              <div className="font-medium text-right">{item['totalSales']}</div>
                                              </NavLink>
                                          </td>
                                      </tr>
                                          );
                                      })}

                                      </tbody>
                                  </table>
                              </div>
                          </div>
                      </div>
                  </section>
              </div>
      </div>
    </>
  )
}

export default Collections
