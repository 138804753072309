import {Input, message, Pagination, Progress, Table} from 'antd'
import React, {useCallback, useEffect, useState} from 'react'
import './PubTokenInfo.css'
import useInterval from '../../../hooks/useInterval'
import BigNumber from "bignumber.js";
import {getBalanceNumber, getDisplayBalance} from "../../../utils/formatBalance";

import useTokenApprove from "../../../hooks/useTokenApprove";
import {TEST_CRYPTO_TOKEN, x20PubTokenList} from "../../../constants/Constants";
import {useWeb3React} from "@web3-react/core";
import {useX20Factory, useX20Token} from "../../../hooks/useContract";
import {close, NULL_ADDRESS} from "../../../constants/addresses";
import {formatAddress, formatLastTime, TimeHMS} from "../../../utils/time";
import {getUrlParamParam} from "../../../utils";
import {eth_location, ic_copy} from "../../../assets";
import {NavLink} from "react-router-dom";
import { isMobile } from 'utils/userAgent'
// @ts-ignore
import {CopyToClipboard} from "react-copy-to-clipboard";
const { TextArea } = Input;
const { Column } = Table;
const PubTokenInfo = () => {
  const { account } = useWeb3React()
  const API_SERVER = process.env.REACT_APP_API_SERVER;

  const [tokenInfo, setTokenInfo] = useState({id:0, name:"", symbol:"", token: "", image: ""});
  const [tokenAddress, setTokenAddress] = useState('');

  useEffect(() => {
    const token = getUrlParamParam('i');
    console.info("Token tokenAddress==>" + token);
    setTokenAddress(token);
    readTokenInfo(token).then(r => {
      console.info('read token succ');
    });

  }, [ setTokenInfo, setTokenAddress])

  const readTokenInfo = async (token: string) => {
    const list = await fetch(API_SERVER + '/v2api/x20/tokenInfo?token=' + token)
    const resList = await list.json()
    if (resList['code'] === 200) {
      setTokenInfo(resList['data']);
    }
  };

  const x20Factory = useX20Factory();
  const x20Token = useX20Token(tokenAddress);
  const baseToken = useX20Token(TEST_CRYPTO_TOKEN);

  const [myLogList, setMyLogList] = useState([]);
  const [x20JoinUserList, setX20JoinUserList] = useState([]);

  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0)
  const onChange = (page: any) => {
    setCurrent(page);
  };

  const [transferFee, setTransferFee] = useState(0);
  const [currentX20, setCurrentX20] = useState(1);
  const [totalX20, setTotalX20] = useState(0)
  const onChangeX20 = (page: any) => {
    setCurrentX20(page);
  };

  const fetchLastTime = useCallback(async () => {
    try{
      if(account){
        const list = await fetch(API_SERVER + '/v2api/x20/walletLog?projectId='
            + tokenAddress +"&address=" + account + "&logPage=" + current + "&projPage=" + currentX20)
        const resList = await list.json()
        if (resList['code'] === 200) {
          const listRow = resList['data'];
          if(listRow){
            setMyLogList(listRow['logList']);
            setX20JoinUserList(listRow['userList']);
            setTotal(listRow['logTotal']);
            setTotalX20(listRow['userTotal']);
          }
        }
      }
    }catch (e) {
      console.error(e);
    }
  }, [  account, tokenAddress, current, currentX20, setMyLogList, setTokenAddress, setX20JoinUserList, setTotal, setTotalX20, setTokenInfo]);
  useInterval(fetchLastTime);

  //Mint
  const [copyStatus, setCopyStatus] = useState(false);
  const [readStatus, setReadStatus] = useState(false);
  const [needAuthnize, setNeedAuthnize] = useState(false);
  const [canJoin, setCanJoin] = useState(false);
  const [canWithdraw, setCanWithdraw] = useState(false);
  const [baseTokenAmount, setBaseTokenAmount] = useState(0);
  const [joinUserTotal, setJoinUserTotal] = useState(0);
  const [lastWithdrawTime, setLastWithdrawTime] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [withdrawInfo, setWithdrawInfo] = useState(
      { available: "0" ,userWithdraw:"0"});

  const readInfos = useCallback(async () => {
    try{
      if(x20Token && baseToken && x20Factory){
        const cryptoAmount = getBalanceNumber(new BigNumber((await x20Token.getCryptoAmount()).toString()));
        const joinUserTotal = (await x20Token.joinUserTotal()).toString();
        setJoinUserTotal(joinUserTotal);
        setBaseTokenAmount(cryptoAmount);

        if(account){
            const baseAllowance = getBalanceNumber(new BigNumber((await baseToken.allowance(account, tokenAddress)).toString()));
            const isUserJoin = (await x20Token.userMintStatus(account)).toString() === 'true';
            const needAuthCrypto = parseFloat(cryptoAmount.toString()) > parseFloat(baseAllowance.toString());
            const lastWithdrawTime = parseInt((await x20Token.getWithdrawTime(account)).toString());

            // const startTime = parseInt((await x20Token.startTime()).toString());

            const transferFee = parseInt((await x20Token.transferFee()).toString());
            // console.info("transferFee=" + transferFee);
            setTransferFee(transferFee);

            setCanJoin(!isUserJoin);
            setNeedAuthnize(needAuthCrypto);
            setLastWithdrawTime(lastWithdrawTime);
            // setStartTime(startTime);

            const joinList = await x20Factory.getJoin(account);
            console.info(joinList);
            let withdrawInfo = {available: "0" ,userWithdraw:"0"};
            for (let i=0; i< joinList.length; i++){
              const rewardInfoObj = joinList[i];
              const available = getDisplayBalance(new BigNumber(rewardInfoObj.available.toString()));
              const userWithdraw = getDisplayBalance(new BigNumber(rewardInfoObj.userWithdraw.toString()));
              // console.info('available=' + rewardInfoObj.available + 'userWithdraw=' + rewardInfoObj.userWithdraw);
              console.info( rewardInfoObj.tokenAddress +' |available=' + available + ' |userWithdraw=' + userWithdraw);
              if(rewardInfoObj.tokenAddress.toString().toLowerCase() === tokenAddress.toLowerCase()){
                withdrawInfo = {available: available ,userWithdraw:userWithdraw}
              }
            }
            setReadStatus(true);
            setWithdrawInfo(withdrawInfo);
            setCanWithdraw(parseFloat(withdrawInfo.available.replaceAll(",", "")) > 0 && lastWithdrawTime == 0);
          }
      }
    }catch (e) {
      console.error(e);
    }
  }, [baseToken, x20Token, x20Factory, setNeedAuthnize, setCanJoin, setJoinUserTotal, setBaseTokenAmount, setWithdrawInfo,
    setLastWithdrawTime, setCanWithdraw, setStartTime, setReadStatus, setTransferFee]);
  useInterval(readInfos);
  useEffect(() => {
    readInfos().then(r => {console.info("init read succ");});
  }, [])


  const {doApprove} = useTokenApprove(TEST_CRYPTO_TOKEN, tokenAddress, '10000000000000000000');
  const doJoin = () => {
    x20Token!.userMint();
  }
  const doWithdraw = () => {
    x20Factory!.releaseTokens(tokenAddress);
  }

  const copyOk = () => {
    setCopyStatus(true);
    message.success('Copy successfully')
  }

  return (
        readStatus? (
            <div className="PubTokenInfoDiv">
              <div className="fast-border">
                <div className="fast-item">
                  <div className="box_logo">
                    <img className="rounded-full" src={tokenInfo.image} alt=""/>
                    <div className="contact-info">
                      Contract: {isMobile ? formatAddress(tokenInfo.token) : tokenInfo.token}

                      <CopyToClipboard text={tokenInfo.token} onCopy={copyOk}>
                        {
                          copyStatus ?
                              <Progress className="copy-btn modal_progress" type="circle" percent={100} width={20}/> :
                              <img className="copy-btn" src={ic_copy} alt=""/>
                        }
                      </CopyToClipboard>
                    </div>
                  </div>
                  <div className="progress-info">
                    Liquidity Contribution Miner: {joinUserTotal} addresses
                    <NavLink className="detail-btn" to={"/log/" + tokenAddress}>Detail</NavLink>
                  </div>

                  <div className="progress-info">
                     Burn transaction tax : {transferFee}%
                  </div>
                </div>
              </div>

              <div className="handle-btn-div">
              {
                readStatus ? (
                    account ? (
                        canJoin ?
                            (
                                needAuthnize ? (
                                    <>
                                      {/*<div className="text">The Amount </div>*/}
                                      <div className="do-btn" onClick={doApprove}>
                                        <div className={'x20_comm_btn'}>Approve (You Need Pay: {baseTokenAmount}CRYPTO to contribute liquidity)</div>
                                      </div>
                                    </>
                                ) : (
                                    <>
                                      <div className="text">To mine</div>
                                      <div className="do-btn" onClick={doJoin}>
                                        <div className={'x20_comm_btn'}>Start to mine</div>
                                      </div>
                                    </>
                                )
                            ) :
                            <>
                              <div className="do-btn" onClick={canWithdraw ? doWithdraw : () => {
                                console.info("unable");
                              }}>
                                <div
                                    className={canWithdraw ? 'x20_comm_btn' : 'ens_handle_btn_disable'}>Withdrawn {canWithdraw ? "" : formatLastTime(lastWithdrawTime)}</div>
                              </div>

                              <div className="text">Available:{withdrawInfo.available} Withdrawn:{withdrawInfo.userWithdraw}</div>
                            </>
                    ) : <div/>
                ) : <div/>
              }
              </div>


              <div className="history">
                {/*<div className="type-title">
          <p>我的领取列表</p>
        </div>*/}
                <div className="table-list">
                  <Table id="domainTable" dataSource={myLogList} pagination={false}>
                    <Column title="Amount" dataIndex="avatar" key="avatar"
                            render={(_, record: any) => (
                                <div className="table_amount">
                                  {record.amount}
                                </div>
                            )}
                    />
                    <Column title="Withdraw Time" dataIndex="createTime" key="createTime"/>
                  </Table>
                </div>
                <div className="pageDiv">
                  <Pagination
                      total={total}
                      defaultPageSize={10}
                      defaultCurrent={1}
                      current={current}
                      onChange={onChange}
                      showSizeChanger={false}
                  />
                </div>
              </div>
            </div> ) : <div/>
  )
}
export default PubTokenInfo
