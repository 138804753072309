import React from "react";
import "./AppsItem.css";
import {Link, NavLink} from "react-router-dom";
import {default_avater} from "../../assets";
const WEB_SERVER = process.env.REACT_APP_WEB_SERVER;
interface CardProps {
    data?: any;
}

interface PriceChartProps {
    data: any,
}

export function TokenItem({ data }: PriceChartProps) {
    const open = data["open"];
  return (
    <div className={"apps_card_box"}>
      <div className="pay_card_inline">
        <div className="box_logo">
          <img className="rounded-full" src={data["icon"] ? data["icon"] : default_avater} alt="" />
        </div>
        {/*<p className="text">{data["name"]}</p>*/}
        <p className="text"></p>

          {
              data["style"] == 0 ?
              <a className="do-btn"  href={WEB_SERVER + data["url"]} >
                  <div className={open ? 'common_btn' : 'disable_btn'}>{open ? data["name"] : "Coming soon"}</div>
              </a>
                  :
              <Link className="do-btn" to={open ? data["url"] : "#"} >
                  <div className={open ? 'common_btn' : 'disable_btn'}>{open ? data["name"] : "Coming soon"}</div>
              </Link>
          }


      </div>
    </div>
  );
};

export default TokenItem;
