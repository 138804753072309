import './VoteAdmin.css'

import React, { useCallback, useState } from 'react'
import { DOG_HOST_URL } from '../../../constants/addresses'
import useInterval from '../../../hooks/useInterval'
const Admin = (props) => {

  const againstTwo = (t) => {
    if (!t) return "";
    if (t.length <= 25) {
      return t;
    }
    return t.substring(0, 20) + "****" + t.substring(t.length - 4, t.length);
  };

  const [total, setTotal] = useState(0)
  const [succTotal, setSuccTotal] = useState(0)
  const [airdropAList, setAirdropAList] = useState([])
  const fetchLastTime = useCallback(async () => {
    try {
      const listA = await fetch(DOG_HOST_URL + '/ethscription/vote/voteAdmin?pageSize=100&pageNum=0')
      const resListA = await listA.json()
      if (resListA['code'] === 200) {
        const listRow = resListA['rows']
        setTotal(resListA['total']);
        setSuccTotal(resListA['succTotal']);
        var tempList = []
        for (var i = 0; i < listRow.length; i++) {
          const one = listRow[i]
          tempList.push({
            id: one['regOrder'],
            address: one['address'] ,
            inviter: one['inviter'],
            types: one['status'],
            time: one['createTime'] + '',
            balance: (one['balance']),
            received: (one['received']),
            remark: (one['remark']),
          })
        }
        setAirdropAList(tempList);
      }
    } catch (e) {
      console.log(e)
    }
  }, [setAirdropAList,  setTotal, setSuccTotal])
  useInterval(fetchLastTime, 3000)


  return (
    <div className="voteadmin">
      <div className="total">
        All Regist User Total:{total}
      </div>
      <div className="total">
        Success Regist User Total:{succTotal}
      </div>
      <div className="total">
      </div>

      <div className="title">
        All Regist User
      </div>
      <div className="table">
        <div className="thead">
          <div className="tr">
            <div className="td">#</div>
            <div className="td address">address</div>
            <div className="td">status</div>
            <div className="td">balance</div>
            <div className="td">received</div>
            <div className="td address">time</div>
            <div className="td address">remark</div>
          </div>
        </div>
        <div className="tbody">
          {airdropAList.map((item, i) => {
            return (
              <div className="tr" key={i}>
                <div className="td">{item.id}</div>
                <div className="td address">
                  <p>{item.address}</p>
                  <p className="inviter">{item.inviter}</p>
                </div>
                <div className="td">{item.types}</div>
                <div className="td">{item.balance}</div>
                <div className="td">{item.received}</div>
                <div className="td address">{item.time}</div>
                <div className="td address">{item.remark}</div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
export default Admin
