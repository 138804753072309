import {Button, Image, Input, message, Pagination, Table, Upload} from 'antd'
import React, {useCallback, useState} from 'react'
// import {useWeb3React} from '@web3-react/core'
import './PubToken.css'
// import {useCryptoToken, useX20Factory,} from '../../../hooks/useContract';
import useInterval from '../../../hooks/useInterval'
import {BigNumber} from "bignumber.js";
import {getDisplayBalance, toBigString} from "../../../utils/formatBalance";
import {useWeb3React} from "@web3-react/core";
import TwtLogin, {ALL_POINT_LOGIN_KEY} from "../../../components/TwtLogin";
import {useX20Factory, useX20Token} from "../../../hooks/useContract";
import {TEST_CRYPTO_TOKEN, x20PubTokenList} from "../../../constants/Constants";
import X20TwtLogin from "../../../components/TwtLogin/X20TwtLogin";
import {RcFile, UploadChangeParam, UploadFile, UploadProps} from "antd/es/upload";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const PubProfile = (props: any) => {
    const { account,provider } = useWeb3React()
    const API_SERVER = process.env.REACT_APP_API_SERVER;


    const [tokenPoolInfo, setTokenPoolInfo] = useState(new Map());
    const [x20TokenList, setX20TokenList] = useState([]);
    const [myInfo, setMyInfo] = useState({userName:'',nickName:'',avatar:'',});
    const [myToken, setMyToken] = useState('');

    const x20Factory = useX20Factory();
    const x20Token = useX20Token(myToken);
    const baseToken = useX20Token(TEST_CRYPTO_TOKEN);

    const [current, setCurrent] = useState(1);
    const [total, setTotal] = useState(0)
    const onChange = (page: any) => {
        setCurrent(page);
    };

    const [currentLog, setCurrentLog] = useState(1);
    const [totalLog, setTotalLog] = useState(0)
    const onChangeLog = (page: any) => {
        setCurrentLog(page);
    };






    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>();
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const getBase64 = (img: RcFile, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {

        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            setLoading(false);
            setImageUrl(info.file.response.data);
            // getBase64(info.file.originFileObj as RcFile, url => {
            //     setLoading(false);
            //     setImageUrl(url);
            // });
        }
    };

    const [tokenName, setTokenName] = useState("Test Token Name");
    // @ts-ignore
    const tokenNameChange = ({ target: { value } }) => {
        setTokenName(value);
    };

    const [tokenSymbol, setTokenSymbol] = useState("Test Token Symbol");
    // @ts-ignore
    const tokenSymbolChange = ({ target: { value } }) => {
        setTokenSymbol(value);
    };
    const [tokenDesc, setTokenDesc] = useState("Test Token Desc");
    // @ts-ignore
    const tokenDescChange = ({ target: { value } }) => {
        setTokenDesc(value);
    };

    const [website, setWebsite] = useState("website");
    // @ts-ignore
    const websiteChange = ({ target: { value } }) => {
        setWebsite(value);
    };

    const [warpcaster, setWarpcaster] = useState("warpcaster");
    // @ts-ignore
    const warpcasterChange = ({ target: { value } }) => {
        setWarpcaster(value);
    };

    const [telegram, setTelegram] = useState("telegram");
    // @ts-ignore
    const telegramChange = ({ target: { value } }) => {
        setTelegram(value);
    };
    const [twitter, setTwitter] = useState("twitter");
    // @ts-ignore
    const twitterChange = ({ target: { value } }) => {
        setTwitter(value);
    };

    const doCreateToken = useCallback(async () => {
        try{
            if(x20Factory){
                const count = await x20Factory.getTokenCount();
                console.info('count=' + count);
                debugger
                // const image = 'http://www.baidu.com';
                const teamAddress = '0xad2b6ab2d50ea5bda8661ec5b832a6e2501340b7';
                await x20Factory.createToken(tokenName, tokenSymbol, imageUrl, tokenDesc, website, warpcaster, telegram, twitter,
                    teamAddress, TEST_CRYPTO_TOKEN);
            }
        }catch (e: any){
            console.error(e);
        }
    }, [x20Factory,  tokenName, tokenSymbol, tokenDesc, imageUrl, tokenDesc, website, warpcaster, telegram, twitter]);

    return (
        <>
            <div className="PubToken">

                <TwtLogin project={"twitter"}/>


                <div className="mutirow">
                    <Upload
                        name="file"
                        listType="picture-card"
                        className="left avatar-uploader"
                        showUploadList={false}
                        action={API_SERVER + '/v2api/x20/uploadImage'}
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                    >
                        {imageUrl ? <img src={imageUrl} alt="avatar" style={{width: '100%'}}/> : uploadButton}
                    </Upload>

                    <div className="right">
                        <div className="linerow">
                            <div className="towtitle">Token Name</div>
                            <div className="rowinput">
                                <Input
                                    showCount maxLength={20}
                                    value={tokenName}
                                    onChange={tokenNameChange}
                                />
                            </div>
                        </div>

                        <div className="linerow linerowmt">
                            <div className="towtitle">Token Symbol ($Ticker) *</div>
                            <div className="rowinput">
                                <Input
                                    showCount maxLength={10}
                                    value={tokenSymbol}
                                    onChange={tokenSymbolChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="linerow linerowmt">
                    <div className="towtitle">Token Description *</div>
                    <div className="rowinput">
                        <TextArea showCount maxLength={300} rows={4} value={tokenDesc} onChange={tokenDescChange}
                                  placeholder="Please Input Token Description"/>
                    </div>
                </div>

                <div className="linerow linerowmt">
                    <div className="towtitle">Website</div>
                    <div className="rowinput">
                        <Input
                            value={website}
                            onChange={websiteChange}
                            placeholder="Optional"
                        />
                    </div>
                </div>
                <div className="linerow linerowmt">
                    <div className="towtitle">Warpcaster</div>
                    <div className="rowinput">
                        <Input
                            value={warpcaster}
                            onChange={warpcasterChange}
                            placeholder="Optional"
                        />
                    </div>
                </div>
                <div className="linerow linerowmt">
                    <div className="towtitle">Telegram</div>
                    <div className="rowinput">
                        <Input
                            value={telegram}
                            onChange={telegramChange}
                            placeholder="Optional"
                        />
                    </div>
                </div>
                <div className="linerow linerowmt">
                    <div className="towtitle">Twitter</div>
                    <div className="rowinput">
                        <Input
                            value={twitter}
                            onChange={twitterChange}
                            placeholder="Optional"
                        />
                    </div>
                </div>

                <div onClick={doCreateToken} className={ 'x20_comm_btn pub_btn' }>Create Token</div>
            </div>
        </>
    )
}
export default PubProfile
