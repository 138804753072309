import {message, Modal, Table, Tabs} from 'antd'
import React, {useCallback, useEffect, useState} from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {useWeb3React} from '@web3-react/core'
import dayjs from 'dayjs'

import './VoteAirdrop.css'
import useInterval from '../../hooks/useInterval'
import {useShareManagerContract} from '../../hooks/useContract'
import {close, DOG_HOST_URL, SOLANA_CLOSE, TX_CLOSE} from '../../constants/addresses'
import useDogHarvest from '../../hooks/useDogHarvest'
import {NavLink} from "react-router-dom";
import {formatAddress} from "../../utils/time";
import logo from "../../assets/img/logo.png";
import {cPoint, pointAi} from "../../assets";

const { TabPane } = Tabs
const AirdropPuls = (props) => {
  const { Column, ColumnGroup } = Table;
  const { account, provider } = useWeb3React()
  const [registState, setRegistState] = useState(false)

  const [userInfo, setUserInfo] = useState({
    balance:0,received:0, olderCryptoBalance:0,olderCryptoBalanceGet:0,
  })
  const [canWithDraw, setCanWithDraw] = useState(false)
  const [giftList, setGiftList] = useState([]);
  const [invitedList, setInvitedList] = useState([]);

  const [isFeed, setIsFeed] = useState(false)
  const [needFeed, setNeedFeed] = useState('0')
  const [inviteLink, setInviteLink] = useState('')
  const [isRule, setIsRule] = useState(false)

  useEffect(() => {
    let link = window.location.protocol + '//' + window.location.host + '/#/invite/' + account
    setInviteLink(link)
  }, [account])

  const firstHarvest = () => {
    console.log('firstHarvest')
  }
  const secondHarvest = () => {
    console.log('secondHarvest')
  }
  const copyOk = () => {
    if(close)return;
    message.success('Copy successfully')
  }

  const voteManager = useShareManagerContract();

  const fetchLastTime = useCallback(async () => {
    if(close)return;
    try {
      if (!account) return -100
      const value = await voteManager.shareToken()
      const isFeeds = await voteManager.feeFlag(account)
      setIsFeed(isFeeds)
      const totalFee = await voteManager.amount()
      setNeedFeed(totalFee)
    } catch (e) {
      console.log(e)
    }
  }, [
    account,
      close,
    setIsFeed,
    setNeedFeed,
    voteManager,
    setUserInfo
  ])
  useInterval(fetchLastTime, 5000)

  const { onStake } = useDogHarvest()

  const showRule = () => {
    if(close)return;
    setIsRule(true)
  }

  const doReg = useCallback(async (type) => {
    if (!account) {
      message.error('please connect wollect first');
      return
    }
    const myInviter = '0x0000000000000000000000000000000000000000';
    try {
      const params = ("address="+account.toLowerCase() + "\ninviter=" + myInviter.toLowerCase());
      console.info('params===>' + params);
      const signature = (await provider.send('personal_sign', [params, account.toLowerCase()]));
      console.info('signature=  ' + signature);
      const result = await fetch(DOG_HOST_URL + '/ethscription/vote/bindUser?address=' + account.toLowerCase()
          +'&inviter='+ myInviter.toLowerCase() + '&signature='+ signature + "&type=" + type
      );
      const res = await result.json()
      if (res['code'] === 200) {
        message.success('bind success')
      }else{
        message.error(res['msg']);
      }
    }catch (e) {
      console.error(e);
    }
  }, [account, voteManager])

  const doWithdrawn = useCallback(async () => {
    // if(TX_CLOSE){
    //   message.error('Due to the repair of the anti-sybil system, the withdrawal function has been suspended for a few days.')
    //   return;
    // }
    if(!canWithDraw)return;
    if (!account) {
      message.error('collect wollet first')
      return
    }
    let totalAmount = userInfo['balance'];
    if (totalAmount <= 0 && !canWithDraw) {
      message.error('Can Withdrawn gift is not enough')
      return
    }
    const totalFee = await voteManager.amount()
    try {
      await onStake(totalFee)
    } catch (e) {
      message.error(e)
      return
    }
  }, [account,close,giftList, voteManager, canWithDraw])


  const getEnsList = useCallback(async () => {
    if(!account)return;
    try{

      const list = await fetch(DOG_HOST_URL + '/ethscription/vote/info?address=' + account.toString())
      const res = await list.json()
      if (res['code'] === 200) {
        const dataObj = res['data'];
        const userInfoObj = dataObj['user'];
        const logListObj = dataObj['logList'];
        const inviteListObj = dataObj['inviterList'];

        setRegistState(dataObj['hasReg']);
        setUserInfo(userInfoObj);
        if(logListObj) {
          setGiftList(logListObj);
          console.info(logListObj);
        }
        if(inviteListObj){
          setInvitedList(inviteListObj);
        }
        setCanWithDraw(userInfoObj['balance'] > 0);
      }
    }catch (e) {
      console.error(e);
    }
  }, [account, setGiftList, setInvitedList, setCanWithDraw, setUserInfo, setRegistState]);
  const fetchLastTime2 = useCallback(async () => {
    if(close)return;
    try {
      getEnsList();
    } catch (e) {
      console.log(e);
    }
  }, [account, close, getEnsList, voteManager, setRegistState]);
  useInterval(fetchLastTime2, 3000);



  const [solAddress, setSolAddress] = useState("");
  const solAddressChange = ({ target: { value } }) => {
    setSolAddress(value);
  };

  const [goonVisible, setGoonVisible] = useState(false);
  const [olderCryptoTx, setOlderCryptoTx] = useState(false);
  const showDialog = (isOlder) => {
    setOlderCryptoTx(isOlder);
    setGoonVisible(true);
  };
  const cancelRule = () => {
    setGoonVisible(false);
  };

  const changeHandle = useCallback(async () => {
    const params = ("address="+ account.toLowerCase() + "\nsolAddress=" + solAddress);
    const signature = (await provider.send('personal_sign', [params, account.toLowerCase()]));

    const result = await fetch(DOG_HOST_URL + '/ethscription/vote/changeToSol?address=' + account.toLowerCase()
        +'&solAddress='+ solAddress + '&signature='+ signature + "&olderCrypto=" + olderCryptoTx
    );
    const res = await result.json()
    if (res['code'] === 200) {
      message.success('change success')
      cancelRule();
    }else{
      message.error(res['msg']);
    }

  }, [account, solAddress, olderCryptoTx]);

  return (
      <div className="ant-row BigPage">



    <div className="VoteAirdrop ">
      {/*{registState ? (
          <div className="air_invite">
            <div className="title">
              You can click the &quot;Copy&quot; button to copy the invitation link to invite others to get more points.
            </div>
            <div className="copy-row">
              <div className="invite_link">{inviteLink}</div>

              <div className="copy-btns">
                <CopyToClipboard text={inviteLink} onCopy={copyOk}>
                  <div className="copy_btn">Copy</div>
                </CopyToClipboard>

                {
                  userInfo['reCheck'] !== 0 ? <div/> :
                      <div className={'btn'} onClick={()=>doReg("recheck")}>
                        Re-check
                      </div>
                }
              </div>
            </div>
          </div>
      ) : (
          <div className={'no_reg'}>
            <div className="no_reg_top">
              <div className="text">
                Click the &quot;Check&quot; button. If they are eligible correspondingly, the non-zero data will be displayed in the column below.
              </div>
              <div className={'btn'} onClick={()=>doReg("regist")}>
                Check
              </div>
            </div>
          </div>
      )}*/}


      <div className="air_top_log">
        <img className="rounded-full" src={SOLANA_CLOSE ? logo : cPoint} alt="" />
        <div className="titles">C POINTS AI</div>
      </div>

    <div className="area_border">

      <div className="air_top">

        <Tabs defaultActiveKey="1">
          <TabPane tab="Cpoints" key="1">
            <div className="air_left">
              <div className="left_con">
                <div className="item">
                  <div className="num">{userInfo['balance'] + userInfo['received'] + userInfo['olderCryptoBalance']}</div>
                  <div className="tetx">Total amount</div>
                </div>
                <div className="item">
                  <div className="num">{userInfo['balance']}</div>
                  <div className="tetx">To be converted</div>
                  {
                    SOLANA_CLOSE ? <div/> :
                    <div className={ (userInfo['balance'] === 0) ? 'handle_btn handle_btn_dis' : 'handle_btn'} onClick={() => showDialog(false)}>
                      Convert to on Solana
                    </div>
                  }
                </div>

                <div className="item">
                  <div className="num">{userInfo['received']}</div>
                  <div className="tetx">converted</div>
                </div>
              </div>
              {/*<NavLink className={"to_ens_div_yes"} to={'/domainname'}>
                <span className="rule" onClick={showRule}>
                  Register your .new domain name
                </span>
              </NavLink>*/}
            </div>
          </TabPane>
        </Tabs>
      </div>
      <div className="air_top_mobile">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Total points Amount" key="1">
            <div className="air_left">
              <div className="left_con">
                <div className="item">
                  <div className="line_one">
                    <div className="num">{userInfo['balance'] + userInfo['received'] + userInfo['olderCryptoBalance']}</div>
                    <div className="tetx">Total amount</div>
                  </div>
                </div>
                <div className="item">
                  <div className="line_one">
                    <div className="num">{userInfo['balance']}</div>
                    <div className="tetx">To be harvested</div>
                  </div>
                  <div className="line_two">
                    {
                      SOLANA_CLOSE ? <div/> :
                    <div className={userInfo['balance'] === 0 ? 'handle_btn_dis' : 'handle_btn'} onClick={() => showDialog(false)}>
                      Convert to on Solana
                    </div>
                    }
                  </div>
                </div>

                <div className="item">
                  <div className="line_one">
                    <div className="num">{userInfo['received']}</div>
                    <div className="tetx">Harvested</div>
                  </div>
                </div>
              </div>
              {/*<NavLink className={userInfo['received'] > 0 ? "to_ens_div_yes" : "to_ens_div"} to={'/domainname'}
                       onClick={userInfo['received'] > 0 ? null : (e) => {e.preventDefault()}} >
                  <span className="rule" onClick={showRule}>
                    Register your .new domain name
                  </span>
              </NavLink>*/}
            </div>
          </TabPane>
        </Tabs>
      </div>

      <div className="history">
        <div className="history_t">Reward history</div>
        <div className="table-list">
          <Table id="domainTable" dataSource={giftList} pagination={{ position: ['none', 'none'] }} >
            <Column title="Gift Type" dataIndex="types" key="types" />
            <Column title="Amount" dataIndex="amount" key="amount" />
            <Column title="Time" dataIndex="createTime" key="createTime" />
          </Table>
        </div>
      </div>

      <div className="history">
        <div className="history_t">Invitation history</div>
        <div className="table-list">
          <Table id="domain2Table" dataSource={invitedList} pagination={{ position: ['none', 'none'] }} >
            <Column
                title="Address"
                key="address"
                render={(_, record) => (
                    <div>
                      {
                        formatAddress(record['address'])
                      }
                    </div>
                )}
            />
            <Column title="Time" dataIndex="createTime" key="createTime" />
          </Table>
        </div>
      </div>
    </div>


    <div className="area_border2">

      <div className="air_top">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Original unwithdrawn CRYPTO points" key="1">
            <div className="air_left">
              <div className="left_con">
                {/*<div className="item">
                  <div className="num">{userInfo['balance'] + userInfo['received'] + userInfo['olderCryptoBalance']}</div>
                  <div className="tetx">Total amount</div>
                </div>*/}
                <div className="item">
                  <div className="num">{userInfo['olderCryptoBalance']}</div>
                  <div className="tetx">Original unwithdrawn CRYPTO points to be converted</div>
                  {
                    SOLANA_CLOSE ? <div/> :
                  <div className={ (userInfo['olderCryptoBalance'] === 0) ? 'handle_btn handle_btn_dis' : 'handle_btn'} onClick={() => showDialog(true)}>
                    Convert to on Solana
                  </div>
                  }
                </div>
                <div className="item">
                  <div className="num">{userInfo['olderCryptoBalanceGet'] ? userInfo['olderCryptoBalanceGet'] : 0}</div>
                  <div className="tetx">converted</div>
                </div>
              </div>
              {/*<NavLink className={"to_ens_div_yes"} to={'/domainname'}>
                <span className="rule" onClick={showRule}>
                  Register your .new domain name
                </span>
              </NavLink>*/}
            </div>
          </TabPane>
        </Tabs>
      </div>
      <div className="air_top_mobile">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Original unwithdrawn CRYPTO points" key="1">
            <div className="air_left">
              <div className="left_con">
                {/*<div className="item">
                  <div className="line_one">
                    <div className="num">{userInfo['balance'] + userInfo['received'] + userInfo['olderCryptoBalance']}</div>
                    <div className="tetx">Total amount</div>
                  </div>
                </div>*/}

                <div className="item">
                  <div className="line_one">
                    <div className="num">{userInfo['olderCryptoBalance']}</div>
                    <div className="tetx">Original unwithdrawn CRYPTO points to be converted</div>
                  </div>
                  <div className="line_two">
                    {
                      SOLANA_CLOSE ? <div/> :
                    <div className={userInfo['olderCryptoBalance'] === 0 ? 'handle_btn_dis' : 'handle_btn'} onClick={() => showDialog(true)}>
                      Convert to on Solana
                    </div>
                    }
                  </div>
                </div>
                <div className="item">
                  <div className="line_one">
                    <div className="num">{userInfo['olderCryptoBalanceGet'] ? userInfo['olderCryptoBalanceGet'] : 0}</div>
                    <div className="tetx">converted</div>
                  </div>
                </div>

              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>

      <div className="to_oldair_area">
        <NavLink className={"to_old_airdrop"} to={'/airdrop/points'}>
                  <span className="rule">
                    Go to your original CRYPTO points page
                  </span>
        </NavLink>
      </div>

    </div>

      <Modal
          visible={goonVisible}
          onCancel={cancelRule}
          closable={false}
          centered={true}
          footer={null}
          className="rule_modal_box"
      >

        <div className="ensset_area">
          Charge your point to SOL address
          <div className="search-row">
            SOL Address:
            <form className="search-form" action="#">
              <input
                  placeholder="Input your SOL Address"
                  autoCapitalize="off"
                  className="modal_input"
                  value={solAddress}
                  onChange={solAddressChange}
              />
            </form>
          </div>
        </div>
        <div  className={ 1===1 ? "ens_handle_btn" : "ens_handle_btn_disable"} onClick={changeHandle}>Sign to Confirm</div>
      </Modal>

    </div>
  </div>
  )
}
export default AirdropPuls
