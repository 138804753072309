import React, {useCallback, useEffect, useState} from "react";
import {message, Modal} from "antd";
import useInterval from "../hooks/useInterval";
import {NavLink} from "react-router-dom";
import {default_avater, eth_location, eth_location_m, x20_log} from "../assets";
import {useWeb3React} from "@web3-react/core";
import {formatAddress} from "../utils/time";
import {isMobile} from "../utils/userAgent";
export const IS_ALL_POINT = false;
export const ALL_POINT_LOGIN_KEY = IS_ALL_POINT ? "ALL_POINT_LOGIN_KEY" : "TWEET_LOGIN_KEY";
export const INVITER_ADDR = "INVITER_ADDR";

interface TwtProps {
    project: string;
}

const TwtLogin = (props: TwtProps) => {
    const { account,provider } = useWeb3React()
    const thisPlant = IS_ALL_POINT ? "allPoint" : "twitter";
    const API_SERVER = process.env.REACT_APP_API_SERVER;

    const [loginUserInfo, setLoginUserInfo] = useState(
        {login: false, isReged: true, mintAllInsc: false, activeScore: "0", eth:"", avatar:"", nickName:"", userName:"", address:"", }
    );

    const getLoginUserInfo = useCallback(async () => {
        const twToken = localStorage.getItem(ALL_POINT_LOGIN_KEY);
        console.info("twToken=" + twToken);
        if(!twToken)return;
        try{
            const list = await fetch(API_SERVER + "/ethscription/" + thisPlant + "/getUserInfo?token=" + twToken.toString())
            const resList = await list.json()
            if (resList && resList["code"] === 200) {
                const info = resList["data"];
                if(info){
                    setLoginUserInfo({login: true, isReged: info.reged, mintAllInsc: info.mintAllInsc, activeScore:info.activeScore, eth: info.eth, avatar:info.avatar, nickName:info.nickName, userName:info.userName, address:info.address});
                }
            }
            // else{
            //     setLoginUserInfo({login: false, isReged: false, mintAllInsc: false, activeScore: "0",eth:"", avatar:"", nickName:"", userName:"", address:"", });
            // }
        }catch (e) {
            console.error(e);
        }
    }, [setLoginUserInfo,]);

    const fetchLastTime = useCallback(async () => {
        try {
            await getLoginUserInfo();
        } catch (e) {
            console.log(e);
        }
    }, [ getLoginUserInfo,]);
    useInterval(fetchLastTime);

    const loginTwitter = useCallback(async () => {
        // if(IS_STEP_1)return;
        window.location.href = API_SERVER + "/ethscription/" + thisPlant + "/twitterLogin";
    }, [account, API_SERVER,]);

    const bindAddress = useCallback(async () => {
        try{
            const twToken = localStorage.getItem(ALL_POINT_LOGIN_KEY);
            console.info("twToken=" + twToken);
            if(!twToken){
                return message.success("login twitter first");
            }
            const params = ("address="+ account!.toLowerCase() + "\nmessage=Hello");
            // @ts-ignore
            const signature = (await provider.send("personal_sign", [params, account.toLowerCase()]));
            // @ts-ignore
            // const signature = await walletClient.signMessage({message: params})
            console.info("message=  " + params);
            console.info("signature=  " + signature);
            const list = await fetch(API_SERVER + "/ethscription/" + thisPlant + "/bindAddress?address=" + account!.toString() + "&token=" + twToken +"&signature=" + signature)
            const response = await list.json()
            if (response["code"] === 200) {
                message.success("bind sucess");
            }else{
                message.error(response["msg"]);
            }
        }catch (e) {
            console.error(e);
        }
    }, [account, provider,]);


////////////////////
    const [dialogVisible, setDialogVisible] = useState(false);
    const showRule = () => {
        setDialogVisible(true);
    };
    const cancelRule = () => {
        setDialogVisible(false);
    };

    const [tweetPage, setTweetPage] = useState("5");
    // const tweetPageChange = ({ target: { value } }) => {
    //     let checkedName = value.toString().toLowerCase().replace(" ", "");
    //     setTweetPage(checkedName);
    // };
    const tweetPageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        console.log("Change:", e.target.value);
        setTweetPage(e.target.value);
    };

    const [remark, setRemark] = useState("");
    // const remarkChange = ({ target: { value } }) => {
    //     setTweetPage(value);
    // };
    const remarkChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        console.log("Change:", e.target.value);
        setTweetPage(e.target.value);
    };

    const applyPublishHistoryTwInsc = useCallback(async () => {
        try{
            const twToken = localStorage.getItem(ALL_POINT_LOGIN_KEY);
            console.info("twToken=" + twToken);
            if(!twToken){
                return message.success("login twitter first");
            }
            const list = await fetch(API_SERVER + "/ethscription/" + thisPlant + "/newUserTwTask?token=" + twToken +"&page=" + tweetPage + "&remark=" + remark)
            const resList = await list.json()
            if (resList["code"] === 200) {
                message.success("Apply Success");
                cancelRule();
            }else{
                message.error(resList["msg"]);
            }
        }catch (e) {
            console.error(e);
        }
    }, [ tweetPage, remark]);


    return (
        <div className="login-info w-full">
            {
                <div className="project-divs">
                    <div className="user-area">
                        <div className="user-info small-text">
                        </div>
                        <div className="user-info right-info">
                            <div className="row-left">
                                <div className="right-info">
                                </div>
                                <img className="common_user-avater" src={loginUserInfo.avatar ? loginUserInfo.avatar : default_avater} alt="" />
                            </div>
                            <div className="row-address">{loginUserInfo.userName ? loginUserInfo.nickName + '@' + loginUserInfo.userName : ""}</div>
                            {
                                account ? <div className="row-address">{formatAddress(account)}</div> : <div/>
                            }

                            {
                                loginUserInfo && loginUserInfo.eth ?
                                    <div className="eth-area">
                                        {/*<img src={eth_location} alt="" />
                                        <NavLink className="eth-name" id={`vote-nav-link`} to={"/MyLands"}>{loginUserInfo.eth}</NavLink>*/}
                                    </div>
                                    : <div/>
                            }

                        </div>
                    </div>
                </div>
            }

            <div className="project-divs mt-8">
                <div className="user-area">
                    <div/>
                    <div className="user-info right-info">
                        <div className="eth-area">
                            <img src={ isMobile ? eth_location_m : eth_location} alt=""/>

                            <NavLink className="font-poppins font-normal cursor-pointer text-[12.8px]" to={'/MyLands'}>
                                My Lands
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                visible={dialogVisible}
                onCancel={cancelRule}
                closable={false}
                centered={true}
                footer={null}
                className="rule_modal_box"
            >
                <div className="title tit">
                    Apply for mint historical Twitter inscriptions
                </div>

                <div className="send_tw_dialog">
                    <div className="search-row">
                        Rules:
                    </div>

                    <div className="search-text">
                        After creating skyrocketing influence engine, you can post a post with 2 tags attached on X to earn the username points without having to post on this website again.
                    </div>

                </div>
                <div className="send_tw_dialog">
                    <div className="search-row">
                        Page Amount:
                    </div>
                    <div className="search-text">
                        <input
                            placeholder="Input your tweet page amount"
                            autoCapitalize="off"
                            className="modal_input"
                            value={tweetPage}
                            onChange={tweetPageChange}
                        />
                    </div>
                </div>
                <div className="send_tw_dialog">
                    <div className="search-row">
                        Project remark:
                    </div>
                    <div className="search-text">
                        <input
                            placeholder="Input your project desc"
                            autoCapitalize="off"
                            className="modal_input"
                            value={remark}
                            onChange={remarkChange}
                        />
                    </div>
                </div>
                <div  className="send_twitter_btn" onClick={applyPublishHistoryTwInsc}>Apply now!</div>
            </Modal>
        </div>
    );
}
export default TwtLogin
