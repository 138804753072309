import React, {useCallback, useEffect, useState} from "react";
import useInterval from "../../../hooks/useInterval";
import {message, Row} from "antd";
import {formatAddress} from "../../../utils/time";
import {close, ETHS_URL, IS_TEST} from "constants/addresses";
import {IPFS_URL} from "../../../constants/addresses";
import {getUrlParamParam} from "../../../utils";
// @ts-ignore
import {CopyToClipboard} from "react-copy-to-clipboard";
import {NavLink} from "react-router-dom";

const RubbingsInfo = () => {
    const API_SERVER = process.env.REACT_APP_API_SERVER;
    const [authorId, setAuthorId] = useState('');
    const [isEth, setIsEth] = useState(true);
    useEffect(() => {
        let pathValue = getUrlParamParam('RubbingsInfo');
        if(pathValue.length > 0){
            if(pathValue.startsWith("e")){
                pathValue = pathValue.substring(1);
                setIsEth(true);
            }
            setAuthorId(pathValue);
            console.info('param Id=' + pathValue);
            getInscList();
        }
    }, [setAuthorId, setIsEth])

    const [inscInfo, setInscInfo] = useState(IS_TEST
        ? { id:'', ordId:'', ipfs:'', status:'', creator:'', authorId:'', owner:'', type:'', image:'https://pbs.twimg.com/profile_images/1644851643228516353/-adFBedl_normal.jpg', memberName:'nickname', address:'xxxxxxxxxxxxxxx', }
        : { id:'', ordId:'', ipfs:'', status:'', creator:'', authorId:'', owner:'', type:'', image:'', memberName:'', address:'', }
    );
    const [facetInscList, setFacetInscList] = useState([]);

    const getInscList = useCallback(async () => {
        try{
            if(!authorId)return;
            const authorParam = isEth ? "&authorId=-1" : "";
            const list = await fetch(API_SERVER + '/ethscription/allPoint/inscInfo?inscId=' + authorId + authorParam)
            const resList = await list.json()
            if (resList['code'] === 200) {
                const listRow = resList['data'];
                if(listRow){
                    if(listRow['insInfo']){
                        setInscInfo(listRow['insInfo']);
                    }
                    if(listRow['msgList']){
                        setFacetInscList(listRow['msgList']);
                    }
                }
            }
        }catch (e) {
            console.error(e);
        }
    }, [setFacetInscList, setInscInfo, authorId]);

    const fetchLastTime = useCallback(async () => {
        try {
            await getInscList();
        } catch (e) {
            console.log(e);
        }
    }, [ getInscList,]);
    useInterval(fetchLastTime);

    // const { sendTransaction, isSuccess, } = useSendTransaction()
    // const getPointToken = useCallback(async () => {
    //     try{
    //         const twToken = localStorage.getItem(ALL_POINT_LOGIN_KEY);
    //         console.info('twToken=' + twToken);
    //         if(!twToken){
    //             return message.success('login twitter first');
    //         }
    //         const list = await fetch(API_SERVER + '/ethscription/allPoint/newUserTwTask')
    //         const resList = await list.json()
    //         if (resList['code'] === 200) {
    //             message.success('Apply Success');
    //         }else{
    //             message.error(resList['msg']);
    //         }
    //     }catch (e) {
    //         console.error(e);
    //     }
    // }, [ ]);

    const copyOk = () => {
        if(close)return;
        message.success('Copy successfully')
    }

    return (
        <>
            <Row className='BigPage'>
                <div className="flex flex-col w-full items-center py-8 gap-8">
                    <section className="point-title flex flex-col w-full">
                        <div className="point-title-in flex flex-col bg-white rounded-2xl shadow-sm">
                            <div className="w-full flex flex-col sm:flex-row gap-8">
                                <div
                                    className="flex flex-1 flex-col items-start justify-start tracking-tight w-full rounded-xl overflow-hidden">
                                    <img className="w-full group-img-border" src={inscInfo.image}/>
                                </div>
                                <div className="flex flex-1 flex-col gap-4">
                                    <h2 className="font-black tracking-tight mb-0 text-2xl md:text-4xl">{inscInfo.id}.eth</h2>
                                    {/*<h1 className="font-black tracking-tight mb-0 text-4xl md:text-6xl">0.16 ETH</h1>*/}

                                    {inscInfo.status === 'NO_MINT' ? <div/> :
                                        <div className="flex flex-col gap-2 mt-2">
                                            <a href={ETHS_URL + inscInfo.ordId}
                                               className="comm_pad_btn_normal btn btn-sm w-fit justify-center rounded-md text-sm font-semibold shadow-sm">
                                                Buy Now
                                            </a>
                                        </div>
                                    }
                                    <div>
                                        <div className="mt-2 border-t border-gray-100">
                                            <dl className="divide-y divide-gray-100">
                                                <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                    <dt className="text-sm font-medium leading-6 text-gray-900">Mimetype</dt>
                                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                        <div className="text-base truncate">image/png</div>
                                                    </dd>
                                                </div>
                                                <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                    <dt className="text-sm font-medium leading-6 text-gray-900">Rubbing
                                                    </dt>
                                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                        <div className="text-base">{inscInfo.id}.eth</div>
                                                    </dd>
                                                </div>
                                                <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                    <dt className="text-sm font-medium leading-6 text-gray-900">Collection</dt>
                                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                        <NavLink
                                                            className={"text-gray-500 hover:text-black transition-colors text-base"}
                                                            to={'/Rubbings/eth'}>
                                                            {inscInfo.type}
                                                        </NavLink>
                                                    </dd>
                                                </div>
                                                <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                    <dt className="text-sm font-medium leading-6 text-gray-900">Owner</dt>
                                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                        <a className="text-gray-500 hover:text-black transition-colors text-base">
                                                            <div className="flex items-center gap-1">
                                                                {
                                                                    inscInfo.owner ?
                                                                        <CopyToClipboard text={inscInfo.owner}
                                                                                         onCopy={copyOk}>
                                                                            <div className="one-row">
                                                                                <span
                                                                                    className="text-base">{formatAddress(inscInfo.owner)}</span>
                                                                                <div
                                                                                    className="common_copy_btn ml-2">Copy
                                                                                </div>
                                                                            </div>
                                                                        </CopyToClipboard>
                                                                        : <div/>
                                                                }

                                                            </div>
                                                        </a></dd>
                                                </div>
                                                <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                    <dt className="text-sm font-medium leading-6 text-gray-900">Creator</dt>
                                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                        <a className="text-gray-500 hover:text-black transition-colors text-base">
                                                            <div className="flex items-center gap-1">
                                                                {
                                                                    inscInfo.creator ?
                                                                        <CopyToClipboard text={inscInfo.creator}
                                                                                         onCopy={copyOk}>
                                                                            <div className="one-row">
                                                                                <span
                                                                                    className="text-base">{formatAddress(inscInfo.creator)}</span>
                                                                                <div
                                                                                    className="common_copy_btn ml-2">Copy
                                                                                </div>
                                                                            </div>
                                                                        </CopyToClipboard>
                                                                        : <div/>
                                                                }
                                                            </div>
                                                        </a></dd>
                                                </div>
                                                {/*<div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                              <dt className="text-sm font-medium leading-6 text-gray-900">Status</dt>
                                              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                  <div className="text-gray-500 hover:text-black transition-colors text-base">
                                                      {inscInfo.status === 'NO_MINT' ? "Not Mint" : "Minted"}
                                                  </div>
                                              </dd>
                                          </div>*/}

                                                {inscInfo.status === 'NO_MINT' ? <div/> :
                                                    <>
                                                        <div
                                                            className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                            <dt className="text-sm font-medium leading-6 text-gray-900">Ethscription</dt>
                                                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                                <a target="_blank"
                                                                   className="text-gray-500 hover:text-black transition-colors text-base"
                                                                   href={ETHS_URL + inscInfo.ordId} rel="noreferrer"
                                                                >
                                                                    {formatAddress(inscInfo.ordId)}
                                                                </a>
                                                            </dd>
                                                        </div>

                                                        <div
                                                            className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                            <dt className="text-sm font-medium leading-6 text-gray-900">IPFS</dt>
                                                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                                <a target="_blank"
                                                                   className="text-gray-500 hover:text-black transition-colors text-base"
                                                                   rel="noreferrer"
                                                                   href={IPFS_URL + inscInfo.ipfs}
                                                                >
                                                                    {formatAddress(inscInfo.ipfs)}
                                                                </a>
                                                            </dd>
                                                        </div>

                                                    </>
                                                }
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Row>
            {
                isEth ? <div/> :
                    <div className="point-msg-div ">
                        <h3 className="font-black tracking-tight mb-0 text-2xl md:text-4xl">{inscInfo.memberName}</h3>
                        <div className="point-msg-list">
                            {facetInscList.map((twt, i) => {
                                return (
                                    <div key={'is_' + i} className="point-msg-item">
                                        <img alt=""
                                             className="msg-author-img rounded-full"
                                             src={inscInfo.image}
                                        />
                                        <div className="msg-infos">
                                            <div className="msg-info-author">
                                                <p className="name">{twt['groupInfo']}</p><p
                                                className="time">{twt['createTime']}</p>
                                            </div>
                                            <div className="msg-info-text">
                                                {twt['text']}
                                            </div>
                                            <div className="msg-info-btns">
                                                <div className="btn-item">
                                                    <svg viewBox="0 0 24 24" fill="rgb(128, 128, 128)"
                                                         className="btn-svg">
                                                        <g>
                                                            <path
                                                                d="M1.751 10c0-4.42 3.584-8 8.005-8h4.366c4.49 0 8.129 3.64 8.129 8.13 0 2.96-1.607 5.68-4.196 7.11l-8.054 4.46v-3.69h-.067c-4.49.1-8.183-3.51-8.183-8.01zm8.005-6c-3.317 0-6.005 2.69-6.005 6 0 3.37 2.77 6.08 6.138 6.01l.351-.01h1.761v2.3l5.087-2.81c1.951-1.08 3.163-3.13 3.163-5.36 0-3.39-2.744-6.13-6.129-6.13H9.756z"></path>
                                                        </g>
                                                    </svg>
                                                    {twt['quoteCount']}
                                                </div>
                                                <div className="btn-item">
                                                    <svg viewBox="0 0 24 24" fill="rgb(128, 128, 128)"
                                                         className="btn-svg">
                                                        <g>
                                                            <path
                                                                d="M4.5 3.88l4.432 4.14-1.364 1.46L5.5 7.55V16c0 1.1.896 2 2 2H13v2H7.5c-2.209 0-4-1.79-4-4V7.55L1.432 9.48.068 8.02 4.5 3.88zM16.5 6H11V4h5.5c2.209 0 4 1.79 4 4v8.45l2.068-1.93 1.364 1.46-4.432 4.14-4.432-4.14 1.364-1.46 2.068 1.93V8c0-1.1-.896-2-2-2z"></path>
                                                        </g>
                                                    </svg>
                                                    {twt['replyCount']}
                                                </div>
                                                <div className="btn-item">
                                                    <svg viewBox="0 0 24 24" fill="rgb(128, 128, 128)"
                                                         className="btn-svg">
                                                        <g>
                                                            <path
                                                                d="M16.697 5.5c-1.222-.06-2.679.51-3.89 2.16l-.805 1.09-.806-1.09C9.984 6.01 8.526 5.44 7.304 5.5c-1.243.07-2.349.78-2.91 1.91-.552 1.12-.633 2.78.479 4.82 1.074 1.97 3.257 4.27 7.129 6.61 3.87-2.34 6.052-4.64 7.126-6.61 1.111-2.04 1.03-3.7.477-4.82-.561-1.13-1.666-1.84-2.908-1.91zm4.187 7.69c-1.351 2.48-4.001 5.12-8.379 7.67l-.503.3-.504-.3c-4.379-2.55-7.029-5.19-8.382-7.67-1.36-2.5-1.41-4.86-.514-6.67.887-1.79 2.647-2.91 4.601-3.01 1.651-.09 3.368.56 4.798 2.01 1.429-1.45 3.146-2.1 4.796-2.01 1.954.1 3.714 1.22 4.601 3.01.896 1.81.846 4.17-.514 6.67z"></path>
                                                        </g>
                                                    </svg>
                                                    {twt['likeCount']}
                                                </div>
                                                <div className="btn-item">
                                                    <svg viewBox="0 0 24 24" fill="rgb(128, 128, 128)"
                                                         className="btn-svg">
                                                        <g>
                                                            <path
                                                                d="M8.75 21V3h2v18h-2zM18 21V8.5h2V21h-2zM4 21l.004-10h2L6 21H4zm9.248 0v-7h2v7h-2z"></path>
                                                        </g>
                                                    </svg>
                                                    {twt['retweetCount']}
                                                </div>
                                                <div className="btn-item">
                                                    <svg viewBox="0 0 24 24" fill="rgb(128, 128, 128)"
                                                         className="btn-svg">
                                                        <g>
                                                            <path
                                                                d="M8.75 21V3h2v18h-2zM18 21V8.5h2V21h-2zM4 21l.004-10h2L6 21H4zm9.248 0v-7h2v7h-2z"></path>
                                                        </g>
                                                    </svg>
                                                    <svg viewBox="0 0 24 24" fill="rgb(128, 128, 128)"
                                                         className="btn-svg">
                                                        <g>
                                                            <path
                                                                d="M12 2.59l5.7 5.7-1.41 1.42L13 6.41V16h-2V6.41l-3.3 3.3-1.41-1.42L12 2.59zM21 15l-.02 3.51c0 1.38-1.12 2.49-2.5 2.49H5.5C4.11 21 3 19.88 3 18.5V15h2v3.5c0 .28.22.5.5.5h12.98c.28 0 .5-.22.5-.5L19 15h2z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                            }
                        </div>
                    </div>
            }

        </>
    )
}

export default RubbingsInfo
