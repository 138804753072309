import React, {useEffect, useState} from 'react'
import {message, Row} from 'antd'
import { useSearchParams } from 'react-router-dom';
import TwtLogin from "../../components/TwtLogin";
import TwtInfos from "../../components/TwtInfos/TwtInfos";

const CrossChina = () => {
  const [params] = useSearchParams();
  const inviter = params.getAll("inviter")[0];
  const twToken = params.getAll("twToken")[0];
  console.info('inviter=>' + inviter +" ,twToken=" + twToken);



  return (
      <>
          <Row className="BigPage">
              <TwtInfos/>
              <div className="pubTitel">
                  Cross China
              </div>




          </Row>
      </>
  )
}

export default CrossChina
