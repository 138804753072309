import React, {useEffect, useState} from 'react'
import AppsItem from './AppsItem'
import {message, Row} from 'antd'
import './AppsHome.css'
import { useSearchParams } from 'react-router-dom';
import {DappsList, x20PubTokenList} from "../../constants/Constants";
import TwtLogin from "../../components/TwtLogin";
import {SOLANA_CLOSE} from "../../constants/addresses";
import TwtInfos from "../../components/TwtInfos/TwtInfos";

const AppsHome = () => {
  const [params] = useSearchParams();
  const inviter = params.getAll("inviter")[0];
  const twToken = params.getAll("twToken")[0];
  console.info('inviter=>' + inviter +" ,twToken=" + twToken);



  return (
      <>

          <Row className="BigPage">

              <div className="appContents">
                  {/*<TwtLogin project={"allPoint"}/>*/}
                  <TwtInfos/>
              </div>

              {DappsList.map((item, index) => {
                  return (
                      <AppsItem
                          key={index}
                          data={item}
                      />
                  )
              })}

              <div className="pubTitel">
                  {
                      SOLANA_CLOSE ? 'Apps based on the EthereumMetaverse open platform' : 'Apps based on the EthereumMetaverse open platform'
                  }
              </div>
          </Row>
      </>
  )
}

export default AppsHome
