import {message, Modal, Input, Pagination, Table, Image} from 'antd'
import React, {useCallback, useEffect, useState} from 'react'
import './TweetInfo.css'
import useInterval from '../../../hooks/useInterval'
import {NavLink} from "react-router-dom";
import {eth_location, logo} from "../../../assets";
import BigNumber from "bignumber.js";
import {getBalanceNumber} from "../../../utils/formatBalance";

import useTokenApprove from "../../../hooks/useTokenApprove";
import {useWeb3React} from "@web3-react/core";
import {ALL_POINT_LOGIN_KEY, INVITER_ADDR} from "../../../components/TwtLogin";
import {useX20Factory, useX20Token} from "../../../hooks/useContract";
import {TEST_CRYPTO_TOKEN, x20PubTokenList} from "../../../constants/Constants";
import {NULL_ADDRESS} from "../../../constants/addresses";
import {formatAddress} from "../../../utils/time";
import {getUrlParamParam} from "../../../utils";

const { TextArea } = Input;
const { Column } = Table;
const EnsRegist = () => {
  const { account,provider } = useWeb3React()
  const API_SERVER = process.env.REACT_APP_API_SERVER;
  const MAIN_TAG = process.env.REACT_APP_MAIN_TAG;

  const [twAccount, setTwAccount] = useState('');
  const [tokenAddress, setTokenAddress] = useState('');

  useEffect(() => {
    const index = getUrlParamParam('x');
    console.info("Token Index==>" + index);
    setTwAccount(index);
  }, [setTwAccount])

  const [tagUserList, setTagUserList] = useState([]);
  const [myJoinList, setMyJoinList] = useState([]);
  const [x20JoinUserList, setX20JoinUserList] = useState([]);
  const [tagBalance, setTagBalance] = useState(50);
  const [tagWithdraw, setTagWithdraw] = useState(50);

  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(200)
  const onChange = (page: any) => {
    setCurrent(page);
  };

  const [currentX20, setCurrentX20] = useState(1);
  const [totalX20, setTotalX20] = useState(200)
  const onChangeX20 = (page: any) => {
    setCurrentX20(page);
  };

  const [loginUserInfo, setLoginUserInfo] = useState(null);
  const [projectInfo, setProjectInfo] = useState(
      {userId:0, login: true, isReged: false, eth:'', avatar:'', nickName:'', userName:'', address:'',  token:''}
  );

  const getLoginUserProjectInfo = useCallback(async () => {
    const twToken = localStorage.getItem(ALL_POINT_LOGIN_KEY);
    console.info("twToken=" + twToken);
    // if(!twToken)return;
    try{
      const list = await fetch(API_SERVER + '/ethscription/twitter/getUserProjectInfo?projectId=' + twAccount + '&token=' +  twToken)
      const resList = await list.json()
      if (resList['code'] === 200) {
        const info = resList['data'];
        setTagBalance(info['balance']);
        setTagWithdraw(info['withdraw']);
        setLoginUserInfo(info);
      }else{
        setTagBalance(0);
        setTagWithdraw(0);
        setLoginUserInfo(null);
      }
    }catch (e) {
      console.error(e);
      setLoginUserInfo(null);
    }
  }, [API_SERVER, setLoginUserInfo, twAccount, setTagBalance, setTagWithdraw]);

  const getProjectInfo = useCallback(async () => {
    try{
      const list = await fetch(API_SERVER + '/ethscription/twitter/projectInfo?projectId=' + twAccount)
      const resList = await list.json()
      if (resList['code'] === 200) {
        const listRow = resList['data'];
        setProjectInfo(listRow['projInfo']);
        const userTokenAddress = listRow['projInfo']['token'];
        console.info('userTokenAddress=' + userTokenAddress);
        setTokenAddress(userTokenAddress);

        setTagUserList(listRow['tagJoinUserList']);
        setX20JoinUserList(listRow['x20JoinUserList']);
        setTotal(listRow['totalTag']);
        setTotalX20(listRow['totalX20']);
      }
    }catch (e) {
      console.error(e);
    }
  }, [twAccount, setTagUserList, setProjectInfo, setTokenAddress, setX20JoinUserList, setTotal, setTotalX20, ]);

  const fetchLastTime = useCallback(async () => {
    try {
      await getProjectInfo();
      await getLoginUserProjectInfo();
    } catch (e) {
      console.log(e);
    }
  }, [ twAccount,  setTagUserList, setMyJoinList]);
  useInterval(fetchLastTime);


  const [isFirstSend, setIsFirstSend] = useState(0);//首次激活不要带链接0,
  const [goonVisible, setGoonVisible] = useState(false);
  const showRule = (e: any) => {
    setGoonVisible(true);
    setIsFirstSend(e);
  };
  const cancelRule = () => {
    setGoonVisible(false);
  };

  const [messageContent, setMessageContent] = useState("");
  const messageContentChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    console.log('messageContentChange:', e.target.value);
    setMessageContent(e.target.value);
  };


  const sendTwitterMsg = useCallback(async () => {
    try{
      const twToken = localStorage.getItem(ALL_POINT_LOGIN_KEY);
      console.info("twToken=" + twToken);
      if(!twToken){
        return message.success('login twitter first');
      }
      const list = await fetch(API_SERVER + '/ethscription/twitter/sendMsg?message=' + messageContent.toString() + "&scoreName=" + (isFirstSend === 0 ? twAccount : 'SELF') + "&token=" + twToken + '&type=' + isFirstSend)
      const resList = await list.json()
      if (resList['code'] === 200) {
        message.success('send sucess');
        cancelRule();
      }else{
        message.error(resList['msg']);
      }
    }catch (e) {
      console.error(e);
    }
  }, [isFirstSend, twAccount, messageContent,]);



  //Mint
  const isDidOpen = true;
  const [canMintToken, setCanMintToken] = useState(false);
  const [tokenImage, setTokenImage] = useState();
  const [baseTokenAmount, setBaseTokenAmount] = useState(0);
  const [baseTokenAllowance, setBaseTokenAllowance] = useState(0);
  const x20Factory = useX20Factory();
  const x20Token = useX20Token(tokenAddress);
  const baseToken = useX20Token(TEST_CRYPTO_TOKEN);

  const readInfos = useCallback(async () => {
    // if(!account)return;
    try{
      if(tokenAddress){
        if(x20Token && baseToken){
          const baseAllowance = await baseToken.allowance(account, tokenAddress);
          const baseAllowanceValue = getBalanceNumber(new BigNumber(baseAllowance.toString()));
          console.info("baseAllowance=>" + baseAllowanceValue);
          const needCryptoAmount = await x20Token.getCryptoAmount();
          const needCryptoAmountValue = getBalanceNumber(new BigNumber(needCryptoAmount.toString()));
          console.info("needCryptoAmount=>" + needCryptoAmountValue);
          setBaseTokenAmount(needCryptoAmountValue);
          setBaseTokenAllowance(baseAllowanceValue);

          const userMintStatus = await x20Token.userMintStatus(account);
          console.info("userMintStatus=>" + userMintStatus);
          setCanMintToken(userMintStatus.toString() === 'false');
        }
      }
    }catch (e) {
      console.error(e);
    }
  }, [tokenAddress,
    setTokenImage,
    setCanMintToken,setBaseTokenAllowance,
    setBaseTokenAmount,]);

  useInterval(readInfos);

  const {doApprove} = useTokenApprove(TEST_CRYPTO_TOKEN, tokenAddress, '10000000000000000000');
  // const {doJoin} = useTokenJoin(tokenAddress);
  // const {doWithdraw} = useWithdrawToken(tokenAddress);
  // const {doSendFee} = useSendFee(projectInfo.userId);

  const doJoin = useCallback(async () => {
    try{
      if(x20Token){
        let inviter = localStorage.getItem(INVITER_ADDR);
        if(!inviter){
          inviter = NULL_ADDRESS;
        }
        await x20Token.userMint(inviter);
      }
    }catch (e: any){
      console.error(e);
    }
  }, [x20Token]);

  const doSendFee = useCallback(async () => {
    try{
      if(x20Factory){
        await x20Factory.sendFee(projectInfo.userId);
      }
    }catch (e: any){
      console.error(e);
    }
  }, [x20Factory]);

  return (
    <div className="TweetInfoArea">
      {/*<TwtLogin project={"twitter"}/>*/}

      <div className="login-info">
        {
          !loginUserInfo ?
              <div className="unlogin">
              </div>
              :(
                  <div className="project-divs">
                    <div className="user-area">
                      <div className="user-info">
                        <div className="row-left">
                          <img className="user-avater rounded-full" src={projectInfo.avatar} alt="" />
                          <div className="user-infos">
                            <div className="left-info">
                              <div className="title">{projectInfo.nickName}</div>
                              <div className="title">@{projectInfo.userName}</div>
                            </div>

                            <div className="right-info">
                              <div>
                                {
                                  loginUserInfo && !loginUserInfo["joinScore"] ?
                                  <button onClick={() => showRule(0)} className="send_twitter_btn marginLeft" type="submit" data-testid="home-search-button">First activation</button> : <div/>
                                }
                              </div>
                            </div>
                          </div>
                        </div>

                        {
                          projectInfo.address ? <div className="row-address">{formatAddress(projectInfo.address)}</div> : <div/>
                        }

                        {
                          projectInfo && projectInfo.eth ?
                              <div className="eth-area">
                                <img src={eth_location} alt="" />
                                <NavLink className="eth-name" id={`vote-nav-link2`} to={'/MyLands'}>{projectInfo.eth}</NavLink>
                                {/*<p className="proj-total">{'Total '+(projectInfo.isReged ? 'Points' : 'Votes')  +': '+ projectInfo.mintTotal}</p>*/}
                              </div>
                              : <div/>

                              // <div className="eth-area"><p>{'Total '+(projectInfo.isReged ? 'Points' : 'Votes') +': '+ projectInfo.mintTotal}</p> </div>
                        }



                      </div>

                      <div className="user-info right-info">
                      </div>
                    </div>

                  </div>

              )
        }
      </div>

      <div className="fast-border">
        <div className="fast-item">
          <div className="box_logo">
            <img className="rounded-full" src={tokenImage ? tokenImage : logo} alt="" />
          </div>
          <p className="text">挖矿积分</p>
          {
            baseTokenAmount >= baseTokenAllowance ? (
                <>
                  <div className="text">The Amount You Need Pay: {baseTokenAmount} CRYPTO</div>
                  <div className="do-btn" onClick={doApprove} >
                    <div className={isDidOpen ? 'ens_handle_btn' : 'ens_handle_btn_disable'}>Approve</div>
                  </div>
                </>
            ) : (
                <>
                  <div className="text">To mine</div>
                  <div className="do-btn" onClick={doJoin} >
                    <div className={canMintToken ? 'ens_handle_btn' : 'ens_handle_btn_disable'}>MINE</div>
                  </div>
                </>
            )
          }
        </div>

        <div className="fast-space"></div>

        <div className="fast-item">
          <div className="box_logo">
            <img className="rounded-full" src={tokenImage ? tokenImage : logo} alt="" />
          </div>

          <p className="text">社区积分</p>
          <p className="text">可领数量:{tagBalance} 已领数量{tagWithdraw}</p>
          {
            <div className="do-btn" onClick={tagBalance > 0 ? doSendFee: () => {console.info("no balance");}} >
              <div className={tagBalance > 0 ? 'ens_handle_btn' : 'ens_handle_btn_disable'}>领取积分</div>
            </div>
          }
        </div>


      </div>


      <div className="history">
        <div className="type-title">挖矿用户列表</div>
        <div className="table-list">
          <Table id="domainTable" dataSource={x20JoinUserList} pagination={false} >
            <Column title="User" dataIndex="avatar" key="avatar"
                    render={(_: any, record: any) => (
                        <div className="table_user">
                          <Image
                              width={20}
                              className="rule_img"
                              src={record.avatar}
                          />
                          {record.nickName}
                        </div>
                    )}
            />
            <Column title="address" dataIndex="address" key="address"/>
            <Column title="Join Time" dataIndex="createTime" key="createTime"/>
          </Table>
        </div>
        <div className="pageDiv">
          <Pagination
              total={totalX20}
              defaultPageSize={10}
              defaultCurrent={1}
              current={currentX20}
              onChange={onChangeX20}
              showSizeChanger={false}
          />
        </div>
      </div>

      <div className="history">
        <div className="type-title">社区积分领取用户列表</div>
        <div className="table-list">
          <Table id="domainTable" dataSource={tagUserList} pagination={false} >
            <Column title="User" dataIndex="avatar" key="avatar"
                    render={(_: any, record: any) => (
                        <div className="table_user">
                          <Image
                              width={20}
                              className="rule_img"
                              src={record.avatar}
                          />
                          {record.nickName}
                        </div>
                    )}
            />
            <Column title="Get Amount" dataIndex="avatar" key="avatar"
                    render={(_: any, record: any) => (
                        <div className="table_amount">
                          {record.balance + record.withdraw}
                        </div>
                    )}
            />
            <Column title="address" dataIndex="address" key="address"/>
            {/*<Column title="Symbol" dataIndex="nickName" key="nickName"/>*/}
            <Column title="Join Time" dataIndex="createTime" key="createTime"/>
          </Table>
        </div>
        <div className="pageDiv">
          <Pagination
              total={total}
              defaultPageSize={10}
              defaultCurrent={1}
              current={current}
              onChange={onChange}
              showSizeChanger={false}
          />
        </div>
      </div>

      {/*<div className="fast-border">
        <div className="fast-item">
          <div className="user-row-title">
            <div>Join Users</div>
          </div>
          {x20JoinUserList.map((item, i) => {
            return (
                <div className="user-row" key={i}>
                  <div className="row-left">
                    <img className="user-avater rounded-full" src={item.avatar} alt="" />
                    <div className="left-info">
                      <div className="title">{item.nickName}</div>
                      <div className="title">@{item.userName}</div>
                    </div>
                  </div>
                </div>
            );
          })}

          <div className="pageDiv">
            <Pagination
                total={total}
                defaultPageSize={10}
                defaultCurrent={1}
                current={current}
                onChange={onChange}
            />
          </div>
        </div>

        <div className="fast-space"></div>
        <div className="fast-item">
          <div className="user-row-title">
            <div> {twAccount} Top Holders</div>
            <div>Votes/Points</div>
          </div>
          {tagUserList.map((item, i) => {
            return (
                <div className="user-row" key={i}>
                  <div className="row-left">
                    <img className="user-avater rounded-full" src={item.avatar} alt="" />
                    <div className="left-info">
                      <div className="title">{item.nickName}</div>
                      <div className="title">@{item.userName}</div>
                      <div className="title">Holders {item.userTotal}</div>
                    </div>
                  </div>
                  <div className="row-right">
                    {item.mintTotal}
                  </div>
                </div>
            );
          })}
        </div>

        <div className="fast-item">
          <div className="user-row-title">
            <div>Your Balance</div>
            <div>Amount</div>
          </div>
          {myJoinList.map((item, i) => {
            return (
                <div className="user-row" key={i}>
                  <div className="row-left">
                    <img className="user-avater rounded-full" src={item.avatar} alt="" />
                    <div className="left-info">
                      <div className="title">{item.nickName}</div>
                      <div className="title">@{item.userName}</div>
                      <div className="title">Holders {item.userTotal}</div>
                    </div>
                  </div>
                  <div className="row-right">
                    {item.balance}
                  </div>
                </div>
            );
          })}
        </div>
      </div>*/}


      <Modal
          visible={goonVisible}
          onCancel={cancelRule}
          closable={false}
          centered={true}
          footer={null}
          className="rule_modal_box"
      >
        <div className="title tit">
          Send the post on X
        </div>

        <div className="send_tw_dialog">
          {/*<div className="search-row">
            Contents:
            <TextArea
                rows={4}
                maxLength={300}
                placeholder="Input Message Contents"
                autoCapitalize="off"
                className="search-form search-form-area"
                value={messageContent}
                onChange={messageContentChange}
            />
          </div>*/}
          <div className="search-row">
            Add Tags:
            <form className="search-form" action="#">
              <input
                  placeholder="Tags"
                  autoCapitalize="off"
                  className="modal_input"
                  disabled={true}
                  value={"#" + MAIN_TAG +" #" + (isFirstSend === 0 ? twAccount : loginUserInfo && loginUserInfo["userName"])}
              />
            </form>
          </div>
          {loginUserInfo ?
              <div className="search-row">
                My username points page:
                <form className="search-form" action="#">
                  <input
                      placeholder="Tags"
                      autoCapitalize="off"
                      className="modal_input"
                      disabled={true}
                      value={API_SERVER + '/x/' + projectInfo["userName"]}
                  />
                </form>
              </div> : <div/>
          }
          <div className="search-text">
            {
              isFirstSend===0 ? 'After the first activation, you can post a post with 2 tags attached on X to earn the username points without having to post on this website again. '
                  : 'After creating skyrocketing influence engine, you can post a post with 2 tags attached on X to earn the username points without having to post on this website again.'
            }
          </div>
        </div>
        <div  className="send_twitter_btn" onClick={sendTwitterMsg}>Send now!</div>
      </Modal>

    </div>
  )
}
export default EnsRegist
