import {Image, message, Progress, Table, Tabs} from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import against from '../../utils/against'
import { useWeb3React } from '@web3-react/core'
import rule from '../../assets/img/rule.jpg'
import dayjs from 'dayjs'

import './airdroppuls.css'
import useInterval from '../../hooks/useInterval'
import { useShareManagerContract } from '../../hooks/useContract'
import {DOG_HOST_URL, close, TX_CLOSE} from '../../constants/addresses'
import useDogHarvest from '../../hooks/useDogHarvest'
import {NavLink} from "react-router-dom";

const { TabPane } = Tabs
const AirdropPuls = (props) => {
  const { Column, ColumnGroup } = Table;
  // const close = true;
  const { account } = useWeb3React()
  const [errorMsg, setErrorMsg] = useState('')
  const [registState, setRegistState] = useState(false)

  const [userInfo, setUserInfo] = useState({
    value:0,total:0,received:0,registed:0,plana:0,planb:0,planc:0,pland:0,numa:0,list:[]
  })

  const [isFeed, setIsFeed] = useState(false)
  const [needFeed, setNeedFeed] = useState('0')
  const [inviteLink, setInviteLink] = useState('')
  const [isRule, setIsRule] = useState(false)

  useEffect(() => {
    let link = window.location.protocol + '//' + window.location.host + '/#/invite/' + account
    setInviteLink(link)
  }, [account])

  const firstHarvest = () => {
    console.log('firstHarvest')
  }
  const secondHarvest = () => {
    console.log('secondHarvest')
  }
  const copyOk = () => {
    if(close)return;
    message.success('Copy successfully')
  }

  const voteManager = useShareManagerContract();

  const fetchLastTime = useCallback(async () => {
    if(close)return;
    try {
      if (!account) return -100
      try{
        const value = await voteManager.shareToken()
        const isFeeds = await voteManager.feeFlag(account)
        setIsFeed(isFeeds)
        const totalFee = await voteManager.amount()
        setNeedFeed(totalFee)
        const isReg = await voteManager.regState(account);
        setRegistState(isReg)
      }catch (e) {}

      const response = await fetch(DOG_HOST_URL + '/api/vote/myCoin?reg=0&address=' + account.toString())
      const res = await response.json()
      if (res['code'] === 200 || res['code'] === 0) {
        setUserInfo(res);
      }
    } catch (e) {
      console.log(e)
    }
  }, [
    account,
      close,
    setIsFeed,
    setNeedFeed,
    setRegistState,
    voteManager,
    setUserInfo
  ])
  useInterval(fetchLastTime, 5000)

  const { onStake } = useDogHarvest()

  const sendFee = useCallback(async () => {
    // if(TX_CLOSE){
    //   message.error('Due to the repair of the anti-sybil system, the withdrawal function has been suspended for a few days. Domain name registration can still continue.')
    //   return;
    // }
    if(close)return;
    if (!account) {
      message.error('E_COLLECT_WOLLET')
      return
    }
    if (userInfo['value'] <= 0) {
      message.error('NEW balance is not enough')
      return
    }
    // if (isFeed) {
    //   message.error('you have handing trance,please wait')
    //   return
    // }
    try {
      await onStake(needFeed)
    } catch (e) {
      message.error(e)
      return
    }
  }, [account,close, isFeed, needFeed, userInfo, voteManager])

  const showRule = () => {
    if(close)return;
    setIsRule(true)
  }

  const doReg = useCallback(async () => {
    if (!account) {
      message.error('please connect wollect first');
      return
    }
    try {
      await voteManager.regist(account, '0x0000000000000000000000000000000000000000').then((response) => {
        console.info(response);
        message.success('bind success')
      }).catch((error) => {
        message.error('bind failed');
        console.error(error);
      });
    }catch (e) {
      console.error(e);
    }
  }, [account, voteManager])

  const [canWithDraw, setCanWithDraw] = useState(false)
  const [giftList, setGiftList] = useState([
    {
      'type': "Registration gift",
      'value': 0,
      'index': 1,
      'btn': false
    },
    {
      'type': "Inviting gift",
      'value': 0,
      'index': 2,
      'btn': false
    },
    {
      'type': "Double gift",
      'value': 0,
      'btn': false
    },
    {
      'type': "Extra gift",
      'value': 0,
      'btn': false
    },
    {
      'type': "Withdrawn",
      'value': 0,
      'index': 3,
      'btn': true
    },
  ]);

  const doWithdrawn = useCallback(async () => {
    if(close)return;
    if(!canWithDraw)return;
    // if(TX_CLOSE){
    //   message.error('Due to the repair of the anti-sybil system, the withdrawal function has been suspended for a few days.')
    //   return;
    // }
    if (!account) {
      message.error('E_COLLECT_WOLLET')
      return
    }
    let totalAmount = giftList[0]['value'] + giftList[1]['value'];
    if (totalAmount <= 0 && !canWithDraw) {
      message.error('Can Withdrawn gift is not enough')
      return
    }
    const totalFee = await voteManager.amount()
    try {
      await onStake(totalFee)
    } catch (e) {
      message.error(e)
      return
    }
  }, [account,close,giftList, voteManager, canWithDraw])


  const getEnsList = useCallback(async () => {
    if(!account)return;
    try{
      const list = await fetch(DOG_HOST_URL + '/api/domainname/self?address=' + account.toString())
      const resList = await list.json()
      if (resList['code'] === 1) {
        const listRow = resList['data'];
        const giftDatas = resList['invite'];
        setCanWithDraw(resList['canWithDraw']);
        setGiftList([
          {
            'type': "Registration gift",
            'value': giftDatas['regGift'],
            'btn': false
          },
          {
            'type': "Inviting gift",
            'value': giftDatas['inviGift'],
            'btn': false
          },
          {
            'type': "Double gift",
            'value': giftDatas['doubleGift'] ? giftDatas['doubleGift'] : 0,
            'btn': false
          },
          {
            'type': "Extra gift",
            'value': giftDatas['extraGift'] ? giftDatas['extraGift'] : 0,
            'btn': false
          },
          {
            'type': "Withdrawn",
            'value': giftDatas['withGift'],
            'btn': true
          },
        ]);
      }
    }catch (e) {
      console.error(e);
    }
  }, [account, setGiftList, setCanWithDraw]);
  const fetchLastTime2 = useCallback(async () => {
    if(close)return;
    try {
      getEnsList();
    } catch (e) {
      console.log(e);
    }
    try {
      const isReg = await voteManager.regState(account);
      setRegistState(isReg)
    } catch (e) {
      console.log(e);
      setRegistState(false)
    }
  }, [account, close, getEnsList, voteManager, setRegistState]);
  useInterval(fetchLastTime2, 3000);

  return (
    <div className="airdroppuls">

      {/*{registState ? (
          <div className="air_invite">
            <div className="title">
              You can click the &quot;Copy&quot; button to copy the invitation link to invite others to get more points.
            </div>
            <div className="copy">
              <div className="invite_link">{inviteLink}</div>
              <CopyToClipboard text={inviteLink} onCopy={copyOk}>
                <div className="copy_btn">Copy</div>
              </CopyToClipboard>
            </div>
          </div>
      ) : (
          <div className={'no_reg'}>
            <div className="no_reg_top">
              <div className="text">
                Click the &quot;Check&quot; button. If conditions are met, the non-zero data will be displayed in the column below.
              </div>
              <div className={'btn'} onClick={doReg}>
                Check
              </div>
            </div>
          </div>
      )}*/}


      <div className="air_top">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Total points Amount" key="1">
            <div className="air_left">
              <div className="left_con">
                <div className="item">
                  <div className="num">0</div>
                  <div className="tetx">Total amount</div>
                </div>
                <div className="item">
                  <div className="num">{userInfo['value']}</div>
                  <div className="tetx">To be harvested</div>
                  {/*<div className={ (TX_CLOSE || userInfo['value'] === 0) ? 'btn btn_dis' : 'btn'} onClick={sendFee}>
                    Harvest
                  </div>*/}
                </div>
                <div className="item">
                  <div className="num">{userInfo['received']}</div>
                  <div className="tetx">Harvested</div>
                </div>
              </div>
              {/*<NavLink className={"to_ens_div_yes"} to={'/domainname'}>
                <span className="rule" onClick={showRule}>
                  Register your .new domain name
                </span>
              </NavLink>*/}
{/*
              <NavLink className={userInfo['received'] > 0 ? "to_ens_div_yes" : "to_ens_div"} to={'/domainname'}
                       onClick={userInfo['received'] > 0 ? null : (e) => {e.preventDefault()}}>
                <span className="rule" onClick={showRule}>
                  Register your .new domain name
                </span>
              </NavLink>
*/}
            </div>
          </TabPane>
          <TabPane tab="Class A points" key="2">
            <div className="air_left">
              <div className="left_con">
                <div className="item">
                  <div className="num">{userInfo['numa']}</div>
                  <div className="tetx">Received</div>
                </div>
              </div>
              <div className="rule_div">
                <span className="rule" onClick={showRule}>
                  Rule
                </span>
                <Image
                  width={200}
                  style={{ display: 'none' }}
                  className="rule_img"
                  src={rule}
                  preview={{
                    visible: isRule,
                    src: rule,
                    onVisibleChange: (value) => {
                      setIsRule(value)
                    },
                  }}
                />
              </div>
            </div>
          </TabPane>
          <TabPane tab="Class B points" key="3">
            <div className="air_left">
              <div className="left_con">
                {/* <div className="item">
                  <div className="num">{userInfo['planb']}</div>
                  <div className="tetx">To be received</div>
                  <div
                    className={userInfo['planb'] === 0 ? 'btn btn_dis' : 'btn'}
                    onClick={
                      userInfo['planb'] === 0
                        ? null
                        : () => {
                            getPlanBDog(1)
                          }
                    }
                  >
                    Get
                  </div>
                </div> */}
                <div className="item">
                  <div className="num">{userInfo['planb']}</div>
                  <div className="tetx">Received</div>
                </div>
              </div>
              <div className="rule_div">
                <span className="rule" onClick={showRule}>
                  Rule
                </span>
                <Image
                  width={200}
                  style={{ display: 'none' }}
                  className="rule_img"
                  src={rule}
                  preview={{
                    visible: isRule,
                    src: rule,
                    onVisibleChange: (value) => {
                      setIsRule(value)
                    },
                  }}
                />
              </div>
            </div>
          </TabPane>
          <TabPane tab="Class C points" key="4">
            <div className="air_left">
              <div className="left_con">
                {/* <div className="item">
                  <div className="num">{userInfo['planc']}</div>
                  <div className="tetx">To be received</div>
                  <div
                    className={userInfo['planc'] === 0 ? 'btn btn_dis' : 'btn'}
                    onClick={`
                      userInfo['planc'] === 0
                        ? null
                        : () => {
                            getPlanBDog(2)
                          }
                    }
                  >
                    Get
                  </div>
                </div> */}
                <div className="item">
                  <div className="num">{userInfo['planc']}</div>
                  <div className="tetx">Received</div>
                </div>
              </div>
              <div className="rule_div">
                <span className="rule" onClick={showRule}>
                  Rule
                </span>
                <Image
                  width={200}
                  style={{ display: 'none' }}
                  className="rule_img"
                  src={rule}
                  preview={{
                    visible: isRule,
                    src: rule,
                    onVisibleChange: (value) => {
                      setIsRule(value)
                    },
                  }}
                />
              </div>
            </div>
          </TabPane>
          <TabPane tab="Class D points" key="5">
            <div className="air_left">
              <div className="left_con">
                <div className="item">
                  <div className="num">{userInfo['pland']}</div>
                  <div className="tetx">Received</div>
                </div>
              </div>
              <div className="rule_div">
                <span className="rule" onClick={showRule}>
                  Rule
                </span>
                <Image
                  width={200}
                  style={{ display: 'none' }}
                  className="rule_img"
                  src={rule}
                  preview={{
                    visible: isRule,
                    src: rule,
                    onVisibleChange: (value) => {
                      setIsRule(value)
                    },
                  }}
                />
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
      <div className="air_top_mobile">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Total points Amount" key="1">
            <div className="air_left">
              <div className="left_con">
                <div className="item">
                  <div className="line_one">
                    <div className="num">0</div>
                    <div className="tetx">Total amount</div>
                  </div>
                </div>
                <div className="item">
                  <div className="line_one">
                    <div className="num">{userInfo['value']}</div>
                    <div className="tetx">To be harvested</div>
                  </div>
                  <div className="line_two">
                    {/*<div className={userInfo['value'] == 0 ? 'btn_dis' : 'btn'} onClick={userInfo['value'] == 0 ? null : sendFee}>
                      Harvest
                    </div>*/}
                  </div>
                </div>
                <div className="item">
                  <div className="line_one">
                    <div className="num">{userInfo['received']}</div>
                    <div className="tetx">Harvested</div>
                  </div>
                </div>
              </div>
              <NavLink className={userInfo['received'] > 0 ? "to_ens_div_yes" : "to_ens_div"} to={'/domainname'}
                       onClick={userInfo['received'] > 0 ? null : (e) => {e.preventDefault()}} >
                  <span className="rule" onClick={showRule}>
                    Register your .new domain name
                  </span>
              </NavLink>
            </div>
          </TabPane>
          <TabPane tab="Class A points" key="2">
            <div className="air_left">
              <div className="left_con">
                <div className="item">
                  <div className="line_one">
                    <div className="num">{userInfo['numa']}</div>
                    <div className="tetx">Received</div>
                  </div>
                </div>
              </div>
              <div className="rule_div">
                <span className="rule" onClick={showRule}>
                  Rule
                </span>
                <Image
                  width={200}
                  style={{ display: 'none' }}
                  className="rule_img"
                  src={rule}
                  preview={{
                    visible: isRule,
                    src: rule,
                    onVisibleChange: (value) => {
                      setIsRule(value)
                    },
                  }}
                />
              </div>
            </div>
          </TabPane>
          <TabPane tab="Class B points" key="3">
            <div className="air_left">
              <div className="left_con">
                <div className="item">
                  <div className="line_one">
                    <div className="num">{userInfo['planb']}</div>
                    <div className="tetx">Received</div>
                  </div>
                </div>
              </div>
              <div className="rule_div">
                <span className="rule" onClick={showRule}>
                  Rule
                </span>
                <Image
                  width={200}
                  style={{ display: 'none' }}
                  className="rule_img"
                  src={rule}
                  preview={{
                    visible: isRule,
                    src: rule,
                    onVisibleChange: (value) => {
                      setIsRule(value)
                    },
                  }}
                />
              </div>
            </div>
          </TabPane>
          <TabPane tab="Class C points" key="4">
            <div className="air_left">
              <div className="left_con">
                {/* <div className="item">
                  <div className="num">{userInfo['planc']}</div>
                  <div className="tetx">To be received</div>
                  <div
                    className={userInfo['planc'] === 0 ? 'btn btn_dis' : 'btn'}
                    onClick={
                      userInfo['planc'] === 0
                        ? null
                        : () => {
                            getPlanBDog(2)
                          }
                    }
                  >
                    Get
                  </div>
                </div> */}
                <div className="item">
                  <div className="line_one">
                    <div className="num">{userInfo['planc']}</div>
                    <div className="tetx">Received</div>
                  </div>
                </div>
              </div>
              <div className="rule_div">
                <span className="rule" onClick={showRule}>
                  Rule
                </span>
                <Image
                  width={200}
                  style={{ display: 'none' }}
                  className="rule_img"
                  src={rule}
                  preview={{
                    visible: isRule,
                    src: rule,
                    onVisibleChange: (value) => {
                      setIsRule(value)
                    },
                  }}
                />
              </div>
            </div>
          </TabPane>
          <TabPane tab="Class D points" key="5">
            <div className="air_left">
              <div className="left_con">
                <div className="item">
                  <div className="line_one">
                    <div className="num">{userInfo['pland']}</div>
                    <div className="tetx">Received</div>
                  </div>
                </div>
              </div>
              <div className="rule_div">
                <span className="rule" onClick={showRule}>
                  Rule
                </span>
                <Image
                  width={200}
                  style={{ display: 'none' }}
                  className="rule_img"
                  src={rule}
                  preview={{
                    visible: isRule,
                    src: rule,
                    onVisibleChange: (value) => {
                      setIsRule(value)
                    },
                  }}
                />
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>

      <div className="history">
        <div className="history_t">Acquire airdrop history</div>
        <div className="table">
          <div className="thead">
            <div className="tr">
              <div className="td">Time</div>
              <div className="td">Types</div>
              <div className="td">Amount</div>
            </div>
          </div>
          <div className="tbody">
            {userInfo['list'].map((item, i) => {
              return (
                <div className="tr" key={i}>
                  <div className="td">
                    {dayjs(dayjs(item.createTime).valueOf() - 28800000).format('YYYY-MM-DDTHH:mm:ss[Z]')}(UTC)
                  </div>
                  {/*<div className="td">{timestampToTime(item.time)}</div>*/}
                  <div className="td">{item.type}</div>
                  <div className="td">{item.value} CRYPTO</div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="table_mobile">
          <div className="thead">
            <div className="tr">
              <div className="td one_td">
                <div>Time</div>
                <div>Amount</div>
              </div>
              <div className="td">Types</div>
            </div>
          </div>
          <div className="tbody">
            {userInfo['list'].map((item, i) => {
              return (
                <div className="tr" key={i}>
                  <div className="td one_td">
                    <div>{dayjs(dayjs(item.createTime).valueOf() - 28800000).format('YYYY-MM-DDTHH:mm:ss[Z]')}(UTC)</div>
                    <div> {item.value} CRYPTO</div>
                  </div>
                  {/*<div className="td">{timestampToTime(item.time)}</div>*/}
                  <div className="td">{item.type}</div>
                </div>
              )
            })}
          </div>
        </div>

      </div>


      <div className="history">
        <div className="history_t">Gift</div>
        <div className="table-list">
          <Table id="domainTable" dataSource={giftList} pagination={{ position: ['none', 'none'] }} >
            <Column title="Gift Type" dataIndex="type" key="type" />
            <Column title="Amount" dataIndex="value" key="value" />
           {/* <Column
                title="Action"
                key="action"
                render={(_: any, record: any) => (
                    <div className="table_btns">
                      {
                        record['btn'] ? <div onClick={() => doWithdrawn()} className={ (canWithDraw) ? "ens_handle_btn max_btn_width" : "ens_handle_btn_disable max_btn_width"}>Withdraw</div> : <div/>
                      }
                    </div>
                )}
            />*/}
          </Table>
        </div>
      </div>

      <NavLink className={"to_ens_reg"} to={'/UNIDID'}>
                <span className="rule" onClick={showRule}>
                  TheBitcoinStandardDAO
                </span>
      </NavLink>

    </div>
  )
}
export default AirdropPuls
