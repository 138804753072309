import {useCallback} from 'react';
import {useWeb3React} from '@web3-react/core'
import Web3 from 'web3';
import {useController} from "./useContract";
import {ADD_SECRET, ENS_CONTROLLER, PUBLIC_RESOLVER} from "../constants/addresses";
import {getProviderOrSigner} from "../utils";
import {message} from "antd";
import {swapErrorToUserReadableMessage} from "../utils/swapErrorToUserReadableMessage";


const useDomainCommit = () => {
    // const {account} = useWeb3React();
    const controller = useController();
    const { provider, connector, account, chainId } = useWeb3React()

    const handleDomainCommit = useCallback(async (domainName: string) => {
            // const balance2 = await library.getBalance(account);
            // @ts-ignore
            const feeAmount = await controller.feeAmount();
            // console.info('balance===>' + balance2 + ',feeAmount=' + feeAmount);
            // @ts-ignore
            const web3 = new Web3(provider);
            const ensController = new web3.eth.Contract([
                    {
                        "inputs": [
                            {
                                "internalType": "contract BaseRegistrarImplementation",
                                "name": "_base",
                                "type": "address"
                            },
                            {
                                "internalType": "contract PriceOracle",
                                "name": "_regPrices",
                                "type": "address"
                            },
                            {
                                "internalType": "contract PriceOracle",
                                "name": "_prices",
                                "type": "address"
                            },
                            {
                                "internalType": "contract IERC20",
                                "name": "token",
                                "type": "address"
                            },
                            {
                                "internalType": "uint256",
                                "name": "_minCommitmentAge",
                                "type": "uint256"
                            },
                            {
                                "internalType": "uint256",
                                "name": "_maxCommitmentAge",
                                "type": "uint256"
                            }
                        ],
                        "stateMutability": "nonpayable",
                        "type": "constructor"
                    },
                    {
                        "anonymous": false,
                        "inputs": [
                            {
                                "indexed": false,
                                "internalType": "string",
                                "name": "name",
                                "type": "string"
                            },
                            {
                                "indexed": true,
                                "internalType": "bytes32",
                                "name": "commitment",
                                "type": "bytes32"
                            },
                            {
                                "indexed": false,
                                "internalType": "address",
                                "name": "from",
                                "type": "address"
                            }
                        ],
                        "name": "EnsCommit2",
                        "type": "event"
                    },
                    {
                        "anonymous": false,
                        "inputs": [
                            {
                                "indexed": false,
                                "internalType": "string",
                                "name": "name",
                                "type": "string"
                            },
                            {
                                "indexed": true,
                                "internalType": "bytes32",
                                "name": "label",
                                "type": "bytes32"
                            },
                            {
                                "indexed": true,
                                "internalType": "address",
                                "name": "owner",
                                "type": "address"
                            },
                            {
                                "indexed": false,
                                "internalType": "uint256",
                                "name": "cost",
                                "type": "uint256"
                            },
                            {
                                "indexed": false,
                                "internalType": "uint256",
                                "name": "expires",
                                "type": "uint256"
                            }
                        ],
                        "name": "NameRegistered",
                        "type": "event"
                    },
                    {
                        "anonymous": false,
                        "inputs": [
                            {
                                "indexed": false,
                                "internalType": "string",
                                "name": "name",
                                "type": "string"
                            },
                            {
                                "indexed": true,
                                "internalType": "bytes32",
                                "name": "label",
                                "type": "bytes32"
                            },
                            {
                                "indexed": false,
                                "internalType": "uint256",
                                "name": "cost",
                                "type": "uint256"
                            },
                            {
                                "indexed": false,
                                "internalType": "uint256",
                                "name": "expires",
                                "type": "uint256"
                            }
                        ],
                        "name": "NameRenewed",
                        "type": "event"
                    },
                    {
                        "anonymous": false,
                        "inputs": [
                            {
                                "indexed": true,
                                "internalType": "address",
                                "name": "oracle",
                                "type": "address"
                            }
                        ],
                        "name": "NewPriceOracle",
                        "type": "event"
                    },
                    {
                        "anonymous": false,
                        "inputs": [
                            {
                                "indexed": true,
                                "internalType": "address",
                                "name": "previousOwner",
                                "type": "address"
                            },
                            {
                                "indexed": true,
                                "internalType": "address",
                                "name": "newOwner",
                                "type": "address"
                            }
                        ],
                        "name": "OwnershipTransferred",
                        "type": "event"
                    },
                    {
                        "inputs": [],
                        "name": "MIN_REGISTRATION_DURATION",
                        "outputs": [
                            {
                                "internalType": "uint256",
                                "name": "",
                                "type": "uint256"
                            }
                        ],
                        "stateMutability": "view",
                        "type": "function"
                    },
                    {
                        "inputs": [],
                        "name": "ONE_YEAR",
                        "outputs": [
                            {
                                "internalType": "uint256",
                                "name": "",
                                "type": "uint256"
                            }
                        ],
                        "stateMutability": "view",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "bytes32[]",
                                "name": "nodeList",
                                "type": "bytes32[]"
                            }
                        ],
                        "name": "authNodes",
                        "outputs": [],
                        "stateMutability": "nonpayable",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "bytes32[]",
                                "name": "nodeList",
                                "type": "bytes32[]"
                            },
                            {
                                "internalType": "uint256",
                                "name": "value",
                                "type": "uint256"
                            }
                        ],
                        "name": "authNodesState",
                        "outputs": [],
                        "stateMutability": "nonpayable",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "string",
                                "name": "name",
                                "type": "string"
                            }
                        ],
                        "name": "available",
                        "outputs": [
                            {
                                "internalType": "bool",
                                "name": "",
                                "type": "bool"
                            }
                        ],
                        "stateMutability": "view",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "bytes32",
                                "name": "",
                                "type": "bytes32"
                            }
                        ],
                        "name": "commitments",
                        "outputs": [
                            {
                                "internalType": "uint256",
                                "name": "",
                                "type": "uint256"
                            }
                        ],
                        "stateMutability": "view",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "string",
                                "name": "name",
                                "type": "string"
                            },
                            {
                                "internalType": "address",
                                "name": "owner1",
                                "type": "address"
                            },
                            {
                                "internalType": "bytes32",
                                "name": "secret",
                                "type": "bytes32"
                            },
                            {
                                "internalType": "address",
                                "name": "resolver",
                                "type": "address"
                            },
                            {
                                "internalType": "address",
                                "name": "addr",
                                "type": "address"
                            }
                        ],
                        "name": "doCommitment",
                        "outputs": [
                            {
                                "internalType": "bytes32",
                                "name": "",
                                "type": "bytes32"
                            }
                        ],
                        "stateMutability": "payable",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "string",
                                "name": "name",
                                "type": "string"
                            },
                            {
                                "internalType": "bytes32",
                                "name": "secret",
                                "type": "bytes32"
                            },
                            {
                                "internalType": "address",
                                "name": "resolver",
                                "type": "address"
                            }
                        ],
                        "name": "fastCommitment",
                        "outputs": [
                            {
                                "internalType": "bytes32",
                                "name": "",
                                "type": "bytes32"
                            }
                        ],
                        "stateMutability": "payable",
                        "type": "function"
                    },
                    {
                        "inputs": [],
                        "name": "feeAmount",
                        "outputs": [
                            {
                                "internalType": "uint256",
                                "name": "",
                                "type": "uint256"
                            }
                        ],
                        "stateMutability": "view",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "string",
                                "name": "name",
                                "type": "string"
                            },
                            {
                                "internalType": "address",
                                "name": "owner",
                                "type": "address"
                            },
                            {
                                "internalType": "bytes32",
                                "name": "secret",
                                "type": "bytes32"
                            }
                        ],
                        "name": "makeCommitment",
                        "outputs": [
                            {
                                "internalType": "bytes32",
                                "name": "",
                                "type": "bytes32"
                            }
                        ],
                        "stateMutability": "pure",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "string",
                                "name": "name",
                                "type": "string"
                            },
                            {
                                "internalType": "address",
                                "name": "owner",
                                "type": "address"
                            },
                            {
                                "internalType": "bytes32",
                                "name": "secret",
                                "type": "bytes32"
                            },
                            {
                                "internalType": "address",
                                "name": "resolver",
                                "type": "address"
                            },
                            {
                                "internalType": "address",
                                "name": "addr",
                                "type": "address"
                            }
                        ],
                        "name": "makeCommitmentWithConfig",
                        "outputs": [
                            {
                                "internalType": "bytes32",
                                "name": "",
                                "type": "bytes32"
                            }
                        ],
                        "stateMutability": "pure",
                        "type": "function"
                    },
                    {
                        "inputs": [],
                        "name": "maxCommitmentAge",
                        "outputs": [
                            {
                                "internalType": "uint256",
                                "name": "",
                                "type": "uint256"
                            }
                        ],
                        "stateMutability": "view",
                        "type": "function"
                    },
                    {
                        "inputs": [],
                        "name": "minCommitmentAge",
                        "outputs": [
                            {
                                "internalType": "uint256",
                                "name": "",
                                "type": "uint256"
                            }
                        ],
                        "stateMutability": "view",
                        "type": "function"
                    },
                    {
                        "inputs": [],
                        "name": "owner",
                        "outputs": [
                            {
                                "internalType": "address",
                                "name": "",
                                "type": "address"
                            }
                        ],
                        "stateMutability": "view",
                        "type": "function"
                    },
                    {
                        "inputs": [],
                        "name": "prices",
                        "outputs": [
                            {
                                "internalType": "contract PriceOracle",
                                "name": "",
                                "type": "address"
                            }
                        ],
                        "stateMutability": "view",
                        "type": "function"
                    },
                    {
                        "inputs": [],
                        "name": "regPrices",
                        "outputs": [
                            {
                                "internalType": "contract PriceOracle",
                                "name": "",
                                "type": "address"
                            }
                        ],
                        "stateMutability": "view",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "bytes32",
                                "name": "",
                                "type": "bytes32"
                            }
                        ],
                        "name": "registState",
                        "outputs": [
                            {
                                "internalType": "uint256",
                                "name": "",
                                "type": "uint256"
                            }
                        ],
                        "stateMutability": "view",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "string",
                                "name": "name",
                                "type": "string"
                            },
                            {
                                "internalType": "bytes32",
                                "name": "secret",
                                "type": "bytes32"
                            },
                            {
                                "internalType": "address",
                                "name": "resolver",
                                "type": "address"
                            }
                        ],
                        "name": "register",
                        "outputs": [],
                        "stateMutability": "payable",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "string",
                                "name": "name",
                                "type": "string"
                            },
                            {
                                "internalType": "address",
                                "name": "owner",
                                "type": "address"
                            },
                            {
                                "internalType": "bytes32",
                                "name": "secret",
                                "type": "bytes32"
                            },
                            {
                                "internalType": "address",
                                "name": "resolver",
                                "type": "address"
                            },
                            {
                                "internalType": "address",
                                "name": "addr",
                                "type": "address"
                            }
                        ],
                        "name": "registerWithConfig",
                        "outputs": [],
                        "stateMutability": "payable",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "string",
                                "name": "name",
                                "type": "string"
                            },
                            {
                                "internalType": "uint256",
                                "name": "duration",
                                "type": "uint256"
                            }
                        ],
                        "name": "renew",
                        "outputs": [],
                        "stateMutability": "payable",
                        "type": "function"
                    },
                    {
                        "inputs": [],
                        "name": "renounceOwnership",
                        "outputs": [],
                        "stateMutability": "nonpayable",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "string",
                                "name": "name",
                                "type": "string"
                            },
                            {
                                "internalType": "uint256",
                                "name": "duration",
                                "type": "uint256"
                            },
                            {
                                "internalType": "bool",
                                "name": "reg",
                                "type": "bool"
                            }
                        ],
                        "name": "rentPrice",
                        "outputs": [
                            {
                                "internalType": "uint256",
                                "name": "",
                                "type": "uint256"
                            }
                        ],
                        "stateMutability": "view",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "uint256",
                                "name": "_minCommitmentAge",
                                "type": "uint256"
                            },
                            {
                                "internalType": "uint256",
                                "name": "_maxCommitmentAge",
                                "type": "uint256"
                            }
                        ],
                        "name": "setCommitmentAges",
                        "outputs": [],
                        "stateMutability": "nonpayable",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "uint256",
                                "name": "value",
                                "type": "uint256"
                            }
                        ],
                        "name": "setFeeAmount",
                        "outputs": [],
                        "stateMutability": "nonpayable",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "address",
                                "name": "value",
                                "type": "address"
                            }
                        ],
                        "name": "setManagerAddress",
                        "outputs": [],
                        "stateMutability": "nonpayable",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "contract PriceOracle",
                                "name": "_prices",
                                "type": "address"
                            }
                        ],
                        "name": "setPriceOracle",
                        "outputs": [],
                        "stateMutability": "nonpayable",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "contract PriceOracle",
                                "name": "_prices",
                                "type": "address"
                            }
                        ],
                        "name": "setRegPriceOracle",
                        "outputs": [],
                        "stateMutability": "nonpayable",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "address",
                                "name": "value",
                                "type": "address"
                            }
                        ],
                        "name": "setShareToken",
                        "outputs": [],
                        "stateMutability": "nonpayable",
                        "type": "function"
                    },
                    {
                        "inputs": [],
                        "name": "shareToken",
                        "outputs": [
                            {
                                "internalType": "contract IERC20",
                                "name": "",
                                "type": "address"
                            }
                        ],
                        "stateMutability": "view",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "bytes4",
                                "name": "interfaceID",
                                "type": "bytes4"
                            }
                        ],
                        "name": "supportsInterface",
                        "outputs": [
                            {
                                "internalType": "bool",
                                "name": "",
                                "type": "bool"
                            }
                        ],
                        "stateMutability": "pure",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "address",
                                "name": "newOwner",
                                "type": "address"
                            }
                        ],
                        "name": "transferOwnership",
                        "outputs": [],
                        "stateMutability": "nonpayable",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "string",
                                "name": "name",
                                "type": "string"
                            }
                        ],
                        "name": "valid",
                        "outputs": [
                            {
                                "internalType": "bool",
                                "name": "",
                                "type": "bool"
                            }
                        ],
                        "stateMutability": "pure",
                        "type": "function"
                    },
                    {
                        "inputs": [],
                        "name": "withdraw",
                        "outputs": [],
                        "stateMutability": "nonpayable",
                        "type": "function"
                    }
                ]
                , ENS_CONTROLLER);
            const data = ensController.methods.fastCommitment(domainName, ADD_SECRET, PUBLIC_RESOLVER).encodeABI();
            // @ts-ignore
            provider
                .getSigner()
                .sendTransaction({
                    from: account,
                    to: ENS_CONTROLLER,
                    data: data,
                    value: feeAmount
                    // let the wallet try if we can't estimate the gas
                    // ...('gasEstimate' in bestCallOption ? { gasLimit: calculateGasMargin(bestCallOption.gasEstimate) } : {}),
                    // ...(value && !isZero(value) ? { value } : {}),
                })
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    // if the user rejected the tx, pass this along
                    if (error?.code === 4001) {
                        // throw new Error(`Transaction rejected.`)
                        message.error("Transaction rejected.");
                    } else {
                        // otherwise, the error was unexpected and we need to convey that
                        // console.error(`Swap failed`, error, address, calldata, value)
                        message.error(`failed`);
                    }
                    console.error(error);
                })
        },
        [account, connector, provider, controller, PUBLIC_RESOLVER, ADD_SECRET],
    );
    return { onCommit: handleDomainCommit };
};

export default useDomainCommit;



