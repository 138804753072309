import {message, Modal, Progress, Radio, Table, Tabs} from 'antd'
import React, {useCallback, useEffect, useState} from 'react'
import {useWeb3React} from '@web3-react/core'
import dayjs from 'dayjs'
import BigNumber from 'bignumber.js'
import './ens.css'
import {
  useBaseRegistrarNft,
  useController,
  useCryptoToken,
  useENS,
  usePublicResolver,
  useRegistPriceOracle,
  useReverseRegistrar,
  useShareManagerContract,
  useTokenPriceOracle
} from '../../hooks/useContract';
import {
  ADD_SECRET,
  close,
  CRYPTO_TOKEN,
  DOG_HOST_URL,
  ENS_CONTROLLER,
  ENS_END,
  ONE_YEAR,
  PUBLIC_RESOLVER,
  TX_CLOSE
} from "../../constants/addresses";
import {getBalanceNumber, getMinBalance} from "../../utils/formatBalance";
import {hasNonAscii, parseSearchTerm} from "../../utils/validateName";
import useInterval from '../../hooks/useInterval'
import useDomainCommit from "../../hooks/useDomainCommit";
import searchIcon from './Search/search.svg'
import {CopyToClipboard} from "react-copy-to-clipboard";
import useDogHarvest from "../../hooks/useDogHarvest";
import {NavLink} from "react-router-dom";

const { Column, ColumnGroup } = Table;
const { TabPane } = Tabs
const EnsRegist = (props) => {
  const { account } = useWeb3React()
  const cryptoToken = useCryptoToken();
  const ens = useENS();
  const controller = useController();
  const baseRegistrar = useBaseRegistrarNft();
  const publicResolver = usePublicResolver();
  const reverseRegistrar = useReverseRegistrar();
  const tokenPriceOracle = useTokenPriceOracle();
  const registPriceOracle = useRegistPriceOracle();
  const shareManager = useShareManagerContract();

  const { onCommit } = useDomainCommit();

  const [checkInfo, setCheckInfo] = useState({
    b:undefined,f:undefined,all:undefined,
  });

  const [tokenBalance, setTokenBalance] = useState(0);
  const [tokenAllowance, setTokenAllowance] = useState(0);
  const [tokenNeed, setTokenNeed] = useState(0);
  const [ensNameMuch, setEnsNameMuch] = useState(false);
  const [hasNoAsc, setHasNoAsc] = useState(false);
  const [ensList, setEnsList] = useState([]);

  const [regState, setRegState] = useState(0);
  const [hasRegedEns, setHasRegedEns] = useState(false);
  const [domainName, setDomainName] = useState("");
  const [domainNameCheckErr, setDomainNameCheckErr] = useState(0);
  const domainNameChange = ({ target: { value } }) => {
    let checkedName = value.toString().toLowerCase().replace(" ", "");
    setDomainName(checkedName);
  };


  const [registState, setRegistState] = useState(false)
  const copyOk = () => {
    if(close)return;
    if(!hasRegedEns){
      return  message.success('Please complete the domain name registration first.');
    }
    message.success('Copy successfully')
  }
  const voteManager = useShareManagerContract();
  const [inviteLink, setInviteLink] = useState('')
  useEffect(() => {
    let link = window.location.protocol + '//' + window.location.host + '/#/invite/' + account
    setInviteLink(link)
  }, [account])

  // const { getName,getResolver,getOwner,getTTL, createSubdomain } = useEnsTool();

  const initHandle = useCallback(async () => {
    // await registPriceOracle.setPrices('200000000000000000');
    // const price = await tokenPriceOracle.getTokenEthPrice();
    // const priceNew = getBalanceNumber(new BigNumber(price.toString()), 18);
    // console.info('price=' + price +'|priceNew='+priceNew)

    // await controller.setShareToken(CRYPTO_TOKEN);
    // await shareManager.setShareToken(CRYPTO_TOKEN);

    // await controller.setFeeAmount('0');
    // await controller.setFeeAmount('2000000000000000');
    // await controller.setManagerAddress('0x0ca517a79119389808593524775d1dedfed00940');

    // await controller.setRegPrice('10000000000000000');
    await controller.setGoOnPrice('100000000000000000');
    // const price = (new BigNumber(await tokenPriceOracle.rentPrices(0))).toString();
    // const price = await tokenPriceOracle.regPrices();
    // console.info(price);

    // const priceOracle = await controller.regPrices();
    // console.info('priceOracle=' + priceOracle);
    // await tokenPriceOracle.setPrices([99999000, 99999000, 10000, 10000, 10000]);

    // await baseRegistrar.addController(ENS_CONTROLLER);
    // await controller.setPriceOracle(tokenPriceOracle);
    // await controller.setRegState([account], false);
    // await baseRegistrar.setBaseUrl('https://newcryptospace.com/api/domainname/mate?id=');
    // await controller.doCommitment(domainName, account, secret, PUBLIC_RESOLVER, account);
    // var commitment = await controller.makeCommitmentWithConfig(domainName, account, secret, PUBLIC_RESOLVER, account);
    // console.info('commitment==>'+commitment);
    // await controller.authNodes([commitment]);

    // var tokenURI = await baseRegistrar.tokenURI('75996404866922495749963021992354359041740452350126598603006648594842177015891');
    // console.info('tokenURI==>'+tokenURI);
  }, [account, domainName, controller, baseRegistrar, registPriceOracle, ENS_CONTROLLER, CRYPTO_TOKEN, shareManager]);

  const autoToken = useCallback(async () => {
    if(close)return;
    const tokenAllowance = getBalanceNumber(new BigNumber((await cryptoToken.allowance(account, ENS_CONTROLLER)).toString()));
    const tokenNeed = getBalanceNumber(new BigNumber((await controller.rentPrice(domainName, ONE_YEAR, true)).toString()));
    if(tokenAllowance >= tokenNeed && tokenAllowance > 0){
      console.info('has authed');
      return;
    }
    const authAmount = '80000000000000000000000';
    await cryptoToken.approve(ENS_CONTROLLER, authAmount);
  }, [account, close,cryptoToken, ENS_CONTROLLER, controller, domainName]);

  const searchHandle = useCallback(async () => {
    if(close)return;
    if(domainName.length <= 0){message.error('The address is incorrect'); return;}
    try {
      let balance = getMinBalance(new BigNumber((await cryptoToken.balanceOf(account)).toString()), 18, 2);
      let balanceEnoph = parseFloat(balance.toString()) >= 0.2;
      let nameMuchCode = 0;
      let hasRegEns = await controller.regState(account);
      setHasRegedEns(hasRegEns);
      if(domainName.length < 1){
        setRegState(0);
        setEnsNameMuch(false);
        setTokenNeed(0);
        nameMuchCode = 1;
      }else{
        try{
          let domainNameCheck = domainName+ENS_END;
          console.info('domainNameCheck=' + domainNameCheck);
          const _type = await parseSearchTerm(domainNameCheck, true);
          const hasNoAscs = await hasNonAscii(domainNameCheck);
          setHasNoAsc(hasNoAscs);
          console.info(domainNameCheck + ' type=' + _type + ' |hasNoAsc=' + hasNoAsc);
          if(_type === 'supported' || _type === 'tld' || _type === 'search'){
            var commitment = await controller.makeCommitmentWithConfig(domainName, account, ADD_SECRET, PUBLIC_RESOLVER, account);
            const state = await controller.registState(commitment);
            const available = await controller.available(domainName);
            const cust = getBalanceNumber(new BigNumber((await controller.rentPrice(domainName, ONE_YEAR, true)).toString()));
            setRegState(parseInt(state.toString()));
            nameMuchCode = available?0:4;
            setEnsNameMuch(available);
            setTokenNeed(cust);
            balanceEnoph = parseFloat(balance.toString()) -  parseFloat(cust.toString()) >=0;
          }else{
            if (_type === 'invalid') {
              nameMuchCode = 2;
            }else{
              nameMuchCode = 3;
            }
            setRegState(0);
            setEnsNameMuch(false);
            setTokenNeed(0);
          }
        } catch (e) {
          console.error(e);
        }
      }
      setDomainNameCheckErr(nameMuchCode);

      const stateObj = {}
      stateObj['b'] = balanceEnoph;
      stateObj['f'] = (nameMuchCode === 0);
      stateObj['all'] = stateObj['b'] && stateObj['f'];
      setCheckInfo(stateObj);
      autoToken();
    }catch (e) {
      console.error(e);
    }
  }, [account, close,domainName, setCheckInfo, controller, onCommit, autoToken,setHasRegedEns,
    setHasNoAsc,setRegState,setDomainNameCheckErr,setEnsNameMuch,setTokenNeed,ADD_SECRET, PUBLIC_RESOLVER,
  ]);

  const fistCommitHandle = useCallback(async () => {
    if(close)return;
    if (domainName.length <= 0) {
      return message.error('All the previous confirmation is required. Click after the button changes color.');
    }
    if (!checkInfo['all']) {
      return message.error('All the previous confirmation is required. Click after the button changes color.');
    }
    await onCommit(domainName);
  },[onCommit,close, domainName, checkInfo]);

  const getRegState = useCallback(async (domainName) => {
    if(close)return;
    try{
      setTokenBalance(getMinBalance(new BigNumber((await cryptoToken.balanceOf(account)).toString()), 18, 2));
      setTokenAllowance(getBalanceNumber(new BigNumber((await cryptoToken.allowance(account, ENS_CONTROLLER)).toString())));
      let hasRegEns = await controller.regState(account);
      setHasRegedEns(hasRegEns);

      if(domainName.length >= 1){
        try{
          let domainNameCheck = domainName+ENS_END;
          const _type = await parseSearchTerm(domainNameCheck, true);
          console.info(domainNameCheck + ' type=' + _type + ' |hasNoAsc=' + hasNoAsc);
          if(_type === 'supported' || _type === 'tld' || _type === 'search'){
            var commitment = await controller.makeCommitmentWithConfig(domainName, account, ADD_SECRET, PUBLIC_RESOLVER, account);
            const state = await controller.registState(commitment);
            const available = await controller.available(domainName);
            const cust = getBalanceNumber(new BigNumber((await controller.rentPrice(domainName, ONE_YEAR, true)).toString()));
            setRegState(parseInt(state.toString()));
            setTokenNeed(cust);
          }else{
            setRegState(0);
          }
        } catch (e) {
          console.error(e);
        }
      }

    } catch (e) {
      console.error(e);
    }
  }, [account,close,setHasRegedEns, setHasNoAsc, setDomainNameCheckErr, controller, setRegState, cryptoToken, setEnsNameMuch, ADD_SECRET, PUBLIC_RESOLVER, ONE_YEAR, ENS_CONTROLLER, setTokenNeed, setTokenBalance, setTokenAllowance]);

  const registHandle = useCallback(async () => {
    if(close)return;
    if( (tokenAllowance >= tokenNeed && tokenAllowance > 0) && regState === 2 && checkInfo['all'] ){
      try{
        await controller.registerWithConfig(domainName, account, ADD_SECRET, PUBLIC_RESOLVER, account);
      }catch (e) {
        return message.error('Failed to register domain name. You have already registered.');
      }
    }else{
      return message.error('All the previous confirmation is required. Please wait patiently, click after the button changes color.');
    }
  }, [account,close, regState, checkInfo, domainName, tokenAllowance, tokenNeed, controller, ADD_SECRET, PUBLIC_RESOLVER]);

  const setDefaultHost = useCallback(async (name) => {
    // if(close)return;
    await reverseRegistrar.setName(name);
    // return message.error('You need to wait until December 1st, 2023 for the resolution time.');
  }, [account, close,reverseRegistrar]);

  const getEnsList = useCallback(async () => {
    if(!account)return;
    try{
      const list = await fetch(DOG_HOST_URL + '/api/domainname/self?address=' + account.toString())
      const resList = await list.json()
      if (resList['code'] === 1) {
        const listRow = resList['data'];
        setEnsList(listRow);
      }
    }catch (e) {
      console.error(e);
    }
  }, [account, setEnsList,]);
  const fetchLastTime = useCallback(async () => {
    if(close)return;
    try {
      getEnsList();
      getRegState(domainName);
    } catch (e) {
      console.log(e);
    }
    try {
      const isReg = await voteManager.regState(account);
      setRegistState(isReg)
    } catch (e) {
      console.log(e);
      setRegistState(false)
    }
  }, [account, close, getEnsList, setEnsList, getRegState, domainName, cryptoToken, voteManager, setRegistState]);
  useInterval(fetchLastTime, 3000);


  const fetchBusinesses = useCallback(async () => {
    if(close)return;
    console.info('only one' + account);
    if(!account)return;
    getEnsList();
  }, [account, close, getEnsList]);

  useEffect(() => {fetchBusinesses()}, [fetchBusinesses])

  interface DataType {
    key: React.Key;
    firstName: string;
    lastName: string;
    age: number;
    address: string;
    tags: string[];
  }


  const [goonVisible, setGoonVisible] = useState(false);
  const [goonEns, setGoonEns] = useState({});
  const showRule = (e) => {
    // if(close)return;
    // return message.error('You need to wait until December 1st, 2023 for the renewal time.');
    setGoonVisible(true);
    setGoonEns(e);
  };
  const cancelRule = () => {
    setGoonVisible(false);
  };
  const [goonYear, setGoonYear] = useState(0);
  const [goonNeed, setGoonNeed] = useState(0);
  const onYearChange = async (e) => {
    console.log('radio checked', e.target.value);
    setGoonYear(e.target.value);
    var year = e.target.value;
    const cust = getBalanceNumber(new BigNumber((await controller.rentPrice(goonEns.name, year * ONE_YEAR, false)).toString()));
    setGoonNeed(cust);
  };

  const goonEnsHandle = useCallback(async () => {
    if(close)return;
    if(goonYear === 0){
      return message.error('please select goon time');
    }

    const name = goonEns.code;
    const time = ONE_YEAR * goonYear;


    const tokenAllowance = getBalanceNumber(new BigNumber((await cryptoToken.allowance(account, ENS_CONTROLLER)).toString()));
    const tokenNeed = getBalanceNumber(new BigNumber((await controller.rentPrice(name, time, true)).toString()));
    if(tokenAllowance < tokenNeed){
      console.info('need authed');
      const authAmount = '80000000000000000000000';
      await cryptoToken.approve(ENS_CONTROLLER, authAmount).then((response) => {
        message.success('Auth success');
        setGoonVisible(false);
      }).catch((error) => {
        message.error('ERROR');
        console.log(error);
      });
      return;
    }

    await controller.renew(name, time).then((response) => {
      console.info(response);
      message.success('SUCCESS');
      setGoonVisible(false);
    }).catch((error) => {
      message.error('ERROR');
      console.log(error);
    });
  }, [account, close, controller, ONE_YEAR, goonEns, setGoonVisible, goonYear, ENS_CONTROLLER]);


  //////////////////////////////////////////////////////////////////
  const doReg = useCallback(async () => {
    if(close)return;
    if (!account) {
      message.error('please connect wollect first');
      return
    }
    try {
      await voteManager.regist(account, '0x0000000000000000000000000000000000000000').then((response) => {
        console.info(response);
        message.success('bind success')
      }).catch((error) => {
        message.error('bind failed');
        console.error(error);
      });
    }catch (e) {
      console.error(e);
    }
  }, [account, close,voteManager])



  return (
    <div className="ens_div">
      <div className="ens_title">
        <p>New Crypto Space</p>
        <p>Domain Name</p>
      </div>
      <div className="air_invite">
        <div className="search-area">
          <form className="search-form" action="#">
            <img src={searchIcon}/>
            <input
                placeholder="Input Name"
                autoCapitalize="off"
                className="modal_input"
                value={domainName}
                onChange={domainNameChange}
              />
              <div className="end">
                {ENS_END}
              </div>
            </form>
            <button onClick={() => searchHandle()} className="earch_button" type="submit" data-testid="home-search-button">Check & Auth</button>
        </div>

        <div className="area_check_view">
          <div className="title" onClick={initHandle}>1.Check result</div>
          <li className="row">1.The number of $CRYPTO held: {tokenBalance}  {checkInfo.b === undefined ? <p></p> : <Progress className="modal_progress" type="circle" percent={100} width={20} status={checkInfo.b ? '' : 'exception'} />}</li>
          <li className="row">2. Whether the characters entered can be registered: {checkInfo.f === undefined ? <p></p> : <Progress className="modal_progress" type="circle" percent={100} width={20} status={checkInfo.f ? '' : 'exception'} />}</li>
          <li className="row">3. Authorized payment of $CRYPTO tokens (CRYPTO tokens authorized only): { (tokenAllowance >= tokenNeed && tokenAllowance > 0) ? <Progress className="modal_progress" type="circle" percent={100} width={20}/> : <p/>}</li>
        </div>

        <div className="area_check_view">
          <div className="title">2. Domain name registration</div>
          <li className="row"><p className="minWidth">a.Submit registration application:</p>
            {
              (regState > 0) ? <Progress className="modal_progress" type="circle" percent={100} width={20}/>
              : <div className={ checkInfo['all'] ? "ens_handle_btn" : "ens_handle_btn_disable"}  onClick={() => fistCommitHandle()} >Submit</div>
            }

          </li>
          <li className="row"><p className="minWidth">b.Initiate registration:</p>
            {
              (regState === 3) ? <Progress className="modal_progress" type="circle" percent={100} width={20}/>
              : <div className={ ((tokenAllowance >= tokenNeed && tokenAllowance > 0) && regState === 2 && checkInfo['all']) ? "ens_handle_btn" : "ens_handle_btn_disable"} onClick={() => registHandle()} >Registration</div>
            }
          </li>
        </div>

      </div>

      <div className="history">
        <div className="history_t">My domain name</div>
        <div className="table-list">
          <Table id="domainTable" dataSource={ensList}  >
            <Column title="Domain name" dataIndex="name" key="name" />
            <Column title="Expiration time" dataIndex="expiryTime" key="expiryTime"
                    render={(_: any, record: DataType) => (
                        <div className="table_time">
                          {dayjs(dayjs(record.expiryTime).valueOf() - 28800000).format('YYYY-MM-DDTHH:mm:ss[Z]')}(UTC)
                        </div>
                    )}
            />
            <Column
                title="Action"
                key="action"
                render={(_: any, record: DataType) => (
                    <div className="table_btns">
                      {/*<a className="disable_btn" onClick={() => setDefaultHost(record.name)}>Resolution</a>*/}
                      <a className="renew_btn" onClick={() => showRule(record)}>Renewal</a>
                    </div>
                )}
            />
          </Table>
        </div>
      </div>

      {/*<div className="air_invite_div">
        <div className="title">

          You can click the &quot;Copy&quot; button to copy the invitation link to invite others to get more airdrops.
          If yours and your invitee&apos;s address have completed the domain name registration, you will automatically receive invitation airdrop rewards.

        </div>
        <div className="copy">
          <div className="invite_link">{inviteLink}</div>
          <CopyToClipboard text={inviteLink} onCopy={copyOk}>
            <div className={ hasRegedEns ? "ens_handle_btn" : "ens_handle_btn_disable"}>Copy</div>
          </CopyToClipboard>
        </div>
      </div>*/}

      {/*<a className={"to_airport_div"} href={'/UNIDID'} >
          <span className="rule" >
           Go to mint UNIDID
          </span>
      </a>*/}

      <NavLink className={"to_airport_div"} to={'/UNIDID'}>
          <span className="rule">
            TheBitcoinStandardDAO
          </span>
      </NavLink>


      <Modal
          visible={goonVisible}
          onCancel={cancelRule}
          closable={false}
          centered={true}
          footer={null}
          className="rule_modal_box"
      >
        <div className="title tit">
          Renew domain name {goonEns.name}
        </div>
        <div className="box">
          <div className="vote_type">
            Renewal Duration
          </div>
          <div className="vote_year">
            <Radio.Group onChange={onYearChange} value={goonYear}>
              <Radio value={2}>2 Years</Radio>
              <Radio value={5}>5 Years</Radio>
              <Radio value={10}>10 Years</Radio>
              <Radio value={20}>20 Years</Radio>
              <Radio value={50}>50 Years</Radio>
              <Radio value={100}>100 Years</Radio>
            </Radio.Group>
          </div>
          <div className="vote_price">Estimated Cost {goonNeed}</div>
        </div>
        <div  className={ goonYear > 0 ? "ens_handle_btn" : "ens_handle_btn_disable"} onClick={goonEnsHandle}>Renewal</div>
      </Modal>
    </div>
  )
}
export default EnsRegist
