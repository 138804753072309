import {useCallback, useEffect, useState} from "react";
import "./PubTokenPage.css";

import Masonry from "react-masonry-css";
import PubTokenItem from "./PubTokenItem";
import {Button, Input, Select, Spin} from "antd";
import {CloseOutlined, DownOutlined} from "@ant-design/icons";

import {search} from "../../../assets";

const { Search } = Input;
export const PubTokenPage = () => {
    const API_SERVER = process.env.REACT_APP_API_SERVER;

    // 瀑布流的不同宽度的列数
    const breakpointColumnsObj = {
        default: 5,
        1200: 4,
        1000: 3,
        768: 1,
    };
    const data = [
        {
            name: "Item 1",
            desc: "dkdkandsndkljands",
            img: "https://picsum.photos/300",
            total: 10000,
            sold: 200,
            marketcap: 1000,
            increase: 10,
        },
        {
            name: "Item 2",
            desc: "hahsdahshdhhdsa",
            img: "https://picsum.photos/500",
            total: 10000,
            sold: 200,
            marketcap: 1000,
            increase: 10,
        },
        {
            name: "Item 3",
            desc: "BTC, ETH, SOL, BASE, and more. Not Financial advice, always DYOR",
            img: "https://picsum.photos/300",
            total: 10000,
            sold: 200,
            marketcap: 1000,
            increase: 10,
        },
        {
            name: "Item 4",
            desc: "The prophecy of SIMTRON token predicts that its market cap will reach $1 billion by October.With new social platforms and no website,the prophet needs no validation.Believe in the prophecy &amp; join us,or stay away if you don’t,Join the Prophecy family TG &amp; X",
            img: "https://picsum.photos/200",
            total: 10000,
            sold: 200,
            marketcap: 1000,
            increase: 10,
        },
        {
            name: "Item 1",
            desc: "The prophecy of SIMTRON token predicts that its market cap will reach $1 billion by October.With new social platforms and no website,the prophet needs no validation.Believe in the prophecy &amp; join us,or stay away if you don’t,Join the Prophecy family TG &amp; XThe prophecy of SIMTRON token predicts that its market cap will reach $1 billion by October.With new social platforms and no website,the prophet needs no validation.Believe in the prophecy &amp; join us,or stay away if you don’t,Join the Prophecy family TG &amp; X",
            img: "https://picsum.photos/300",
            total: 10000,
            sold: 200,
            marketcap: 1000,
            increase: 10,
        },
        {
            name: "Item 2",
            desc: "hahsdahshdhhdsa",
            img: "https://picsum.photos/400",
            total: 10000,
            sold: 200,
            marketcap: 1000,
            increase: 10,
        },
        {
            name: "Item 3",
            desc: "Meet Welcome to Dog Wif Tron, Dive in, and enjoy the vibe. Here’s to making more great memories together! VC starts 09:00 UTC! The mascot of the Tron Network",
            img: "https://picsum.photos/300",
            total: 10000,
            sold: 200,
            marketcap: 1000,
            increase: 10,
        },
        {
            name: "Item 4",
            desc: "hahsdahshdhhdsa",
            img: "https://picsum.photos/300",
            total: 10000,
            sold: 200,
            marketcap: 1000,
            increase: 10,
        },
        {
            name: "Item 5",
            desc: "hahsdahshdhhdsa",
            img: "https://picsum.photos/300",
            total: 10000,
            sold: 200,
            marketcap: 1000,
            increase: 10,
        },
        {
            name: "Item 1",
            desc: "dkdkandsndkljands",
            img: "https://picsum.photos/300",
            total: 10000,
            sold: 200,
            marketcap: 1000,
            increase: 10,
        },
    ];
    const [list, setList] = useState([
        {
            name: "Item 1",
            desc: "dkdkandsndkljands",
            img: "https://picsum.photos/300",
            total: 10000,
            sold: 200,
            marketcap: 1000,
            increase: 10,
        },
        {
            name: "Item 2",
            desc: "hahsdahshdhhdsa",
            img: "https://picsum.photos/500",
            total: 10000,
            sold: 200,
            marketcap: 1000,
            increase: 10,
        },
        {
            name: "Item 3",
            desc: "BTC, ETH, SOL, BASE, and more. Not Financial advice, always DYOR",
            img: "https://picsum.photos/300",
            total: 10000,
            sold: 200,
            marketcap: 1000,
            increase: 10,
        },
        {
            name: "Item 4",
            desc: "The prophecy of SIMTRON token predicts that its market cap will reach $1 billion by October.With new social platforms and no website,the prophet needs no validation.Believe in the prophecy &amp; join us,or stay away if you don’t,Join the Prophecy family TG &amp; X",
            img: "https://picsum.photos/200",
            total: 10000,
            sold: 200,
            marketcap: 1000,
            increase: 10,
        },
        {
            name: "Item 1",
            desc: "The prophecy of SIMTRON token predicts that its market cap will reach $1 billion by October.With new social platforms and no website,the prophet needs no validation.Believe in the prophecy &amp; join us,or stay away if you don’t,Join the Prophecy family TG &amp; XThe prophecy of SIMTRON token predicts that its market cap will reach $1 billion by October.With new social platforms and no website,the prophet needs no validation.Believe in the prophecy &amp; join us,or stay away if you don’t,Join the Prophecy family TG &amp; X",
            img: "https://picsum.photos/300",
            total: 10000,
            sold: 200,
            marketcap: 1000,
            increase: 10,
        },
        {
            name: "Item 2",
            desc: "hahsdahshdhhdsa",
            img: "https://picsum.photos/400",
            total: 10000,
            sold: 200,
            marketcap: 1000,
            increase: 10,
        },
        {
            name: "Item 3",
            desc: "Meet Welcome to Dog Wif Tron, Dive in, and enjoy the vibe. Here’s to making more great memories together! VC starts 09:00 UTC! The mascot of the Tron Network",
            img: "https://picsum.photos/300",
            total: 10000,
            sold: 200,
            marketcap: 1000,
            increase: 10,
        },
        {
            name: "Item 4",
            desc: "hahsdahshdhhdsa",
            img: "https://picsum.photos/300",
            total: 10000,
            sold: 200,
            marketcap: 1000,
            increase: 10,
        },
        {
            name: "Item 5",
            desc: "hahsdahshdhhdsa",
            img: "https://picsum.photos/300",
            total: 10000,
            sold: 200,
            marketcap: 1000,
            increase: 10,
        },
        {
            name: "Item 1",
            desc: "dkdkandsndkljands",
            img: "https://picsum.photos/300",
            total: 10000,
            sold: 200,
            marketcap: 1000,
            increase: 10,
        },
        {
            name: "Item 2",
            desc: "hahsdahshdhhdsa",
            img: "https://picsum.photos/300",
            total: 10000,
            sold: 200,
            marketcap: 1000,
            increase: 10,
        },
        {
            name: "Item 3",
            desc: "BTC, ETH, SOL, BASE, and more. Not Financial advice, always DYOR",
            img: "https://picsum.photos/300",
            total: 10000,
            sold: 200,
            marketcap: 1000,
            increase: 10,
        },
        {
            name: "Item 4",
            desc: "The prophecy of SIMTRON token predicts that its market cap will reach $1 billion by October.With new social platforms and no website,the prophet needs no validation.Believe in the prophecy &amp; join us,or stay away if you don’t,Join the Prophecy family TG &amp; X",
            img: "https://picsum.photos/300",
            total: 10000,
            sold: 200,
            marketcap: 1000,
            increase: 10,
        },
        {
            name: "Item 1",
            desc: "The prophecy of SIMTRON token predicts that its market cap will reach $1 billion by October.With new social platforms and no website,the prophet needs no validation.Believe in the prophecy &amp; join us,or stay away if you don’t,Join the Prophecy family TG &amp; XThe prophecy of SIMTRON token predicts that its market cap will reach $1 billion by October.With new social platforms and no website,the prophet needs no validation.Believe in the prophecy &amp; join us,or stay away if you don’t,Join the Prophecy family TG &amp; X",
            img: "https://picsum.photos/300",
            total: 10000,
            sold: 200,
            marketcap: 1000,
            increase: 10,
        },
        {
            name: "Item 2",
            desc: "hahsdahshdhhdsa",
            img: "https://picsum.photos/300",
            total: 10000,
            sold: 200,
            marketcap: 1000,
            increase: 10,
        },
        {
            name: "Item 3",
            desc: "Meet Welcome to Dog Wif Tron, Dive in, and enjoy the vibe. Here’s to making more great memories together! VC starts 09:00 UTC! The mascot of the Tron Network",
            img: "https://picsum.photos/300",
            total: 10000,
            sold: 200,
            marketcap: 1000,
            increase: 10,
        },
        {
            name: "Item 4",
            desc: "hahsdahshdhhdsa",
            img: "https://picsum.photos/300",
            total: 10000,
            sold: 200,
            marketcap: 1000,
            increase: 10,
        },
        {
            name: "Item 5",
            desc: "hahsdahshdhhdsa",
            img: "https://picsum.photos/300",
            total: 10000,
            sold: 200,
            marketcap: 1000,
            increase: 10,
        },
    ]);

    const [x20ProjectList, setX20ProjectList] = useState([]);
    const [pageNum, setPageNum] = useState(1)
    const [total, setTotal] = useState(0)
    const [keyword, setKeyword] = useState("");
    const [order, setOrder] = useState("1");

    const fetchProjects = useCallback(async (reset: boolean) => {
        try{
            if(reset){
                setPageNum(1);
            }
            setLoading(true);
            const chain = 'ARB';
            const pageSize = '50';
            const list = await fetch(API_SERVER + '/v2api/x20/tokenList?chain='
                + chain +"&keyword=" + keyword + "&pageNum=" + pageNum + "&pageSize=" + pageSize + "&order=" + order)
            const resObj = await list.json()
            if (resObj['code'] === 200) {
                let newList = reset ? [] : [...x20ProjectList];
                // @ts-ignore
                newList.push(...resObj['data']);
                setX20ProjectList(newList);
                setTotal(resObj['total']);
            }
            setLoading(false);
        }catch (e) {
            console.error(e);
            setLoading(false);
        }
    }, [setX20ProjectList, setTotal, x20ProjectList, setPageNum, pageNum, keyword]);

    // 搜索
    const onSearch = async (value:string) => {
        console.log("search", value);
        setKeyword(value);
        await fetchProjects(true);
    };

    // 选择器内容
    const options = [
        {
            value: "1",
            label: "Launched Time",
        },
        {
            value: "2",
            label: "Market Cap",
        },
        // {
        //     value: "3",
        //     label: "Trading Volume",
        // },
        // {
        //     value: "4",
        //     label: "24H Price Increase",
        // },
    ];

    // 选择器改变
    const handleChange = async(value:any) => {
        console.log("select", value);
        setOrder(value);
        await fetchProjects(true);
    };

    // 加载中
    const [loading, setLoading] = useState(false);
    // 更多按钮
    const moreButton = async() => {
        setPageNum(pageNum + 1);
        await fetchProjects(false);
    };

    useEffect(() => {
        console.info("init read project data");
        fetchProjects(true);
    }, [])

    return (
        <div className="PubTokenPageArea">
            <div className="home_header">
                <div className="home_header_search">
                    <Search
                        placeholder="Search for tokens"
                        prefix={<img src={search} />}
                        allowClear={{
                            clearIcon: <CloseOutlined style={{ color: "white" }} />,
                        }}
                        onSearch={onSearch}
                        className="home_header_search_input"
                    />
                </div>
                <div className="home_header_select">
                    <Select
                        defaultValue={order}
                        onChange={handleChange}
                        options={options}
                        defaultOpen
                        suffixIcon={<DownOutlined style={{ color: "white" }} />}
                        className="home_header_select_input"
                        popupClassName="home_header_select_popup"
                    />
                </div>
            </div>
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="list"
                columnClassName="list_column"
            >
                {x20ProjectList.map((item, i) => (
                    <PubTokenItem item={item} key={i} />
                ))}
            </Masonry>
            <div className="home_more">
                {loading ? (
                    <Spin />
                ) : (
                    <Button
                        type="primary"
                        className="home_more_button"
                        onClick={moreButton}
                    >
                        Load More
                    </Button>
                )}
            </div>
        </div>
    );
};
export default PubTokenPage;
