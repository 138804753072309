import React, {ReactNode, useContext} from 'react'
import {AlertCircle, X} from 'react-feather'
import styled, {ThemeContext} from 'styled-components/macro'
import {ButtonPrimary} from "../Button";
import {ThemedText} from "../../theme";

const StyledModal = styled.div`
  position: fixed;
  display: flex;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  height: fit-content;
  color: ${({ theme }) => theme.textPrimary};
  font-size: 18px;
  padding: 20px;
  background-color: ${({ theme }) => theme.backgroundSurface};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.backgroundOutline};
  z-index: 100;
  flex-direction: column;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.backgroundOutline};
`

function Modal({ open, children }: { open: boolean; children: ReactNode }) {
  return open ? <StyledModal>{children}</StyledModal> : null
}

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  //align-items: center;
  padding: 8px 0px;
`

const CloseButton = styled.button`
  cursor: pointer;
  background: ${({ theme }) => theme.none};
  border: none;
  color: ${({ theme }) => theme.textPrimary};
`

const Header = styled(Row)`
  font-weight: 600;
  font-size: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.backgroundOutline};
  margin-bottom: 8px;
`




export default function BtcAddrErrModal({
                                            open,
                                            btcList,
                                            close,
        btcErrorType
                                        }: {
    open: boolean
    btcList: []
    close: () => void
    btcErrorType: 0
}) {
    const theme = useContext(ThemeContext)
    return (
    <Modal open={open}>
      <Header>
          {btcErrorType === 0 ? 'Bitcoin addresses cannot be repeated' : 'You must type into your own Bitcoin wallet address starting with "bc1p" in the box'}
        <CloseButton onClick={close}>
          <X size={24} />
        </CloseButton>
      </Header>

        {/*<Loader size={'20rem'}/>*/}

        {/*<CheckCircle color={theme.deprecated_green1} size={24} />*/}


        {btcList.map((btc) => {
            return (<Row>
                <AlertCircle color={theme.deprecated_red1} size={24} /><div>{btc}</div>
            </Row>)
        })}


        <ButtonPrimary onClick={close}>
            <ThemedText.DeprecatedMediumHeader color="white">
                Close
            </ThemedText.DeprecatedMediumHeader>
        </ButtonPrimary>
    </Modal>
  )
}
