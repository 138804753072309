import React, {useCallback, useEffect, useState} from "react";
import {message, Modal} from "antd";
import {NavLink} from "react-router-dom";
import {default_avater, eth_location, eth_location_m, x20_log} from "../../assets";
import {useWeb3React} from "@web3-react/core";
export const IS_ALL_POINT = false;
export const ALL_POINT_LOGIN_KEY = IS_ALL_POINT ? "ALL_POINT_LOGIN_KEY" : "TWEET_LOGIN_KEY";
export const INVITER_ADDR = "INVITER_ADDR";
import {isMobile} from "../../utils/userAgent";
interface TwtProps {
    project: string;
}
import './TwtInfos.css'

const TwtInfos = () => {
    const { account,provider } = useWeb3React()
    const thisPlant = IS_ALL_POINT ? "allPoint" : "twitter";
    const API_SERVER = process.env.REACT_APP_API_SERVER;




    return (

        <div className="TwtInfosArea">
            <div className="TwtInfos">
                <div className="TwtImg">
                    <NavLink className="font-poppins cursor-pointer text-[12.8px]" to={'/MyLands'}>
                        <img className="TwtImgAvater" src={default_avater} alt=""/>
                    </NavLink>
                </div>
                <div className="TwtEth">
                    <NavLink className="font-poppins cursor-pointer text-[12.8px]" to={'/MyLands'}>
                        <img src={eth_location} alt=""/>
                    </NavLink>
                </div>
            </div>
        </div>
    );
}
export default TwtInfos
