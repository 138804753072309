import {Input, message, Modal, Table, Tabs} from 'antd'
import React, {useCallback, useEffect, useState} from 'react'
import {useWeb3React} from '@web3-react/core'
import './TweetLogin.css'
import {close, DOG_HOST_URL, IS_TEST, MAIN_TAG, TW_LOGIN_URL} from "../../../constants/addresses";
import useInterval from '../../../hooks/useInterval'
import searchIcon from '../../Ens/Search/search.svg'
import {NavLink} from "react-router-dom";
import eth_location from "../../../assets/svg/eth_location.svg";
import {formatAddress} from "../../../utils/time";

const { TextArea } = Input;
const TweetLogin = (props) => {
  const { account, provider } = useWeb3React()
  const [domainName, setDomainName] = useState("");
  const domainNameChange = ({ target: { value } }) => {
    let checkedName = value.toString().toLowerCase().replace(" ", "");
    setDomainName(checkedName);
  };
  const [twTags, setTwTags] = useState('');
  // const [loginUserInfo, setLoginUserInfo] = useState({login: true, isReged: false, activeScore: '0', eth:'xxx.eth', avatar:'https://pbs.twimg.com/profile_images/1644851643228516353/-adFBedl_normal.jpg', nickName:'nickname', userName:'username', address:'xxxxxxxxxxxxxxx', });

  const [loginUserInfo, setLoginUserInfo] = useState(IS_TEST
  ? {login: true, isReged: false, activeScore: '0', eth:'xxx.eth', avatar:'https://ipfs.io/ipfs/QmeeKwuahs7upTeDXtX55HHtnssaxhKoD7X9hsUp4SuWCn/8658.jpg', nickName:'nickname', userName:'username', address:'xxxxxxxxxxxxxxx', }
  : {login: false, isReged: true, activeScore: '0', eth:'', avatar:'', nickName:'', userName:'', address:'', }
  );
  useEffect(() => {
    let urlString = window.location.hash
    let tempParam = urlString.substring(urlString.indexOf('/') + 1)
    if (tempParam.indexOf('?') > 0) {
      if(tempParam.length > 11){
        tempParam = tempParam.substring(11, tempParam.indexOf('?'));
      }
    }
    tempParam = tempParam.replaceAll("TweetTotal/", "");
    if(tempParam.length > 10){
      localStorage.setItem("DOG-TW-LOGIN-KEY", tempParam);
      console.info('save twToken=' + tempParam);

      const twToken = localStorage.getItem("DOG-TW-LOGIN-KEY");
      console.info('保存后读取到twToken=' + twToken);
    }
  }, [])

  const getLoginUserInfo = useCallback(async () => {
    const twToken = localStorage.getItem("DOG-TW-LOGIN-KEY");
    console.info('twToken=' + twToken);
    if(!twToken)return;
    if(IS_TEST)return;
    try{
      const list = await fetch(DOG_HOST_URL + '/ethscription/twitter/getUserInfo?token=' + twToken.toString())
      const resList = await list.json()
      if (resList['code'] === 200) {
        const info = resList['data'];
        setTwTags("#"+MAIN_TAG+" #" + info.userName);
        setLoginUserInfo({login: true, isReged: info.reged, activeScore:info.activeScore, eth: info.eth, avatar:info.avatar, nickName:info.nickName, userName:info.userName, address:info.address});
      }else{
        setLoginUserInfo({login: false, isReged: false, activeScore: '0',eth:'', avatar:'', nickName:'', userName:'', address:'', });
      }
    }catch (e) {
      console.error(e);
      setLoginUserInfo({login: false, activeScore: '0',eth:'', avatar:'', nickName:'', userName:'', address:'', });
    }
  }, [setLoginUserInfo,]);

  const fetchLastTime = useCallback(async () => {
    try {
      getLoginUserInfo();
    } catch (e) {
      console.log(e);
    }
  }, [account, domainName,]);
  useInterval(fetchLastTime, 3000);


  const searchHandle = useCallback(async () => {
    if(domainName.length <= 0){message.error('The name is incorrect'); return;}
    try {
      const list = await fetch(DOG_HOST_URL + '/ethscription/twitter/twUserSearch?keyword=' + domainName.toString())
      const response = await list.json()
      if (response['code'] === 200) {
        window.location.href = '/#/x/' + domainName;
      }else{
        message.error(response['msg']);
      }
    }catch (e) {
      console.error(e);
    }
  }, [domainName,]);

  const loginTwitter = useCallback(async () => {
    window.location.href = TW_LOGIN_URL;
  }, [account, TW_LOGIN_URL]);

  const bindAddress = useCallback(async () => {
    try{
      const twToken = localStorage.getItem("DOG-TW-LOGIN-KEY");
      console.info('twToken=' + twToken);
      if(!twToken){
        return message.success('login twitter first');
      }
      const params = ("address="+ account.toLowerCase() + "\nmessage=Hello");
      const signature = (await provider.send('personal_sign', [params, account.toLowerCase()]));
      console.info('message=  ' + params);
      console.info('signature=  ' + signature);
      const list = await fetch(DOG_HOST_URL + '/ethscription/twitter/bindAddress?address=' + account.toString() + "&token=" + twToken +"&signature=" + signature)
      const response = await list.json()
      if (response['code'] === 200) {
        message.success('bind sucess');
      }else{
        message.error(response['msg']);
      }
    }catch (e) {
      console.error(e);
    }
  }, [account,]);


////////////////////
  const [isFirstSend, setIsFirstSend] = useState(false);
  const [goonVisible, setGoonVisible] = useState(false);
  const showRule = (e) => {
    setGoonVisible(true);
    setIsFirstSend(e);
  };
  const cancelRule = () => {
    setGoonVisible(false);
  };

  const [messageContent, setMessageContent] = useState("");
  const messageContentChange = ({ target: { value } }) => {
    setMessageContent(value);
  };


  const sendTwitterMsg = useCallback(async () => {
    try{
      const twToken = localStorage.getItem("DOG-TW-LOGIN-KEY");
      console.info('twToken=' + twToken);
      if(!twToken){
        return message.success('login twitter first');
      }
      const list = await fetch(DOG_HOST_URL + '/ethscription/twitter/sendMsg?message=' + messageContent.toString() + "&scoreName=SELF" + "&token=" + twToken + '&type=2')
      const resList = await list.json()
      if (resList['code'] === 200) {
        message.success('send sucess');
        cancelRule();
      }else{
        message.error(resList['msg']);
      }
    }catch (e) {
      console.error(e);
    }
  }, [ messageContent,]);


  return (
    <div className="TweetLogin">

      <div className="login-info">
        {
          !loginUserInfo.login ?
              <div className="unlogin">
                <button onClick={() => loginTwitter()} className="login_button" type="submit" data-testid="home-search-button">Login X1</button>
              </div>
              :(
                  <div className="project-divs">
                    <div className="user-area">
                      <div className="user-info">
                        {/*{
                          loginUserInfo.activeScore === '1' ? <div/> :
                          <button onClick={() => showRule()} className="login_button marginRight" type="submit" data-testid="home-search-button">Create my skyrocketing influence engine</button>
                          }*/}
                      </div>
                      <div className="user-info right-info">
                        <div className="row-left">
                          <div className="right-info">
                            {
                              loginUserInfo.address ? <div/> :
                                  <button onClick={() => bindAddress()} className="login_button marginRight" type="submit" data-testid="home-search-button">Bind the wallet</button>
                            }
                          </div>

                          <img className="user-avater rounded-full" src={loginUserInfo.avatar} alt="" />
                          <div className="user-infos">
                            <div className="left-info">
                              <div className="useritem">{loginUserInfo.nickName}</div>
                              <div className="useritem">@{loginUserInfo.userName}</div>
                            </div>
                          </div>
                        </div>
                        {
                          loginUserInfo.address ? <div className="row-address">{formatAddress(loginUserInfo.address)}</div> : <div/>
                        }

                        {
                          loginUserInfo && loginUserInfo.eth ?
                              <div className="eth-area">
                                <img src={eth_location} alt="" />
                                <NavLink className="eth-name" id={`vote-nav-link`} to={'/MyLands'}>{loginUserInfo.eth}</NavLink>
                              </div>
                              : <div/>
                        }

                      </div>
                    </div>

                  </div>

              )
        }

        <div className="project-divs mt-2">
          <div className="user-area">
            <div/>
            <div className="user-info right-info">
              <div className="eth-area">
                <img src={eth_location} alt="" />
                <NavLink className="font-poppins font-normal cursor-pointer text-[14.8px] ml-1" to="https://newcryptospace.com/#/MyLands"> Lands</NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="air_invite">
        <div className="main-search-area">
          <form className="search-form" action="#">
            <img src={searchIcon}/>
            <input
                placeholder="Input X username"
                autoCapitalize="off"
                className="modal_input"
                value={domainName}
                onChange={domainNameChange}
              />
            </form>
            <button onClick={() => searchHandle()} className="earch_button" type="submit" data-testid="home-search-button">Search</button>
        </div>
      </div>



      <Modal
          visible={goonVisible}
          onCancel={cancelRule}
          closable={false}
          centered={true}
          footer={null}
          className="rule_modal_box"
      >
        <div className="title tit">
          Send the post on X
        </div>

        <div className="send_tw_dialog">
          <div className="search-row">
            Contents:
            <TextArea
                rows={40}
                maxLength={300}
                placeholder="Input Message Contents"
                autoCapitalize="off"
                className="search-form search-form-area"
                value={messageContent}
                onChange={messageContentChange}
            />
          </div>
          <div className="search-row">
            Add Tags:
            <form className="search-form" action="#">
              <input
                  placeholder="Tags"
                  autoCapitalize="off"
                  className="modal_input"
                  disabled="disabled"
                  value={twTags}
              />
            </form>
          </div>
          {isFirstSend ?
              <div/> :
              <div className="search-row">
                My username points page:
                <form className="search-form" action="#">
                  <input
                      placeholder="Tags"
                      autoCapitalize="off"
                      className="modal_input"
                      disabled="disabled"
                      value={DOG_HOST_URL + '/#/x/' + loginUserInfo.userName}
                  />
                </form>
              </div>
          }

          <div className="search-text">
            After creating skyrocketing influence engine, you can post a post with 2 tags attached on X to earn the username points without having to post on this website again.
          </div>

        </div>
        <div  className="send_twitter_btn" onClick={sendTwitterMsg}>Send now!</div>
      </Modal>

    </div>
  )
}
export default TweetLogin
