import {useCallback} from 'react';
import {useWeb3React} from '@web3-react/core'
import Web3 from 'web3';
import {useController, useDidFeeManager, useDidNft, useNftToBtcUtils} from "./useContract";
import {ADD_SECRET, DID_FEE_MANAGER, NFT_TO_BTC_UTILS, PUBLIC_RESOLVER} from "../constants/addresses";
import {getProviderOrSigner} from "../utils";
import {message} from "antd";
import {swapErrorToUserReadableMessage} from "../utils/swapErrorToUserReadableMessage";
import {BigNumber} from "bignumber.js";


const useBtcInscribe = () => {
    // const {account} = useWeb3React();
    const btcInscribeUtils = useNftToBtcUtils();
    const { provider, connector, account, } = useWeb3React()
    const handleDomainCommit = useCallback(async (nft: string, idList: string[], btcList: string[], back: (hash: any) => void) => {
            // @ts-ignore
            const feeAmount = await btcInscribeUtils.amount();
            console.info( ',feeAmount=' + feeAmount);
            const lastEthValue = new BigNumber(feeAmount).mul(new BigNumber(idList.length));
            // @ts-ignore
            const web3 = new Web3(provider);
            const ensController = new web3.eth.Contract([
                    {
                        "inputs": [],
                        "stateMutability": "nonpayable",
                        "type": "constructor"
                    },
                    {
                        "anonymous": false,
                        "inputs": [
                            {
                                "indexed": true,
                                "internalType": "address",
                                "name": "previousOwner",
                                "type": "address"
                            },
                            {
                                "indexed": true,
                                "internalType": "address",
                                "name": "newOwner",
                                "type": "address"
                            }
                        ],
                        "name": "OwnershipTransferred",
                        "type": "event"
                    },
                    {
                        "anonymous": false,
                        "inputs": [
                            {
                                "indexed": false,
                                "internalType": "address",
                                "name": "nft",
                                "type": "address"
                            },
                            {
                                "indexed": false,
                                "internalType": "uint256",
                                "name": "tokenId",
                                "type": "uint256"
                            },
                            {
                                "indexed": false,
                                "internalType": "string",
                                "name": "btc",
                                "type": "string"
                            }
                        ],
                        "name": "oneInsc",
                        "type": "event"
                    },
                    {
                        "anonymous": false,
                        "inputs": [
                            {
                                "indexed": false,
                                "internalType": "address",
                                "name": "nft",
                                "type": "address"
                            },
                            {
                                "indexed": false,
                                "internalType": "string[]",
                                "name": "btcList",
                                "type": "string[]"
                            },
                            {
                                "indexed": false,
                                "internalType": "uint256[]",
                                "name": "idList",
                                "type": "uint256[]"
                            }
                        ],
                        "name": "toInsc",
                        "type": "event"
                    },
                    {
                        "inputs": [],
                        "name": "BURN_ADDR",
                        "outputs": [
                            {
                                "internalType": "address",
                                "name": "",
                                "type": "address"
                            }
                        ],
                        "stateMutability": "view",
                        "type": "function"
                    },
                    {
                        "inputs": [],
                        "name": "amount",
                        "outputs": [
                            {
                                "internalType": "uint256",
                                "name": "",
                                "type": "uint256"
                            }
                        ],
                        "stateMutability": "view",
                        "type": "function"
                    },
                    {
                        "inputs": [],
                        "name": "amount1",
                        "outputs": [
                            {
                                "internalType": "uint256",
                                "name": "",
                                "type": "uint256"
                            }
                        ],
                        "stateMutability": "view",
                        "type": "function"
                    },
                    {
                        "inputs": [],
                        "name": "amount2",
                        "outputs": [
                            {
                                "internalType": "uint256",
                                "name": "",
                                "type": "uint256"
                            }
                        ],
                        "stateMutability": "view",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "string",
                                "name": "",
                                "type": "string"
                            }
                        ],
                        "name": "btcState",
                        "outputs": [
                            {
                                "internalType": "bool",
                                "name": "",
                                "type": "bool"
                            }
                        ],
                        "stateMutability": "view",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "string[]",
                                "name": "btcList",
                                "type": "string[]"
                            }
                        ],
                        "name": "checkBtcBatch",
                        "outputs": [
                            {
                                "internalType": "string[]",
                                "name": "",
                                "type": "string[]"
                            }
                        ],
                        "stateMutability": "view",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "address",
                                "name": "nft",
                                "type": "address"
                            },
                            {
                                "internalType": "uint256",
                                "name": "tokenId",
                                "type": "uint256"
                            },
                            {
                                "internalType": "string",
                                "name": "btc",
                                "type": "string"
                            }
                        ],
                        "name": "checkNft",
                        "outputs": [],
                        "stateMutability": "nonpayable",
                        "type": "function"
                    },
                    {
                        "inputs": [],
                        "name": "fee",
                        "outputs": [
                            {
                                "internalType": "uint256",
                                "name": "",
                                "type": "uint256"
                            }
                        ],
                        "stateMutability": "view",
                        "type": "function"
                    },
                    {
                        "inputs": [],
                        "name": "feeAddress1",
                        "outputs": [
                            {
                                "internalType": "address",
                                "name": "",
                                "type": "address"
                            }
                        ],
                        "stateMutability": "view",
                        "type": "function"
                    },
                    {
                        "inputs": [],
                        "name": "feeAddress2",
                        "outputs": [
                            {
                                "internalType": "address",
                                "name": "",
                                "type": "address"
                            }
                        ],
                        "stateMutability": "view",
                        "type": "function"
                    },
                    {
                        "inputs": [],
                        "name": "getBalance",
                        "outputs": [
                            {
                                "internalType": "uint256",
                                "name": "",
                                "type": "uint256"
                            }
                        ],
                        "stateMutability": "view",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "address",
                                "name": "",
                                "type": "address"
                            },
                            {
                                "internalType": "uint256",
                                "name": "",
                                "type": "uint256"
                            }
                        ],
                        "name": "nftState",
                        "outputs": [
                            {
                                "internalType": "bool",
                                "name": "",
                                "type": "bool"
                            }
                        ],
                        "stateMutability": "view",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "address",
                                "name": "to",
                                "type": "address"
                            }
                        ],
                        "name": "out",
                        "outputs": [],
                        "stateMutability": "nonpayable",
                        "type": "function"
                    },
                    {
                        "inputs": [],
                        "name": "owner",
                        "outputs": [
                            {
                                "internalType": "address",
                                "name": "",
                                "type": "address"
                            }
                        ],
                        "stateMutability": "view",
                        "type": "function"
                    },
                    {
                        "inputs": [],
                        "name": "renounceOwnership",
                        "outputs": [],
                        "stateMutability": "nonpayable",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "address",
                                "name": "nft",
                                "type": "address"
                            },
                            {
                                "internalType": "uint256[]",
                                "name": "idList",
                                "type": "uint256[]"
                            },
                            {
                                "internalType": "string[]",
                                "name": "btcList",
                                "type": "string[]"
                            }
                        ],
                        "name": "sendToBtcBatch",
                        "outputs": [],
                        "stateMutability": "payable",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "uint256",
                                "name": "_amount",
                                "type": "uint256"
                            }
                        ],
                        "name": "setAmount1",
                        "outputs": [],
                        "stateMutability": "nonpayable",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "uint256",
                                "name": "_amount",
                                "type": "uint256"
                            }
                        ],
                        "name": "setAmount2",
                        "outputs": [],
                        "stateMutability": "nonpayable",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "uint256",
                                "name": "_amount",
                                "type": "uint256"
                            }
                        ],
                        "name": "setFee",
                        "outputs": [],
                        "stateMutability": "nonpayable",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "address",
                                "name": "value",
                                "type": "address"
                            }
                        ],
                        "name": "setFeeAddress1",
                        "outputs": [],
                        "stateMutability": "nonpayable",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "address",
                                "name": "value",
                                "type": "address"
                            }
                        ],
                        "name": "setFeeAddress2",
                        "outputs": [],
                        "stateMutability": "nonpayable",
                        "type": "function"
                    },
                    {
                        "inputs": [
                            {
                                "internalType": "address",
                                "name": "newOwner",
                                "type": "address"
                            }
                        ],
                        "name": "transferOwnership",
                        "outputs": [],
                        "stateMutability": "nonpayable",
                        "type": "function"
                    }
                ]
                , NFT_TO_BTC_UTILS);
            const data = ensController.methods.sendToBtcBatch(nft, idList, btcList).encodeABI();
            // @ts-ignore
            provider
                .getSigner()
                .sendTransaction({
                    from: account,
                    to: NFT_TO_BTC_UTILS,
                    data: data,
                    value: lastEthValue.toString()
                    // let the wallet try if we can't estimate the gas
                    // ...('gasEstimate' in bestCallOption ? { gasLimit: calculateGasMargin(bestCallOption.gasEstimate) } : {}),
                    // ...(value && !isZero(value) ? { value } : {}),
                })
                .then((response) => {
                    back({state: true, data: response.hash});
                    return response
                })
                .catch((error) => {

                    // if the user rejected the tx, pass this along
                    if (error?.code === 4001) {
                        // throw new Error(`Transaction rejected.`)
                        message.error("Transaction rejected.");
                        back({state: false, data: "Transaction rejected."});
                    } else {
                        // otherwise, the error was unexpected and we need to convey that
                        // console.error(`Swap failed`, error, address, calldata, value)
                        message.error(`failed`);
                        back({state: false, data: "Transaction failed."});
                    }
                    console.error(error);
                })
        },
        [account, connector, provider, btcInscribeUtils],
    );
    return { payBtcInscribeFee: handleDomainCommit };
};

export default useBtcInscribe;



