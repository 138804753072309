import {Form, Image, Input, message, Table} from 'antd';
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import "./BtcInscribe.css";
import {DAO_NFT, NFT_TO_BTC_UTILS} from "../../../constants/addresses";
import useInterval from "../../../hooks/useInterval";
import {useWeb3React} from "@web3-react/core";
import useBtcInscribe from "../../../hooks/useBtcInscribe";
import {useNftToBtcUtils, useDaoStandNft} from "../../../hooks/useContract";
import UrlBtnTitle from "../../components/UrlBtnTitle";
import BtcAddrErrModal from "../../../components/FeatureFlagModal/BtcAddrErrModal";
import BtcHandingModal from "../../../components/FeatureFlagModal/BtcHandingModal";

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};
const EditableCell = ({
                          title,
                          editable,
                          children,
                          dataIndex,
                          record,
                          handleSave,
                          ...restProps
                      }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);
    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };
    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({
                ...record,
                ...values,
            });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };
    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save}  className="editable-cell-input"/>
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap  editable-cell-input"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
};
// @ts-ignore
const BtcInscribe = () => {
    const [dataSource, setDataSource] = useState([]);
    const [count, setCount] = useState(2);
    const handleDelete = (key) => {
        const newData = dataSource.filter((item) => item.key !== key);
        setDataSource(newData);
    };
    const defaultColumns = [
        /*{
            title: 'No.',
            dataIndex: 'index',
            render: (text, record, index) => dataSource.length >= 1 ? (<div>{index + 1}</div>) : null,
        },*/
        {
            title: 'TheBitcoinStandardDAO NFT',
            dataIndex: 'index',
            render: (text, record, index) => dataSource.length >= 1 ? ( <Image
                width={20}
                className="rule_img"
                src={record.image}
            />) : null,
        },
        {
            title: 'Token ID',
            dataIndex: 'nftId',
            width: '10%',
        },
        {
            title: 'Bitcoin Address',
            dataIndex: 'btcAddress',
            width: '70%',
            className: "btnAddressRow",
            editable: true,
        },
        /*{
            title: 'operation',
            dataIndex: 'operation',
            render: (_, record, index) =>
                dataSource.length >= 1 ? (
                    <div onClick={() => handleDelete(record.key)}>
                        <a>Delete</a>
                    </div>
                ) : null,
        },*/
    ];
    const handleAdd = () => {
        const newData = {
            key: count,
            nftId: '',
            btcAddress: ``,
        };
        setDataSource([...dataSource, newData]);
        setCount(count + 1);
    };
    const handleSave = (row) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setDataSource(newData);
    };
    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable && record.notsubmit,
                dataIndex: col.dataIndex,
                title: col.title,
                className: col.className,
                handleSave,
            }),
        };
    });

    const { account } = useWeb3React()
    const [isApprovedAll, setIsApprovedAll] = useState(false);
    const standNft = useDaoStandNft();
    const nftToBtcUtils = useNftToBtcUtils();
    const fetchLastTime = useCallback(async () => {
        try {
            if(standNft && account){
               const isApproved = await standNft.isApprovedForAll(account, NFT_TO_BTC_UTILS);
               console.info("isApproved==>" + isApproved);
               setIsApprovedAll(isApproved.toString() === 'true');
            }
        } catch (e) {
            console.log(e);
        }
    }, [account, standNft, NFT_TO_BTC_UTILS, setIsApprovedAll]);
    useInterval(fetchLastTime, 2000);

    const {payBtcInscribeFee} = useBtcInscribe();

    const getStandNftList = useCallback(async () => {
        // if(!account)return;
        try{
            const API_SERVER = process.env.REACT_APP_API_SERVER;
            // const list2 = await fetch(API_SERVER + '/common/btcInscribe/myStd?address=0xab282e05c3461fb186bc050a0526013cd2939159')
            const list2 = await fetch(API_SERVER + '/common/btcInscribe/myStd?address=' + account.toString().toLowerCase())
            const res2List = await list2.json()
            if (res2List['code'] === 200) {
                const listRow = res2List['data'];
                const dataList = [];
                for (let i = 0; i < listRow.length; i++) {
                    const item = listRow[i];
                    const newData = {
                        key: item["id"],
                        nftId: item["id"],
                        image: item["mate"],
                        btcAddress: item["btcAddress"],
                        notsubmit: !item["btcAddress"],
                    };
                    dataList.push(newData);
                }
                setCount(dataList.length);
                setDataSource(dataList);
            }
        }catch (e) {
            console.error(e);
        }
    }, [account,  setDataSource, setCount]);

    useEffect( () => {
        getStandNftList();
    }, [account,  setDataSource, setCount, getStandNftList])

    // dispatch(addPopup({ content: { txn: {"hash":"0x56b25f1ba4639e1f162809199f00cbddafb2592457e7e69b6fb1e354091efefa"} }, key: `hash` }))
    // dispatch(addPopup({ content: { failedSwitchNetwork: 1 }, key: `failed-network-switch` }))

    const [errBtcList, setErrBtcList] = useState(['aaa', 'bbb']);
    const [btcErrorType, setBtcErrorType] = useState(0);
    const [showBtcDialog, setShowBtcDialog] = useState(false);
    const [showSendFeeDialog, setShowSendFeeDialog] = useState(false);
    const [sendFeeHash, setSendFeeHash] = useState(null);


    function showFeeHash(hash) {
        setSendFeeHash(hash)
        setShowSendFeeDialog(true)
        console.info(hash);
    }


    const doPayInscribeFeee = useCallback(async () => {
        if(!account){
            return message.error("Connect your wallet first please.");
        }
        if(dataSource && dataSource.length > 0){
            let idList = [];
            let btcList = [];
            let btcExistList = [];
            let btcAddrErrList = [];
            for (let i = 0; i < dataSource.length; i++) {
                const item = dataSource[i];
                if(item.notsubmit){
                    if(!item.btcAddress || item.btcAddress.length < 1){
                        // return message.error("第" +(i+1)+ "行，的BTC接收地址不能为空");
                    }else{
                        if(btcList.includes(item.btcAddress)){
                            btcExistList.push(item.btcAddress);
                        }

                        if(!item.btcAddress.startsWith("bc1p") || item.btcAddress.length < 60){
                            btcAddrErrList.push(item.btcAddress);
                        }
                        idList.push(item.nftId);
                        btcList.push(item.btcAddress);
                    }
                }
            }
            if(btcList.length < 1){
                return message.error("You need to submit your Bitcoin address.");
            }

            const existBtcList = await nftToBtcUtils.checkBtcBatch(btcList);
            if(existBtcList.length > 0 || btcExistList.length > 0){
                setErrBtcList(existBtcList.concat(btcExistList));
                setBtcErrorType(0);
                setShowBtcDialog(true);
                return;
            }
            if(btcAddrErrList.length > 0){
                setErrBtcList(btcAddrErrList);
                setBtcErrorType(1);
                setShowBtcDialog(true);
                return;
            }

            if(!isApprovedAll){
                await standNft.setApprovalForAll(NFT_TO_BTC_UTILS, true);
                return;
            }
            await payBtcInscribeFee(DAO_NFT, idList, btcList, (res)=>{
                console.info("sendFeeHash===>" + res);
                if(res.state){
                    showFeeHash(res.data);
                }
            });
        }
    },[payBtcInscribeFee, dataSource, account, isApprovedAll, standNft, DAO_NFT, NFT_TO_BTC_UTILS, setErrBtcList,setShowBtcDialog, showFeeHash,setBtcErrorType ]);
    const test = useCallback(async () => {
        // await nftToBtcUtils.setFee('2000000000000000');
        // await nftToBtcUtils.setAmount1('2500000000000000');
        // await nftToBtcUtils.out('0x5664c98c7fc59071187842fed306129c3ee14014');
    },[nftToBtcUtils]);


    return (
        <div className="BtcInscribePage">


            {/*<TransactionPopup hash={'0x56b25f1ba4639e1f162809199f00cbddafb2592457e7e69b6fb1e354091efefa'}/>*/}
            <UrlBtnTitle/>

            {/*<div onClick={test}>55555</div>*/}

            {/*<div
                onClick={handleAdd}
                className="common_copy_btn"
                style={{marginBottom: 16}}
            >
                Add Row
            </div>*/}
            <Table
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={dataSource}
                columns={columns}
                pagination={{defaultPageSize: 50}}
            />

            <div className="MagicEden">
                <a href="https://magiceden.io/ordinals/marketplace/standard">Ordinals on MagicEden</a>
            </div>

            <div className="btc-buttom-btn">

                {/*<PopupItem key={'1'} content={item.content} popKey={item.key} removeAfterMs={item.removeAfterMs} />*/}

                <div
                    onClick={() => doPayInscribeFeee()}
                    className="common_copy_btn btc-insc-btn"
                    style={{marginBottom: 16, width: '15.5rem'}}
                >
                    {isApprovedAll ? 'Confirm to inscribe the ordinals' : 'Approve the STANDARD NFTs'}
                </div>
            </div>

            <BtcAddrErrModal open={showBtcDialog} close={() => setShowBtcDialog(false)} btcList={errBtcList}
                             btcErrorType={btcErrorType}/>
            <BtcHandingModal open={showSendFeeDialog} close={() => setShowSendFeeDialog(false)} hash={sendFeeHash}
                             back={getStandNftList}/>
        </div>
    );
};
export default BtcInscribe;
