import React, {useCallback, useEffect, useState} from "react";
import useInterval from "../../../hooks/useInterval";
import {NavLink} from "react-router-dom";
import {message, Modal, Pagination, Row} from 'antd'
import web3 from "web3";
import { logo } from "../../../assets";
import {formatAddress} from "../../../utils/time";
import {useSendTransaction} from "../../../nft/hooks/useSendTransaction";
import {useWeb3React} from "@web3-react/core";
import {getUrlParamParam} from "../../../utils";
import "./Rubbings.css"

const Rubbings = () => {
    const API_SERVER = process.env.REACT_APP_API_SERVER;
    const { sendTransaction,  } = useSendTransaction()
    const { account } = useWeb3React()

    const [authorId, setAuthorId] = useState('eth');
    useEffect(() => {
        const pathValue = getUrlParamParam('Rubbings');
        if(pathValue.length > 0){
            setAuthorId(pathValue);
            console.info('uthorId=' + pathValue);
        }
    }, [setAuthorId])
    const [ethInscGroupList, setEthInscGroupList] = useState([]);
    const [selectEthGroup, setSelectEthGroup] = useState("");

    const [facetInscList, setFacetInscList] = useState([]);
    const [inscGroupInfo, setInscGroupInfo] = useState(
        {total:0, name:"", nick:"", desc:"", image:"", totalVolume:0, floorPrice:0, uniqueOwner:0, mintableTotal:0});

    const [current, setCurrent] = useState(1);
    const [total, setTotal] = useState(0)
    const [pageSize, setPageSize] = useState(8)
    const onChange = (page: any) => {
        setCurrent(page);
    };
    const switchEthGroup = async (groupName: any) => {
        setSelectEthGroup(groupName);
        setFacetInscList([]);
        await getInscList();
    };
    const onShowSizeChange = (current: any, pageSize: any) => {
        setPageSize(pageSize);
        setCurrent(current);
    };

    const getInscList = useCallback(async () => {
        try{
            const list = await fetch(API_SERVER + '/ethscription/allPoint/facetInscList?authorId=-1' +"&pageSize="+pageSize+"&pageNum=" + current.toString() +"&type=" + selectEthGroup)
            const resList = await list.json()
            if (resList['code'] === 200) {
                const listRow = resList['data'];
                setFacetInscList(listRow['inscList']);
                setInscGroupInfo(listRow);
                setTotal(listRow['total']);
            }

            if(authorId === "eth"){
                const response = await fetch(API_SERVER + '/ethscription/allPoint/ethscriptionType');
                const responseObject = await response.json()
                if (responseObject['code'] === 200) {
                    setEthInscGroupList(responseObject['data']);
                }
            }
        }catch (e) {
            console.error(e);
        }
    }, [setFacetInscList, authorId, current, pageSize, setTotal, selectEthGroup, setInscGroupInfo, setEthInscGroupList]);

    const fetchLastTime = useCallback(async () => {
        try {
            getInscList();
        } catch (e) {
            console.log(e);
        }
    }, [ getInscList,]);
    useInterval(fetchLastTime);

    const doMintInsc = useCallback(async () => {
        const list = await fetch(API_SERVER + '/ethscription/allPoint/getMintAbleFacet?authorId=' + authorId)
        const resList = await list.json()
        if (resList['code'] === 200) {
            const inscData = resList['data'];
            console.info(inscData);
            const data = "data:,{\"m\":\"point\",\"p\":\"inscription\",\"op\":\"mint\",\"type\":\"" +inscData['type']+ "\",\"ipfs\":\"" +inscData['ipfs']+ "\"}";
            console.info('data==>' + data);
            const datas = web3.utils.utf8ToHex(data);
            console.info('datas==>' + datas);
            // @ts-ignore
            sendTransaction({to: address, data: datas})
            message.success('Mint Success');
        }else{
            message.error(resList['msg']);
        }
    },[account, authorId]);

    return (
        <>
            <Row className='BigPage'>
                <div className="RubbingHead flex flex-col w-full items-center py-8 gap-8">
                    <section className="RubbingArea flex flex-col w-full">
                        <div className="flex flex-col bg-white rounded-2xl shadow-sm overflow-hidden">
                            {/*<img
                            src="https://iili.io/HQyYOtp.png" alt="mfpurrs banner" className="aspect-[4/1]"
                            id="image-rendering: pixelated; width: 100%; object-fit: cover;"/>*/}
                            <div className="flex flex-1 flex-col items-start">
                                <div className="flex flex-1 flex-row w-full gap-6 sm:gap-8">
                                    <div className="flex-1 flex-shrink-0">
                                        <img className="common_user-avater"
                                           src={inscGroupInfo.image ? inscGroupInfo.image : logo}
                                           width="100" height="100"
                                           id="image-rendering: pixelated;"/>
                                    </div>
                                    <div className="flex gap-4 items-start"></div>
                                </div>
                                <div className="flex flex-1 flex-col gap-2"><h2
                                    className="font-black tracking-tight mb-0 text-2xl md:text-4xl">EthereumMetaverse Lands
                                </h2>
                                    <div className="max-w-3xl">{inscGroupInfo.desc}</div>
                                    {
                                        // eslint-disable-next-line react/no-unescaped-entities
                                        authorId === 'eth' ? <div/> : <div className="max-w-3xl">@{inscGroupInfo.nick}'s Tweets Collection</div>
                                    }
                                </div>

                                <div className="flex flex-1 w-full flex-row gap-6 flex-wrap sm:gap-8">
                                    <div className="flex max-w-xs flex-col">
                                        <div className="text-2xl font-black tracking-tight text-gray-900">{inscGroupInfo.totalVolume} ETH</div>
                                        <div className="text-base text-gray-600">total volume</div>
                                    </div>
                                    <div className="flex max-w-xs flex-col">
                                        <div className="text-2xl font-black tracking-tight text-gray-900">{inscGroupInfo.floorPrice} ETH</div>
                                        <div className="text-base text-gray-600">floor price</div>
                                    </div>
                                    <div className="flex max-w-xs flex-col">
                                        <div className="text-2xl font-black tracking-tight text-gray-900">{inscGroupInfo.uniqueOwner}</div>
                                        <div className="text-base text-gray-600">unique owners</div>
                                    </div>

                                    {
                                        inscGroupInfo.mintableTotal === 0 ? <div/> :
                                       <div className="mint-btns-div">
                                           <div className="flex max-w-xs flex-col">
                                               <div className="text-2xl font-black tracking-tight text-gray-900">{inscGroupInfo.mintableTotal}</div>
                                               <div className="text-base text-gray-600">Mintable</div>
                                           </div>
                                           <div className="flex max-w-xs flex-col">
                                               <button onClick={() => doMintInsc()} className="login_button px-20" type="submit" data-testid="home-search-button">Mint</button>
                                           </div>
                                       </div>
                                    }
                                </div>

                            </div>
                        </div>
                    </section>
                    <section className="RubbingArea flex flex-col w-full">
                        <div className="w-full text-md font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                            <ul className="flex flex-wrap -mb-px">
                                {ethInscGroupList.map((item, index) => {
                                    return (
                                        // eslint-disable-next-line react/jsx-key
                                        <li className="mr-2 ">
                                            <a>
                                            <div onClick={()=> {switchEthGroup(item["name"]);}} className={selectEthGroup === item["name"] ? "hover-item inline-block p-4 text-black border-b-2 border-black rounded-t-lg active" : "hover-item inline-block p-4 text-black border-black rounded-t-lg"}>
                                                {item["desc"]} ({item["total"]})
                                            </div>
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </section>
                    <section className="flex flex-col w-full gap-4">
                        <div className="infinite-scroll-component__outerdiv">
                            <div className="infinite-scroll-component !overflow-visible" id="height: auto; overflow: auto;">
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-6">
                                    {facetInscList.map((item, i) => {
                                        return (
                                        <NavLink className="user-row" key={i}  to={'/RubbingsInfo/'+ (authorId === 'eth' ? "e" : "" ) + item['id']}>
                                            <div className="flex bg-white flex-col items-start justify-start gap-4 tracking-tight w-full h-full shadow-sm rounded-xl overflow-hidden">
                                                <div className="flex flex-col gap-1 w-full h-full text-lg justify-between relative">
                                                    <div className="w-full overflow-hidden">
                                                        <div className="aspect-square hover:scale-[1.1] transition duration-300">
                                                            <div className="pointer-events-none">
                                                                <img
                                                                alt=""
                                                                className="w-full group-img-border"
                                                                src={item['image']}
                                                                id="image-rendering: pixelated;"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col gap-1 p-4">
                                                        <h6 className="font-black tracking-tight mb-0 text-sm md:text-md">{item['id']}.eth</h6>
                                                        <h4 className="font-black tracking-tight mb-0 text-lg md:text-xl">{item['ctime']}</h4>
                                                        <div className="text-xs text-gray-500">{formatAddress(item['ipfs'])}</div>
                                                        {/*<div className="text-xs text-gray-500">{item['status'] === 'NO_MINT' ? 'Not Mint' : 'Minted'}</div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </NavLink>
                                    );
                                    })}
                                </div>
                                <Pagination
                                    className="pageView"
                                    total={total}
                                    showSizeChanger={false}
                                    defaultPageSize={8}
                                    defaultCurrent={1}
                                    current={current}
                                    onChange={onChange}
                                    onShowSizeChange={onShowSizeChange}
                                />
                            </div>
                        </div>
                    </section>
                </div>
            </Row>

        </>
    )
}

export default Rubbings