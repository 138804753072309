import {Input, message, Modal} from 'antd'
import React, {useCallback, useEffect, useState} from 'react'
import {useWeb3React} from '@web3-react/core'
import '../TweetAll.css'
import './TweetTotal.css'
import {IS_TEST, MAIN_TAG, TW_LOGIN_URL} from "../../../constants/addresses";
import useInterval from '../../../hooks/useInterval'
import {NavLink} from "react-router-dom";
import TweetLogin from "../TweetLogin";
import {getUrlParamParam} from "../../../utils";

const { TextArea } = Input;
const EnsRegist = (props) => {
  const API_SERVER = process.env.REACT_APP_API_SERVER;
  const { account, provider } = useWeb3React()
  const [noregUserList, setNoregUserList] = useState([]);
  const [plantUserList, setPlantUserList] = useState([]);
  const [regedUserList, setRegedUserList] = useState([]);
  const [domainName, setDomainName] = useState("");
  const domainNameChange = ({ target: { value } }) => {
    let checkedName = value.toString().toLowerCase().replace(" ", "");
    setDomainName(checkedName);
  };
  const [twTags, setTwTags] = useState('');
  // const [loginUserInfo, setLoginUserInfo] = useState({login: true, isReged: false, activeScore: '0', eth:'xxx.eth', avatar:'https://pbs.twimg.com/profile_images/1644851643228516353/-adFBedl_normal.jpg', nickName:'nickname', userName:'username', address:'xxxxxxxxxxxxxxx', });

  const [loginUserInfo, setLoginUserInfo] = useState(
   {login: false, isReged: true, activeScore: '0', eth:'', avatar:'', nickName:'', userName:'', address:'', }
  );

  useEffect(() => {
    const pathValue = getUrlParamParam('TweetTotal');
    console.info("pathValue==>" + pathValue);
    if(pathValue.length > 10){
      localStorage.setItem("DOG-TW-LOGIN-KEY", pathValue);
      console.info('save twToken=' + pathValue);
    }
  }, [])

  const getLoginUserInfo = useCallback(async () => {
    const twToken = localStorage.getItem("DOG-TW-LOGIN-KEY");
    console.info('twToken=' + twToken);
    if(!twToken)return;
    if(IS_TEST)return;
    try{
      const list = await fetch(API_SERVER + '/ethscription/twitter/getUserInfo?token=' + twToken.toString())
      const resList = await list.json()
      if (resList['code'] === 200) {
        const info = resList['data'];
        setTwTags("#"+MAIN_TAG+" #" + info.userName);
        setLoginUserInfo({login: true, isReged: info.reged, activeScore:info.activeScore, eth: info.eth, avatar:info.avatar, nickName:info.nickName, userName:info.userName, address:info.address});
      }else{
        setLoginUserInfo({login: false, isReged: false, activeScore: '0',eth:'', avatar:'', nickName:'', userName:'', address:'', });
      }
    }catch (e) {
      console.error(e);
      setLoginUserInfo({login: false, activeScore: '0',eth:'', avatar:'', nickName:'', userName:'', address:'', });
    }
  }, [setLoginUserInfo,]);

  const getEnsList = useCallback(async () => {
    try{
      const list = await fetch(API_SERVER + '/ethscription/twitter/twTopUser')
      const resList = await list.json()
      if (resList['code'] === 200) {
        const listRow = resList['data'];
        setNoregUserList(listRow['noregUserList']);
        setPlantUserList(listRow['plantUserList']);
        setRegedUserList(listRow['regedUserList']);
      }
    }catch (e) {
      console.error(e);
    }
  }, [setNoregUserList, setRegedUserList, setPlantUserList]);
  const fetchLastTime = useCallback(async () => {
    try {
      getEnsList();
      getLoginUserInfo();
    } catch (e) {
      console.log(e);
    }
  }, [account, getEnsList, domainName, setNoregUserList, setRegedUserList]);
  useInterval(fetchLastTime, 3000);


  const searchHandle = useCallback(async () => {
    if(domainName.length <= 0){message.error('The name is incorrect'); return;}
    try {
      const list = await fetch(API_SERVER + '/ethscription/twitter/twUserSearch?keyword=' + domainName.toString())
      const response = await list.json()
      if (response['code'] === 200) {
        window.location.href = '/#/x/' + domainName;
      }else{
        message.error(response['msg']);
      }
    }catch (e) {
      console.error(e);
    }
  }, [domainName,]);

  const loginTwitter = useCallback(async () => {
    window.location.href = TW_LOGIN_URL;
  }, [account, TW_LOGIN_URL]);

  const bindAddress = useCallback(async () => {
    try{
      const twToken = localStorage.getItem("DOG-TW-LOGIN-KEY");
      console.info('twToken=' + twToken);
      if(!twToken){
        return message.success('login twitter first');
      }
      const params = ("address="+ account.toLowerCase() + "\nmessage=Hello");
      const signature = (await provider.send('personal_sign', [params, account.toLowerCase()]));
      console.info('message=  ' + params);
      console.info('signature=  ' + signature);
      const list = await fetch(API_SERVER + '/ethscription/twitter/bindAddress?address=' + account.toString() + "&token=" + twToken +"&signature=" + signature)
      const response = await list.json()
      if (response['code'] === 200) {
        message.success('bind sucess');
      }else{
        message.error(response['msg']);
      }
    }catch (e) {
      console.error(e);
    }
  }, [account,]);


////////////////////
  const [isFirstSend, setIsFirstSend] = useState(false);
  const [goonVisible, setGoonVisible] = useState(false);
  const showRule = (e) => {
    setGoonVisible(true);
    setIsFirstSend(e);
  };
  const cancelRule = () => {
    setGoonVisible(false);
  };

  const [messageContent, setMessageContent] = useState("");
  const messageContentChange = ({ target: { value } }) => {
    setMessageContent(value);
  };


  const sendTwitterMsg = useCallback(async () => {
    try{
      const twToken = localStorage.getItem("DOG-TW-LOGIN-KEY");
      console.info('twToken=' + twToken);
      if(!twToken){
        return message.success('login twitter first');
      }
      const list = await fetch(API_SERVER + '/ethscription/twitter/sendMsg?message=' + messageContent.toString() + "&scoreName=SELF" + "&token=" + twToken + '&type=2')
      const resList = await list.json()
      if (resList['code'] === 200) {
        message.success('send sucess');
        cancelRule();
      }else{
        message.error(resList['msg']);
      }
    }catch (e) {
      console.error(e);
    }
  }, [ messageContent,]);


  return (
    <div className="TweetTotalArea">

      <TweetLogin/>

      <div className="fast-border">

        <div className="fast-item">

          <div className="user-row-title">
            <div className="title">Influencers</div>
            <div>Votes</div>
          </div>

          {noregUserList.map((item, i) => {
            return (
                <NavLink className="user-row" key={i}  to={'/x/'+item.userName}>
                  <div className="row-left">
                    <img className="user-avater rounded-full" src={item.avatar} alt="" />
                    <div className="left-info">
                      <div className="title">{item.nickName}</div>
                      <div className="title">@{item.userName}</div>
                      <div className="title">Holders {item.userTotal}</div>
                    </div>
                  </div>
                  <div className="row-right">
                    {item.mintTotal}
                  </div>
                </NavLink>
            );
          })}
        </div>
        <div className="fast-space"></div>
        <div className="fast-item">
          <div className="user-row-title">
            <div className="title">NewCryptoSpace&apos;s Followers</div>
            <div>Votes/Points</div>
          </div>
          {plantUserList.map((item, i) => {
            return (
                <NavLink className="user-row" key={i}  to={'/x/'+item.userName}>
                  <div className="row-left">
                    <img className="user-avater rounded-full" src={item.avatar} alt="" />
                    <div className="left-info">
                      <div className="title">{item.nickName}</div>
                      <div className="title">@{item.userName}</div>
                      <div className="title">Holders {item.userTotal}</div>
                    </div>
                  </div>
                  <div className="row-right">
                    {item.mintTotal}
                  </div>
                </NavLink>
            );
          })}
        </div>
        <div className="fast-space"></div>
        <div className="fast-item">
          <div className="user-row-title">
            <div className="title">Creater</div>
            <div>Points</div>
          </div>
          {regedUserList.map((item, i) => {
            return (
                <NavLink className="user-row" key={i}  to={'/x/'+item.userName}>
                  <div className="row-left">
                    <img className="user-avater rounded-full" src={item.avatar} alt="" />
                    <div className="left-info">
                      <div className="title">{item.nickName}</div>
                      <div className="title">@{item.userName}</div>
                      <div className="title">Holders {item.userTotal}</div>
                    </div>
                  </div>
                  <div className="row-right">
                    {item.mintTotal}
                  </div>
                </NavLink>
            );
          })}
        </div>
      </div>


      <Modal
          visible={goonVisible}
          onCancel={cancelRule}
          closable={false}
          centered={true}
          footer={null}
          className="rule_modal_box"
      >
        <div className="title tit">
          Send the post on X
        </div>

        <div className="send_tw_dialog">
          <div className="search-row">
            Contents:
            <TextArea
                rows={40}
                maxLength={300}
                placeholder="Input Message Contents"
                autoCapitalize="off"
                className="search-form search-form-area"
                value={messageContent}
                onChange={messageContentChange}
            />
          </div>
          <div className="search-row">
            Add Tags:
            <form className="search-form" action="#">
              <input
                  placeholder="Tags"
                  autoCapitalize="off"
                  className="modal_input"
                  disabled="disabled"
                  value={twTags}
              />
            </form>
          </div>
          {isFirstSend ?
              <div/> :
              <div className="search-row">
                My username points page:
                <form className="search-form" action="#">
                  <input
                      placeholder="Tags"
                      autoCapitalize="off"
                      className="modal_input"
                      disabled="disabled"
                      value={API_SERVER + '/#/x/' + loginUserInfo.userName}
                  />
                </form>
              </div>
          }

          <div className="search-text">
            After creating skyrocketing influence engine, you can post a post with 2 tags attached on X to earn the username points without having to post on this website again.
          </div>

        </div>
        <div  className="send_twitter_btn" onClick={sendTwitterMsg}>Send now!</div>
      </Modal>

    </div>
  )
}
export default EnsRegist
