import {useEffect, useRef} from "react";
import { isAddress } from 'ethers/lib/utils'

const useInterval = (callback:any) =>{
    const delay = 2000;
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        callback();
        savedCallback.current = callback;
    }, [callback]);

    // @ts-ignore
    useEffect(() => {
        function tick() {
            // @ts-ignore
            savedCallback.current();
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay])
}

export default useInterval
