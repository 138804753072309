import React from 'react'

import './footer.css'
import styled from "styled-components/macro";
import {NavLink} from "react-router-dom";
import {darken} from "polished";
import {message} from "antd";

export default function Footer() {
  const activeClassName = 'active'
  const StyledNavLink = styled(NavLink)`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.deprecated_text2};
  font-size: 1rem;
  font-weight: 500;
  padding: 8px 12px;
  word-break: break-word;
  overflow: hidden;
  white-space: nowrap;
  &.${activeClassName} {
    border-radius: 14px;
    font-weight: 600;
    justify-content: center;
    color: ${({ theme }) => theme.deprecated_text1};
    background-color: ${({ theme }) => theme.deprecated_bg1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.deprecated_text1)};
  }
`
  const wailt = () => {
    message.success('coming soon')
  }

  return (
      <div className="footer_area">
        <div className="g_footer">
            <div className="g_footer_link">

             {/*<NavLink className={"item_link twitter link_h"} to={'/airdrop/vote'}>
                Cpoints
              </NavLink>

              <NavLink className={"item_link twitter link_b"} to={'/airdrop/vote'}>
                Cpoints
              </NavLink>*/}

              {/*
              <div onClick={wailt} className="item_link medium link_h" rel="noreferrer">
                API
              </div>
              <div onClick={wailt} className="item_link medium link_b" rel="noreferrer">
                API
              </div>*/}

              {/*<NavLink className={"item_link twitter link_h"} to={'/Rubbings/eth'}>
              Rubbings
              </NavLink>

              <NavLink className={"item_link twitter link_b"} to={'/Rubbings/eth'}>
              Rubbings
              </NavLink>

              <NavLink className={"item_link twitter link_h"} to={'/Standard'}>
                STANDARD
              </NavLink>

              <NavLink className={"item_link twitter link_b"} to={'/Standard'}>
                STANDARD
              </NavLink>*/}

              <a href="https://twitter.com/newcryptospace" target="_blank" className="item_link twitter link_h" rel="noreferrer">
                X(Twitter)
              </a>
              <a href="https://twitter.com/newcryptospace" target="_blank" className="item_link twitter link_b" rel="noreferrer">
                X(Twitter)
              </a>

              <a href="https://medium.com/newcryptospace" target="_blank" className="item_link medium link_h" rel="noreferrer">
                Medium
              </a>
              <a href="https://medium.com/newcryptospace" target="_blank" className="item_link medium link_b" rel="noreferrer">
                Medium
              </a>

            </div>
          </div>

        <div className="foot-copyright">
          NewCryptoSpace © <p>2024</p>
        </div>
    </div>
  )
}
