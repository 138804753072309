import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useShareManagerContract } from './useContract'
import Web3 from 'web3';
import { SHARE_MANAGER_ADDRESSES } from '../constants/addresses'
import { message } from 'antd'
import { swapErrorToUserReadableMessage } from '../utils/swapErrorToUserReadableMessage'


const useDogHarvest = () => {
  const {chainId, provider,account} = useWeb3React();

  const handleStake = useCallback(async (feeAmount: string) => {
      // @ts-ignore
      const web3 = new Web3(provider)
      const address = chainId ? SHARE_MANAGER_ADDRESSES[chainId] : undefined

      const shareManager = new web3.eth.Contract([
          {
            "inputs": [],
            "stateMutability": "nonpayable",
            "type": "constructor"
          },
          {
            "anonymous": false,
            "inputs": [
              {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
              },
              {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
              }
            ],
            "name": "OwnershipTransferred",
            "type": "event"
          },
          {
            "anonymous": false,
            "inputs": [
              {
                "indexed": false,
                "internalType": "address",
                "name": "user",
                "type": "address"
              }
            ],
            "name": "onFee",
            "type": "event"
          },
          {
            "anonymous": false,
            "inputs": [
              {
                "indexed": false,
                "internalType": "address",
                "name": "user",
                "type": "address"
              },
              {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
              }
            ],
            "name": "onSend",
            "type": "event"
          },
          {
            "inputs": [],
            "name": "amount",
            "outputs": [
              {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
              }
            ],
            "stateMutability": "view",
            "type": "function"
          },
          {
            "inputs": [
              {
                "internalType": "address",
                "name": "",
                "type": "address"
              }
            ],
            "name": "feeFlag",
            "outputs": [
              {
                "internalType": "bool",
                "name": "",
                "type": "bool"
              }
            ],
            "stateMutability": "view",
            "type": "function"
          },
          {
            "inputs": [
              {
                "internalType": "address",
                "name": "coin",
                "type": "address"
              }
            ],
            "name": "getBalance",
            "outputs": [
              {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
              }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
          },
          {
            "inputs": [
              {
                "internalType": "address",
                "name": "coin",
                "type": "address"
              }
            ],
            "name": "getCoinBalance",
            "outputs": [
              {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
              }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
          },
          {
            "inputs": [
              {
                "internalType": "address",
                "name": "user",
                "type": "address"
              }
            ],
            "name": "isFeed",
            "outputs": [
              {
                "internalType": "bool",
                "name": "",
                "type": "bool"
              }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
          },
          {
            "inputs": [
              {
                "internalType": "address",
                "name": "to",
                "type": "address"
              }
            ],
            "name": "out",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
          },
          {
            "inputs": [
              {
                "internalType": "address",
                "name": "coin",
                "type": "address"
              },
              {
                "internalType": "address",
                "name": "to",
                "type": "address"
              }
            ],
            "name": "outCoin",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
          },
          {
            "inputs": [],
            "name": "owner",
            "outputs": [
              {
                "internalType": "address",
                "name": "",
                "type": "address"
              }
            ],
            "stateMutability": "view",
            "type": "function"
          },
          {
            "inputs": [],
            "name": "renounceOwnership",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
          },
          {
            "inputs": [
              {
                "internalType": "address",
                "name": "user",
                "type": "address"
              },
              {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
              }
            ],
            "name": "sendCoin",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
          },
          {
            "inputs": [],
            "name": "sendFee",
            "outputs": [],
            "stateMutability": "payable",
            "type": "function"
          },
          {
            "inputs": [
              {
                "internalType": "uint256",
                "name": "_amount",
                "type": "uint256"
              }
            ],
            "name": "setAmount",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
          },
          {
            "inputs": [
              {
                "internalType": "address",
                "name": "value",
                "type": "address"
              }
            ],
            "name": "setShareToken",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
          },
          {
            "inputs": [],
            "name": "shareToken",
            "outputs": [
              {
                "internalType": "contract IERC20",
                "name": "",
                "type": "address"
              }
            ],
            "stateMutability": "view",
            "type": "function"
          },
          {
            "inputs": [
              {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
              }
            ],
            "name": "transferOwnership",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
          }
        ]
        , address)

      const data = shareManager.methods.sendFee().encodeABI();

      // method.send({
      //         from: account,
      //         value: tokenAmount
      //     })
      //     .on('transactionHash', (hash: string) => {
      //         console.info('HASH===>' + hash);
      // })

      // @ts-ignore
      provider
        .getSigner()
        .sendTransaction({
          from: account,
          to: address,
          data: data,
          value: feeAmount
          // let the wallet try if we can't estimate the gas
          // ...('gasEstimate' in bestCallOption ? { gasLimit: calculateGasMargin(bestCallOption.gasEstimate) } : {}),
          // ...(value && !isZero(value) ? { value } : {}),
        })
        .then((response) => {
          return response
        })
        .catch((error) => {
          // if the user rejected the tx, pass this along
          if (error?.code === 4001) {
            // throw new Error(`Transaction rejected.`)
            message.error("Transaction rejected.");
          } else {
            // otherwise, the error was unexpected and we need to convey that
            // console.error(`Swap failed`, error, address, calldata, value)
            message.error(`failed: ${swapErrorToUserReadableMessage(error)}`);
          }
        })
      },
      [account,],
  );
  return { onStake: handleStake };
};

export default useDogHarvest;



