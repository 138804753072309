import './header.css'

import { Trans } from '@lingui/macro'
import useScrollPosition from '@react-hook/window-scroll'
import { useWeb3React } from '@web3-react/core'
import { getChainInfoOrDefault } from 'constants/chainInfo'
import {Phase0Variant, usePhase0Flag} from 'featureFlags/flags/phase0'
import useTheme from 'hooks/useTheme'
import { darken } from 'polished'
import { NavLink, useLocation } from 'react-router-dom'
import { Text } from 'rebass'
import { useShowClaimPopup, useToggleSelfClaimModal } from 'state/application/hooks'
import { useUserHasAvailableClaim } from 'state/claim/hooks'
import { useNativeCurrencyBalances } from 'state/connection/hooks'
import { useUserHasSubmittedClaim } from 'state/transactions/hooks'
import { useDarkModeManager } from 'state/user/hooks'
import styled from 'styled-components/macro'

import logo from '../../assets/img/logo.png'
import x20_log from '../../assets/x20/x20_log.png'
import { ExternalLink, ThemedText, MEDIA_WIDTHS } from '../../theme'
import ClaimModal from '../claim/ClaimModal'
import { CardNoise } from '../earn/styled'
import Menu from '../Menu'
import Row from '../Row'
import { Dots } from '../swap/styleds'
import Web3Status from '../Web3Status'
import NetworkSelector from './NetworkSelector'
import {SupportedChainId} from "../../constants/chains";
import {SwitchLocaleLink} from "../SwitchLocaleLink";
import wallet from "../../assets/pg/wallet.png";
import X20Menu from 'components/Menu/X20Menu'
import {message} from "antd";
import {arb, eth, twitter, avater} from "../../assets";
const HeaderFrame = styled.div<{ showBackground: boolean }>`
  display: grid;
  grid-template-columns: 120px 120px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  padding-top: .2rem;
  padding-bottom: .2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  z-index: 21;
  position: relative;
  /* Background slide effect on scroll. */
  /*background-image: ${({ theme }) => `linear-gradient(to bottom, transparent 50%, ${theme.deprecated_bg0} 50% )}}`};*/
  background-position: ${({ showBackground }) => (showBackground ? '0 -100%' : '0 0')};
  background-size: 100% 200%;
  box-shadow: 0px 0px 0px 1px ${({ theme, showBackground }) => (showBackground ? theme.deprecated_bg2 : 'transparent;')};
  transition: background-position 0.1s, box-shadow 0.1s;
  background-blend-mode: hard-light;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    padding-left: 1rem;
    padding-right: 1rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
      padding-left: 1rem;
    padding-right: 1rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
      padding-left: 1rem;
    padding-right: 1rem;
  `};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: 0.5em;
  }

  /* addresses safaris lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: center;
  `};
`

const HeaderLinks = styled(Row)`
  justify-self: center;
  background-color: ${({ theme }) => theme.deprecated_bg0};
  width: max-content;
  padding: 2px;
  border-radius: 16px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  overflow: auto;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    justify-self: start;
    `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-self: center;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    z-index: 99;
    position: fixed;
    bottom: 0; right: 50%;
    transform: translate(50%,-50%);
    margin: 0 auto;
    background-color: ${({ theme }) => theme.deprecated_bg0};
    border: 1px solid ${({ theme }) => theme.deprecated_bg2};
    box-shadow: 0px 6px 10px rgb(0 0 0 / 2%);
  `};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 16px;
  white-space: nowrap;
  width: 100%;
  height: 40px;
`

const UNIAmount = styled(AccountElement)`
  color: black;
  padding: 4px 8px;
  height: 36px;
  font-weight: 500;
`

const UNIWrapper = styled.span`
  width: 85px;
  position: relative;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.9;
  }
`

const Langeuage = styled.span`
  width: 100px;
  text-align:center;
  color: white;
  padding: 4px 8px;
  height: 36px;
  display:flex;
  font-weight: 500;
  background-color: ${({ theme }) => theme.deprecated_bg3};
  background: radial-gradient(174.47% 188.91% at 1.84% 0%, #ff007a 0%, #2172e5 100%), #edeef2;
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
    color:white;
  `};
`

const LogTitleText = styled(Text)`
  color: black;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
    color:white;
  `};
`

const Title = styled.a`
  display: flex;
  pointer-events: auto;
  margin-right: 12px;
  color: #111;
  text-decoration: none;
  align-items: start;
`

const BateText = styled.div`
  color: gray;
  font-weight: bold;
  margin-left: .2rem;
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    //transform: rotate(-5deg);
  }
  display:flex;
  line-height: 2.25rem;
  text-align: center;
  position: relative;
`

// can't be customized under react-router-dom v6
// so we have to persist to the default one, i.e., .active
const activeClassName = 'active'

const StyledNavLink = styled(NavLink)`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.deprecated_text2};
  font-size: 1rem;
  font-weight: 500;
  padding: 8px 12px;
  word-break: break-word;
  overflow: hidden;
  white-space: nowrap;
  &.${activeClassName} {
    border-radius: 14px;
    font-weight: 600;
    justify-content: center;
    color: ${({ theme }) => theme.deprecated_text1};
    background-color: ${({ theme }) => theme.deprecated_bg1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.deprecated_text1)};
  }
`

const StyledExternalLink = styled(ExternalLink)`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.deprecated_text2};
  font-size: 1rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.deprecated_text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.deprecated_text1)};
    text-decoration: none;
  }
`

const Logo = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 8px;
`

const SelectorLogo = styled(Logo)`
  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    margin-right: 8px;
  }
`

const InnerJumpMenuItem = styled(NavLink)`
  flex: 1;
  padding: 0.5rem 0.5rem;
  flex-direction: row;
  align-items: center;
  margin-right: 1rem;
  display: flex;
  color:black;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
    
  `};
  //color: ${({ theme }) => theme.deprecated_text1};
  :hover {
    //color: ${({ theme }) => theme.deprecated_text2};
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
  > img {
    width: 1rem;
    margin-right: .3rem;
  }
`
const InnerJumpMenuDivItem = styled.div`
  width: 11rem;
  flex: 1;
  padding: 0.5rem 0.5rem;
  flex-direction: row;
  align-items: center;
  margin-right: 1rem;
  display: flex;
  color:black;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
  :hover {
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
  > img {
    width: 1rem;
    margin-right: .3rem;
  }
`

export default function Header() {
  const phase0Flag = usePhase0Flag()

  const { account, chainId } = useWeb3React()

  const userEthBalance = useNativeCurrencyBalances(account ? [account] : [])?.[account ?? '']
  const [darkMode] = useDarkModeManager()
  const { deprecated_white, deprecated_black } = useTheme()

  const toggleClaimModal = useToggleSelfClaimModal()

  const availableClaim: boolean = useUserHasAvailableClaim(account)

  const { claimTxn } = useUserHasSubmittedClaim(account ?? undefined)

  const showClaimPopup = useShowClaimPopup()

  const scrollY = useScrollPosition()

  const { pathname } = useLocation()
  const isProfile = pathname.startsWith('/profile')

  const {
    infoLink,
    nativeCurrency: { symbol: nativeCurrencySymbol },
  } = getChainInfoOrDefault(chainId)
  const PLANT_TYPE = process.env.REACT_APP_PLANT_TYPE;

  const onWaitAlert = () => {
    message.success('Coming soon')
  }

  return (
      <HeaderFrame showBackground={scrollY > 45}>
        <Title href="." className="header_title">
          <UniIcon>
              <img src={PLANT_TYPE === 'X20_PUB' ? x20_log : logo} alt="" style={{ width: '2.25rem', height: '2.25rem' }} />
              <LogTitleText className="title">{PLANT_TYPE === 'X20_PUB' ? "AllPointSpace" : "NewCryptoSpace"}</LogTitleText>
          </UniIcon>
          {
              PLANT_TYPE === 'X20_PUB' ? <BateText>Beta</BateText> : <></>
          }

        </Title>
        <HeaderControls>
          {/*<BalanceText>
          <NetworkSelector />
        </BalanceText>*/}

          {
              PLANT_TYPE === 'X20_PUB' ?
                  <>
                    <InnerJumpMenuItem id={`charts-nav-link`} to={'/launch'}>
                      Launch
                    </InnerJumpMenuItem>
                    <InnerJumpMenuItem id={`charts-nav-link`} to={'/profile'}>
                      <img src={avater}/>Profile
                    </InnerJumpMenuItem>
                    <InnerJumpMenuItem id={`charts-nav-link`} to={'/c'}>
                      <img src={eth}/>Warpcaster
                    </InnerJumpMenuItem>
                    <InnerJumpMenuDivItem onClick={onWaitAlert}>
                      <img src={arb}/>Medium
                    </InnerJumpMenuDivItem>

                  </> :
                  <></>
          }



{/*
          <StyledExternalLink id={`charts-nav-link`} href="https://newcryptospace.com/#/airdrop/vote">
            <SelectorLogo src={logo} />
            <LogTitleText>SocialfionEthereum</LogTitleText>
          </StyledExternalLink>
*/}

          {/*<NavLink id={`swap-nav-link`} to={'/my'}>
            <UNIAmount active={!!account} style={{ pointerEvents: 'auto' }}>
              <SelectorLogo src={mypns} />
              <LogTitleText>SocialfionEthereum</LogTitleText>
            </UNIAmount>
          </NavLink>*/}

          <NetworkSelector />
          <HeaderElement>
            <AccountElement active={!!account}>
              {/*{account && userEthBalance ? (
              <BalanceText style={{ flexShrink: 0, userSelect: 'none', color:'white' }} pl="0.75rem" pr=".4rem" fontWeight={500}>
                <Trans>
                  {userEthBalance?.toSignificant(3)} {nativeCurrencySymbol}
                </Trans>
              </BalanceText>
            ) : null}*/}
              <Web3Status />
            </AccountElement>



          </HeaderElement>

          {/*<HeaderElement>
            <SwitchLocaleLink/>
            <Menu/>
          </HeaderElement>*/}
          <HeaderElement>
            {/*<X20Menu/>*/}
          </HeaderElement>
        </HeaderControls>
      </HeaderFrame>
  )
}
