import { FeatureFlag, useUpdateFlag } from 'featureFlags'
import { Phase0Variant, usePhase0Flag } from 'featureFlags/flags/phase0'
import { Phase1Variant, usePhase1Flag } from 'featureFlags/flags/phase1'
import React, {ReactNode, useCallback, useContext, useEffect, useState} from 'react'
import {AlertCircle, CheckCircle, X} from 'react-feather'
import { useModalIsOpen, useToggleFeatureFlags } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import useTheme from 'hooks/useTheme'
import styled, {keyframes, ThemeContext} from 'styled-components/macro'
import Loader from "../Loader";
import {message} from "antd";
import {NFT_BTC_TEST, NFT_TO_BTC_UTILS} from "../../constants/addresses";
import {useWeb3React} from "@web3-react/core";
import {useNftToBtcUtils, useTestStandNft} from "../../hooks/useContract";
import useBtcInscribe from "../../hooks/useBtcInscribe";
import {ButtonPrimary} from "../Button";
import {ThemedText} from "../../theme";
import {useTransaction} from "../../state/transactions/hooks";

const StyledModal = styled.div`
  position: fixed;
  display: flex;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: fit-content;
  color: ${({ theme }) => theme.textPrimary};
  font-size: 18px;
  padding: 20px;
  background-color: ${({ theme }) => theme.backgroundSurface};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.backgroundOutline};
  z-index: 100;
  flex-direction: column;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.backgroundOutline};
`

function Modal({ open, children }: { open: boolean; children: ReactNode }) {
  return open ? <StyledModal>{children}</StyledModal> : null
}

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
`

const CloseButton = styled.button`
  cursor: pointer;
  background: ${({ theme }) => theme.none};
  border: none;
  color: ${({ theme }) => theme.textPrimary};
`

const Header = styled(Row)`
  font-weight: 600;
  font-size: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.backgroundOutline};
  margin-bottom: 8px;
`


function FeatureFlagOption({
  label,
}: {
  label: string
}) {
  return (
    <Row>
        <div>Please wait for the transaction confirmation, 1minite later you can fresh the page to get new data.</div>
    </Row>
  )
}

interface TwtProps {
    open: boolean;
    hash: string;
    close: () => void;
    back: () => void;
}


export default function BtcHandingModal({
                                            open,
                                            hash,
                                            close,
                                            back
                                        }: TwtProps) {
  // const open = useModalIsOpen(ApplicationModal.FEATURE_FLAGS)
  //   const toggle = useToggleFeatureFlags()
    const theme = useContext(ThemeContext)
    const tx = useTransaction(hash)

    // if (!tx) return null
    const [success, setSuccess] = useState(false);

    const removeAfterMs = 2000;
    useEffect(() => {
        if (removeAfterMs === null) return undefined
        if (hash === null) return undefined

        const timeout = setTimeout(() => {
            // const success = !tx ? false : Boolean(tx.receipt && tx.receipt.status === 1)
            back();
            // console.info("是否成功2==>" + success);
            close();
        }, removeAfterMs)

        return () => {
            clearTimeout(timeout)
        }
    }, [removeAfterMs, close, tx])


    // const doPayInscribeFeee = useCallback(async () => {
    //
    //
    //     if(!account){
    //         return message.error("Connect your wallet first please.");
    //     }
    //     if(dataSource && dataSource.length > 0){
    //         let idList: string[] = [];
    //         let btcList: string[] = [];
    //         for (let i = 0; i < dataSource.length; i++) {
    //             const item = dataSource[i];
    //             if(item['notsubmit']){
    //                 const btcAddress = item['btcAddress'] as string;
    //                 if(!btcAddress || btcAddress.length < 1){
    //                     // return message.error("第" +(i+1)+ "行，的BTC接收地址不能为空");
    //                 }else{
    //                     idList.push(item['nftId']);
    //                     btcList.push(item['btcAddress']);
    //                 }
    //             }
    //         }
    //         if(btcList.length < 1){
    //             return message.error("no right submit address");
    //         }
    //
    //         // @ts-ignore
    //         const existBtcList = await nftToBtcUtils.checkBtcBatch(btcList);
    //         if(existBtcList.length > 0){
    //             const adds =  existBtcList.join(",");
    //             return message.error(adds + " has minted ");
    //         }
    //
    //         if(!isApprovedAll){
    //             // @ts-ignore
    //             await standNft.setApprovalForAll(NFT_TO_BTC_UTILS, true);
    //             return;
    //         }
    //         await payBtcInscribeFee(NFT_BTC_TEST, idList, btcList);
    //     }
    // },[payBtcInscribeFee, dataSource, account, isApprovedAll, standNft, NFT_BTC_TEST, NFT_TO_BTC_UTILS]);


    return (
        <Modal open={open}>
            <Header>
                Wait for confirmation
                <CloseButton onClick={close}>
                    <X size={24}/>
                </CloseButton>
            </Header>
            {/*<AlertCircle color={theme.deprecated_red1} size={24} />*/}

            <FeatureFlagOption label={"1"}/>

            <div style={{paddingRight: 16}}>
                {success ? (
                    <CheckCircle color={theme.deprecated_green1} size={'20rem'}/>
                ) : (
                    <Loader size={'20rem'}/>
                )}
            </div>


            {/*<ButtonPrimary onClick={close}>
                <ThemedText.DeprecatedMediumHeader color="white">
                    Close And Refesh
                </ThemedText.DeprecatedMediumHeader>
            </ButtonPrimary>*/}

        </Modal>
    )
}
