import {useCallback} from 'react';
import {useWeb3React} from '@web3-react/core'
import {useTokenContract,} from "./useContract";
import {BigNumber} from "ethers/lib/ethers";


const useTokenApprove = (tokenAddress:string, x20Address:string,amount: string) => {
    const { provider, connector, account, } = useWeb3React()
    const token = useTokenContract(tokenAddress);
    const handleDomainCommit = useCallback(async () => {
           token?.approve(x20Address,   BigNumber.from(amount ? amount :'50000000000000000000000'))
        },
        [account, connector, provider, token, tokenAddress, x20Address,],
    );
    return { doApprove: handleDomainCommit };
};

export default useTokenApprove;



