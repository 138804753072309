import './ComHome.css'

import React, {useCallback, useEffect, useState} from 'react'
import {DOG_HOST_URL, ISDID_SYSTEM,} from '../../../constants/addresses'
import {useWeb3React} from '@web3-react/core'
import logo from "../../../assets/img/logo.png";
import {message} from 'antd'
import {useX20Token} from "../../../hooks/useContract";
import useInterval from "../../../hooks/useInterval";
import { darken } from 'polished'
import { NavLink } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import styled from 'styled-components/macro'
import Row from '../../../components/Row'
import {CopyToClipboard} from "react-copy-to-clipboard";
import {eth_sample} from "../../../assets";

const ComHome = () => {
    const { account } = useWeb3React()

    const [dataState, setDataState] = useState(true);
    const [address, setAddress] = useState('');
    const [isToken, setIsToken] = useState(false);
    const [tokenName, setTokenName] = useState('');
    const [tokenImage, setTokenImage] = useState('');
    const [ethscriber, setEthscriber] = useState('');

    const x20Token = useX20Token(address);

    useEffect(() => {
        let urlString = window.location.hash
        let tempParam = urlString.substring(urlString.indexOf('/') + 1)
        if (tempParam.indexOf('?') > 0) {
            tempParam = tempParam.substring(0, tempParam.indexOf('?'));
            tempParam = tempParam.toLowerCase();
        }
        if(tempParam.length !== 42){
            tempParam = '0xDc511564990966b1105CFdA5bFB4D6d024b27CD1';
        }
        setAddress(tempParam)
    }, [setAddress])

    console.info('address=' + address);
    const readInfos = useCallback(async () => {
        const reqAddress = account ? account : address;
        try{
            setIsToken(x20Token !== null);
            if(x20Token){
                setTokenName(await x20Token.symbol());
                setTokenImage(await x20Token.image());
                setDataState(true);
            }else{
                const list = await fetch(DOG_HOST_URL + '/api/did/myUid?address=' + reqAddress.toString().toLowerCase())
                const resList = await list.json()
                let showName = '';
                let showImage = '';
                if (resList['code'] === 1) {
                    const listRow = resList['data'];
                    if(listRow.length > 0){
                        showName = listRow[0].name;
                        showImage = listRow[0].mate;
                    }
                }
                setTokenName(showName);
                setTokenImage(showImage);
                setDataState(true);
            }

            const responseMine = await fetch(DOG_HOST_URL + '/ethscription/my?pageSize='+1+'&pageNum=' + 1 +'&address=' + reqAddress.toLowerCase())
            const resMine = await responseMine.json()
            let ethThrictionName = '';
            if (resMine['code'] === 200) {
                const mateList =  resMine['data'];
                if(mateList.length > 0){
                    ethThrictionName = mateList[0].name + ".eth";
                }
            }
            setEthscriber(ethThrictionName);
        }catch (e) {
            console.error(e);
        }
    }, [account, address, x20Token, setTokenName, setTokenImage, setDataState, setEthscriber]);

    useInterval(readInfos, 1000);

    console.info('isToken=' + isToken);

    const doReleaseTokens = useCallback(async () => {
        if(!account){return message.error('symbol can not be empty');}
        // await x20Pool.releaseTokens();
    }, [ account,]);


const HeaderLinks = styled(Row)`
  justify-self: center;
  background-color: ${({ theme }) => theme.deprecated_bg0};
  width: max-content;
  padding: 2px;
  border-radius: 16px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  overflow: auto;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    justify-self: start;
    `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-self: center;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    z-index: 99;
    position: fixed;
    bottom: 0; right: 50%;
    transform: translate(50%,-50%);
    margin: 0 auto;
    background-color: ${({ theme }) => theme.deprecated_bg0};
    border: 1px solid ${({ theme }) => theme.deprecated_bg2};
    box-shadow: 0px 6px 10px rgb(0 0 0 / 2%);
  `};
`
const activeClassName = 'active'
const StyledNavLink = styled(NavLink)`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.deprecated_text2};
  font-size: 1rem;
  font-weight: 500;
  padding: 8px 12px;
  word-break: break-word;
  overflow: hidden;
  white-space: nowrap;
  &.${activeClassName} {
    border-radius: 14px;
    font-weight: 600;
    justify-content: center;
    color: ${({ theme }) => theme.deprecated_text1};
    background-color: ${({ theme }) => theme.deprecated_bg1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.deprecated_text1)};
  }
`

    const copyOk = () => {
        message.success('Copy successfully')
    }

    return (

        <div className="com_home">
            <div className="center-cont">
                <StyledNavLink id={`vote-nav-link`} to={'/apps'}>
                    <div className="to_app_btn">
                        Apps
                    </div>
                </StyledNavLink>
            </div>

            {dataState ? (
                <div className="com_home_div">
                    {/*<div className="text">{tokenName}</div>*/}
                    <div className="fast-border" style={{marginTop: '.225rem',}}>
                        <div className="fast-item">

                            {/*<StyledNavLink id={`swap-nav-link`} to={'/apps'}>
                                <Trans>Apps</Trans>
                            </StyledNavLink>*/}


                                <StyledNavLink id={`vote-nav-link`} to={'/MyLands'}>
                                <div className="box_logo">
                                    <img className="rounded-full" src={eth_sample} alt=""/>
                                </div>
                                </StyledNavLink>
                            <div>

                                {/* {ethscriber && (
                            <div>
                                <a href={ '#' }>
                                <div className="text">Lands: {ethscriber}</div>
                                </a>
                            </div>
                            )}


                            {isToken && (
                            <div>
                                <CopyToClipboard text={address} onCopy={copyOk}>
                                    <a className="text">SocialToken Constract: copy</a>
                                </CopyToClipboard>
                            </div>
                            )}

                            {isToken && (
                                <div>
                                    <a href={ isToken ? 'https://newcryptospace.com/#/mintToken/' + address : 'https://newcryptospace.com/#/UNIDID' }>
                                        <div className="text">To Mine the tokens : {tokenName}</div>
                                    </a>
                                </div>
                            )}
                            */}


                            </div>
                        </div>
                    </div>
                </div>
            ) : <div/>
            }
        </div>
    )
}
export default ComHome
