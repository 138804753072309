import {Image, message, Pagination, Table} from 'antd'
import React, {useCallback, useState} from 'react'
// import {useWeb3React} from '@web3-react/core'
import './PubProfile.css'
// import {useCryptoToken, useX20Factory,} from '../../../hooks/useContract';
import useInterval from '../../../hooks/useInterval'
import {BigNumber} from "bignumber.js";
import {getDisplayBalance, toBigString} from "../../../utils/formatBalance";
import {useWeb3React} from "@web3-react/core";
import TwtLogin, {ALL_POINT_LOGIN_KEY} from "../../../components/TwtLogin";
import {useX20Factory,  useX20Token} from "../../../hooks/useContract";
import {TEST_CRYPTO_TOKEN, x20PubTokenList} from "../../../constants/Constants";
import X20TwtLogin from "../../../components/TwtLogin/X20TwtLogin";
import {NavLink} from "react-router-dom";


const { Column } = Table;
const PubProfile = (props: any) => {
    const { account,provider } = useWeb3React()
    const API_SERVER = process.env.REACT_APP_API_SERVER;


    const [tokenPoolInfo, setTokenPoolInfo] = useState(new Map());
    const [x20TokenList, setX20TokenList] = useState([]);
    const [myInfo, setMyInfo] = useState({userName:'',nickName:'',avatar:'',});
    const [myToken, setMyToken] = useState('');
    const [myJoinX20ProjectList, setMyJoinX20ProjectList] = useState([]);
    const [myJoinX20ProjectLogList, setMyJoinX20ProjectLogList] = useState([]);

    const x20Factory = useX20Factory();
    const x20Token = useX20Token(myToken);
    const baseToken = useX20Token(TEST_CRYPTO_TOKEN);

    const [current, setCurrent] = useState(1);
    const [total, setTotal] = useState(0)
    const onChange = (page: any) => {
        setCurrent(page);
    };

    const [currentLog, setCurrentLog] = useState(1);
    const [totalLog, setTotalLog] = useState(0)
    const onChangeLog = (page: any) => {
        setCurrentLog(page);
    };


    const getMyJoinInfo = useCallback(async () => {
        // const twToken = localStorage.getItem(ALL_POINT_LOGIN_KEY);
        if(!account)return;
        try{
            const list = await fetch(API_SERVER + '/v2api/x20/myAccount?address=' + account +'&projPage=' + current +'&logPage=' + currentLog)
            const resList = await list.json()
            if (resList['code'] === 200) {
                const listRow = resList['data'];
                if(listRow['tweetUser']){
                    setMyInfo(listRow['tweetUser']);
                    setMyToken(listRow['tweetUser']['token']);
                }
                setMyJoinX20ProjectList(listRow['myJoinX20ProjectList']);
                setMyJoinX20ProjectLogList(listRow['myJoinX20ProjectLogList']);
                setTotal(listRow['totalProj']);
                setTotalLog(listRow['totalLog']);
            }
        }catch (e) {
            console.error(e);
        }
    }, [account, setMyInfo, setMyToken, setMyJoinX20ProjectList, setMyJoinX20ProjectLogList, current, currentLog, setTotal, setTotalLog]);

    const getTokenList = useCallback(async () => {
        if(!account)return;
        try{
            // @ts-ignore
            const tokenList = await x20Factory.getJoin(account);
            console.info('tokenList=');
            console.info(tokenList);
            const rsMap = new Map();
            for (let i=0; i< tokenList.length; i++){
                const rewardInfoObj = tokenList[i];
                const available = getDisplayBalance(new BigNumber(rewardInfoObj.available.toString()));
                const userWithdraw = getDisplayBalance(new BigNumber(rewardInfoObj.userWithdraw.toString()));
                console.info('available=' + available + 'userWithdraw=' + userWithdraw);
                rsMap.set(rewardInfoObj.tokenAddress.toString().toLowerCase(), {available:available,userWithdraw:userWithdraw});
            }
            setTokenPoolInfo(rsMap);
        }catch (e) {
            console.error(e);
        }
    }, [account, setTokenPoolInfo, current, currentLog, setTotal, setTotalLog, x20Factory]);

    const fetchLastTime = useCallback(async () => {
        try {
            getMyJoinInfo();
            getTokenList();
        } catch (e) {
            console.log(e);
        }
    }, [account,  getMyJoinInfo, setX20TokenList,
        // getTokenList, cryptoToken
    ]);
    useInterval(fetchLastTime);

    // const doWithrawToken = async (token: any) => {
    //   try{
    //       // @ts-ignore
    //       await x20Factory.releaseTokens(token);
    //   }catch (e: any){
    //       console.error(e);
    //   }
    // }

    const doCreateToken = useCallback(async () => {
        if(!myInfo){return message.error('please login first');}
        if(!myInfo.userName){return message.error('please login first');}
        try{
            if(x20Factory){
                await x20Factory.createToken(false, myInfo.userName, myInfo.nickName, myInfo.avatar);
            }
        }catch (e: any){
            console.error(e);
        }
    }, [x20Factory, myInfo]);

    // const doWithrawToken = async (token: string) => {
    //     {
    //         x20PubTokenList.map((item, index) => {
    //             if (item.token === token) {
    //                 window.location.href = "/#/i/" + index;
    //             }
    //         })
    //     }
    // }
    return (
        <>
        <div className="MyTokenList">

            <TwtLogin project={"twitter"}/>
            {/*{
                myToken ? <div/> :
                <button onClick={() => doCreateToken()} className="login_button mt-10" type="submit">Create my X username social token contract</button>
            }*/}

            {/*<div className="ens_title">
                <p>My mining</p>
            </div>*/}

            <div className="history">
                <div className="type-title">My mining</div>
                <div className="table-list">
                    <Table id="domainTable" dataSource={myJoinX20ProjectList} pagination={false} >
                        <Column title="Name" dataIndex="userName" key="userName"/>
                        <Column title="Symbol" dataIndex="nickName" key="nickName"/>
                        <Column title="Avater" dataIndex="avatar" key="avatar"
                                render={(_: any, record: any) => (
                                    <div className="table_time">
                                        <Image
                                            width={20}
                                            className="rule_img"
                                            src={record.avatar}
                                        />
                                    </div>
                                )}
                        />
                        <Column title="Can Withdraw" dataIndex="creator" key="creator"
                                render={(_: any, record: any) => (
                                    <div className="table_copy">
                                        {tokenPoolInfo.get(record.token) ? tokenPoolInfo.get(record.token)['available'] : 0}
                                    </div>
                                )}
                        />
                        <Column title="Has Withdrawn" dataIndex="creator" key="creator"
                                render={(_: any, record: any) => (
                                    <div className="table_copy">
                                        {tokenPoolInfo.get(record.token) ? tokenPoolInfo.get(record.token)['userWithdraw'] : 0}
                                    </div>
                                )}
                        />
                        <Column title="Contract(Arbitrum)" dataIndex="tokenAddress" key="tokenAddress"
                                render={(_: any, record: any) => (
                                    <div className="table_copy">
                                        {/*<a className="copy_btn" href={"/#/mintToken/"+record.token}>Mint</a>*/}
                                        <NavLink to={'/i/' + record.token}>
                                            <div className="x20_comm_btn">Enter</div>
                                        </NavLink>
                                    </div>
                                )}
                        />
                    </Table>
                </div>
                <div className="pageDiv">
                    <Pagination
                        total={total}
                        defaultPageSize={10}
                        defaultCurrent={1}
                        current={current}
                        onChange={onChange}
                        showSizeChanger={false}
                    />
                </div>
            </div>


            <div className="history">
                <div className="type-title">My Log</div>
                <div className="table-list">
                    <Table id="domainTable" dataSource={myJoinX20ProjectLogList} pagination={false} >
                        <Column title="Name" dataIndex="userName" key="userName"/>
                        <Column title="Symbol" dataIndex="nickName" key="nickName"/>
                        <Column title="Avater" dataIndex="name" key="name"
                                render={(_: any, record: any) => (
                                    <div className="table_time">
                                        <Image
                                            width={20}
                                            className="rule_img"
                                            src={record.avatar}
                                        />
                                    </div>
                                )}
                        />
                        <Column title="Amount" dataIndex="amount" key="amount"/>
                        <Column title="Time" dataIndex="createTime" key="createTime"/>
                    </Table>
                </div>
                <div className="pageDiv">
                    <Pagination
                        total={totalLog}
                        defaultPageSize={10}
                        defaultCurrent={1}
                        current={currentLog}
                        onChange={onChangeLog}
                        showSizeChanger={false}
                    />
                </div>
            </div>

            {/*<X20TwtLogin project={"twitter"}/>*/}
        </div>
        </>
    )
}
export default PubProfile
