import {Image, message, Modal, Radio, Table, Tabs} from 'antd'
import React, {useCallback, useState} from 'react'
import {useWeb3React} from '@web3-react/core'
import dayjs from 'dayjs'
import './ensset.css'
import {useCryptoToken, useShareManagerContract} from '../../hooks/useContract';
import {close, DOG_HOST_URL} from "../../constants/addresses";
import useInterval from '../../hooks/useInterval'

const { Column, ColumnGroup } = Table;
const { TabPane } = Tabs
const EnsRegist = (props) => {
  const { account, provider } = useWeb3React()
  const cryptoToken = useCryptoToken();
  const shareManager = useShareManagerContract();
  const [ensList, setEnsList] = useState([]);

  const getEnsList = useCallback(async () => {
    if(!account)return;
    try{
      const list = await fetch(DOG_HOST_URL + '/api/domainname/self?address=' + account.toString())
      const resList = await list.json()
      if (resList['code'] === 1) {
        const listRow = resList['data'];
        setEnsList(listRow);
      }
    }catch (e) {
      console.error(e);
    }
  }, [account, setEnsList,]);
  const fetchLastTime = useCallback(async () => {
    if(close)return;
    try {
      getEnsList();
    } catch (e) {
      console.log(e);
    }
  }, [account, close, getEnsList, setEnsList, cryptoToken,]);
  useInterval(fetchLastTime, 3000);


  interface DataType {
    key: React.Key;
    firstName: string;
    lastName: string;
    age: number;
    address: string;
    tags: string[];
  }


  const [goonVisible, setGoonVisible] = useState(false);
  const [goonEns, setGoonEns] = useState({});
  const showRule = (e) => {
    setGoonEns(e);
    setGoonVisible(true);
  };
  const cancelRule = () => {
    setGoonVisible(false);
  };
  const [chainType, setChainType] = useState('ETH');
  const chainTypeChange = async (e) => {
    setChainType(e.target.value);
  };

  const [nftBgColor, setNftBgColor] = useState('WHITE');
  const nftBgColorChange = async (e) => {
    setNftBgColor(e.target.value);
  };


  //////////////////////////////////////////////////////////////////
  const [constractAddress, setConstractAddress] = useState("");
  const constractAddressChange = ({ target: { value } }) => {
    let checkedName = value.toString().toLowerCase().replace(" ", "");
    setConstractAddress(checkedName);
  };

  const [nftSer, setNftSer] = useState("");
  const nftSerChange = ({ target: { value } }) => {
    let checkedName = value.toString().toLowerCase().replace(" ", "");
    setNftSer(checkedName);
  };

  const setAvaterHandle = useCallback(async () => {
    if(!constractAddress){
      return message.error('constract address can not be empty');
    }
    if(!nftSer){
      return message.error('nft id  can not be empty');
    }
    const params = ("Chain="+chainType + "\nContract=" + constractAddress.toLowerCase() + "\nNftId=" + nftSer.toLowerCase());
    const signature = (await provider.send('personal_sign', [params, account.toLowerCase()]));
    console.info('message=  ' + params);
    console.info('signature=  ' + signature);
    cancelRule();
    const result = await fetch(DOG_HOST_URL + '/api/domainname/image?address=' + account.toLowerCase()
        +'&domain='+goonEns.id + '&signature='+ signature +'&chain='+ chainType +'&contract='
        +constractAddress.toLowerCase() +'&nftId='+nftSer.toLowerCase() + "&color="+nftBgColor+"&type=ens"
    );
    const responceBody = await result.json()
    if (responceBody['code'] === 1) {
      message.success('set image success please refresh the matedata');
    }else{
      message.error(responceBody['msg']);
    }
  }, [account,shareManager, provider, nftSer, chainType, goonEns, setGoonVisible, nftBgColor]);

  return (
    <div className="ensset_divs">
      <div className="ens_title">
        <p>MyCryptoSpace</p>
      </div>

      <div className="history">
        <div className="history_t">My domain name</div>
        <div className="table-list">
          <Table id="domainTable" dataSource={ensList}  >
            <Column title="UNIDID" dataIndex="name" key="name"
                render={(_: any, record: DataType) => (
                    <div className="table_time">
                      <Image
                          width={20}
                          className="rule_img"
                          src={record.image}
                      />
                    </div>
                )}
            />

            <Column title="Domain name" dataIndex="name" key="name" />
            <Column title="Expiration time" dataIndex="expiryTime" key="expiryTime"
                    render={(_: any, record: DataType) => (
                        <div className="table_time">
                          {dayjs(dayjs(record.expiryTime).valueOf() - 28800000).format('YYYY-MM-DDTHH:mm:ss[Z]')}(UTC)
                        </div>
                    )}
            />
            <Column
                title="Set Avatar"
                key="action"
                render={(_: any, record: DataType) => (
                    <div className="table_btns">
                      {
                        (true) ? <a className="table_btns" onClick={() => showRule(record)}>Set Avatar</a> : <div/>
                      }
                    </div>
                )}
            />
          </Table>
        </div>
      </div>

      {/*<div className="air_invite_div">
        <div className="title">
          Coming soon
        </div>
      </div>*/}

      <Modal
          visible={goonVisible}
          onCancel={cancelRule}
          closable={false}
          centered={true}
          footer={null}
          className="rule_modal_box"
      >
        <div className="title tit">
          Set {goonEns.name} Avatar
        </div>

        <div className="ensset_area">
          <div className="search-row">
            <div className="vote_type">
              Select the blockchain network that your NFT is issued on.
            </div>
            <div className="vote_year">
              <Radio.Group onChange={chainTypeChange} value={chainType}>
                <Radio value={'ETH'}>Ethereum</Radio>
                <Radio value={'ARB'}>Arbitrum</Radio>
              </Radio.Group>
            </div>
          </div>
          <div className="search-row">
            <div className="vote_type">
              Choose a color for your domain name
            </div>
            <div className="vote_year">
              <Radio.Group onChange={nftBgColorChange} value={nftBgColor}>
                <Radio value={'WHITE'}>White</Radio>
                <Radio value={'GREEN'}>Green</Radio>
                <Radio value={'RED'}>Red</Radio>
                <Radio value={'BLUE'}>DarkBlue</Radio>
              </Radio.Group>
            </div>
          </div>

          <div className="search-row">
            The NFT Contract Address:
            <form className="search-form" action="#">
              <input
                  placeholder="Input your NFT Contract Address"
                  autoCapitalize="off"
                  className="modal_input"
                  value={constractAddress}
                  onChange={constractAddressChange}
              />
            </form>
          </div>
          <div className="search-row">
            The NFT Item/TokenID:
            <form className="search-form" action="#">
              <input
                  placeholder="Input your NFT Item/TokenID"
                  autoCapitalize="off"
                  className="modal_input"
                  value={nftSer}
                  onChange={nftSerChange}
              />
            </form>
          </div>
        </div>


        <div  className={ 1===1 ? "ens_handle_btn" : "ens_handle_btn_disable"} onClick={setAvaterHandle}>Sign to Confirm</div>
      </Modal>
    </div>
  )
}
export default EnsRegist
