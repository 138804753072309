import './invite.css'

import React, { useCallback, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { message } from 'antd'
import { DOG_HOST_URL } from '../../constants/addresses'
import { useShareManagerContract } from '../../hooks/useContract'

const Invite = (props) => {
  const cancel = () => {
    // props.history.push('/')
  }
  const { account,provider } = useWeb3React()
  const [myInviter, setMyInviter] = useState('')
  const voteManager = useShareManagerContract();

  useEffect(() => {
    let link = window.location.href
    let tempParam = link.substring(link.indexOf('invite/') + 7)
    if (tempParam.indexOf('?') > 0) {
      tempParam = tempParam.substring(0, tempParam.indexOf('?'));
      tempParam = tempParam.toLowerCase();
    }
    setMyInviter(tempParam)
  }, [])

  const doRegest = useCallback(async () => {
    if (!account) {
      message.error('Please connect the wallet first.')
      return
    }
    if (myInviter && myInviter.length !== 42) {
      message.error('error inviter address')
      return
    }
    if (account.toLowerCase() === myInviter) {
      message.error('Not allowed to invite yourself')
      return
    }
    try {
      await voteManager.regist(account, myInviter).then((response) => {
        console.info(response);
        message.success('bind success')
        window.location.href = '/#/airdrop/points'
      }).catch((error) => {
        message.error('bind failed');
        console.error(error);
        window.location.href = '/#/airdrop/points'
      });
    }catch (e) {
      console.error(e);
    }
  }, [account, myInviter, voteManager])


  const doRegestV2 = useCallback(async () => {
    if (!account) {
      message.error('Please connect the wallet first.')
      return
    }
    if (myInviter && myInviter.length !== 42) {
      message.error('error inviter address')
      return
    }
    if (account.toLowerCase() === myInviter) {
      message.error('Not allowed to invite yourself')
      return
    }
    try {
      const params = ("address="+account.toLowerCase() + "\ninviter=" + myInviter.toLowerCase());
      console.info('params===>' + params);
      const signature = (await provider.send('personal_sign', [params, account.toLowerCase()]));
      console.info('signature=  ' + signature);
      const result = await fetch(DOG_HOST_URL + '/ethscription/vote/bindUser?address=' + account.toLowerCase()
          +'&inviter='+ myInviter.toLowerCase() + '&signature='+ signature
      );
      const res = await result.json()
      if (res['code'] === 200) {
        message.success('bind success')
        window.location.href = '/#/airdrop/vote'
      }else{
        message.error(res['msg']);
        window.location.href = '/#/airdrop/vote'
      }
    }catch (e) {
      console.error(e);
    }
  }, [account, myInviter, voteManager])

  return (
    <div className="invite">
      <div className="box">
        <div className="title"> {myInviter}</div>
        <div className="title">Confirm the binding invitation relationship?</div>
        <div className="lintbtn">
          <NavLink to={'/'}>
            <div className="cancel" onClick={cancel}>
              Cancel
            </div>
          </NavLink>
          <div className="confirm" onClick={doRegestV2}>
            Confirm
          </div>
        </div>
      </div>

    </div>
  )
}
export default Invite
