import './admin.css'

import React, { useCallback, useState } from 'react'
import {
    ADD_SECRET,
    CRYPTO_TOKEN,
    DOG_HOST_URL,
    ENS_CONTROLLER,
    ENS_END,
    PUBLIC_RESOLVER,
    SHORT_CONTROLLER
} from '../../../constants/addresses'
import useInterval from '../../../hooks/useInterval'
import dayjs from "dayjs";
import searchIcon from "../../Ens/Search/search.svg";
import {useWeb3React} from "@web3-react/core";
import {useBaseRegistrarNft, useController, useShortController} from "../../../hooks/useContract";
const Admin = (props) => {

  const [total, setTotal] = useState(0)
  const [airdropAList, setAirdropAList] = useState([])
  const fetchLastTime = useCallback(async () => {
    try {

      const listA = await fetch(DOG_HOST_URL + '/api/domainname/admin?size=100')
      const resListA = await listA.json()
      if (resListA['code'] === 1) {
        const listRow = resListA['data']
        setAirdropAList(listRow);
        setTotal(resListA['total']);
      }
    } catch (e) {
      console.log(e)
    }
  }, [setAirdropAList, setTotal])
  useInterval(fetchLastTime, 3000)

    const [sendUser, setSendUser] = useState("");
    const sendUserChange = ({ target: { value } }) => {
        let checkedName = value.toString().toLowerCase().replace(" ", "");
        setSendUser(checkedName);
    };


    const [domainName, setDomainName] = useState("");
    const domainNameChange = ({ target: { value } }) => {
        let checkedName = value.toString().toLowerCase().replace(" ", "");
        setDomainName(checkedName);
    };

    const { account } = useWeb3React()
    const controller = useShortController();
    const baseRegistrar = useBaseRegistrarNft();

    const initHandle = useCallback(async () => {
        // await baseRegistrar.addController(ENS_CONTROLLER);
        // await baseRegistrar.removeController(ENS_CONTROLLER);

        await controller.registerAdmin(sendUser, domainName, ADD_SECRET, PUBLIC_RESOLVER);

        // await registPriceOracle.setPrices('200000000000000000');
        // const price = await tokenPriceOracle.getTokenEthPrice();
        // const priceNew = getBalanceNumber(new BigNumber(price.toString()), 18);
        // console.info('price=' + price +'|priceNew='+priceNew)

        // await controller.setShareToken(CRYPTO_TOKEN);
        // await shareManager.setShareToken(CRYPTO_TOKEN);

        // await baseRegistrar.addController(SHORT_CONTROLLER);
        // await controller.setPriceOracle();
        // await controller.setRegState([account], false);
        // await baseRegistrar.setBaseUrl('https://newcryptospace.org/api/domainname/mate?id=');
        // const x = await baseRegistrar.tokenURI('68490849177350780583669473090509297879727137682161907224422451510039933989718');
        // console.info('Url=' + x);
        // var commitment = await controller.makeCommitmentWithConfig(domainName, account, secret, PUBLIC_RESOLVER, account);
        // console.info('commitment==>'+commitment);
        // await controller.authNodes([commitment]);

        // var tokenURI = await baseRegistrar.tokenURI('75996404866922495749963021992354359041740452350126598603006648594842177015891');
        // console.info('tokenURI==>'+tokenURI);
    }, [account, sendUser, domainName, controller, baseRegistrar,ADD_SECRET, SHORT_CONTROLLER, PUBLIC_RESOLVER]);

  return (
    <div className="ensadmin">

        <div className="air_invite">
            <div className="search-area">
                <form className="search-form" action="#">
                    <input
                        placeholder="Input Target User"
                        autoCapitalize="off"
                        className="modal_input"
                        value={sendUser}
                        onChange={sendUserChange}
                    />
                </form>
            </div>
            <div className="search-area">
                <form className="search-form" action="#">
                    <img src={searchIcon}/>
                    <input
                        placeholder="Input Name"
                        autoCapitalize="off"
                        className="modal_input"
                        value={domainName}
                        onChange={domainNameChange}
                    />
                    <div className="end">
                        {ENS_END}
                    </div>
                </form>
                <button onClick={() => initHandle()} className="earch_button" type="submit" data-testid="home-search-button">Send</button>
            </div>
        </div>

      <div className="total">
          Domain Total:{total}
      </div>
      <div className="total">
      </div>

      <div className="title">
          Domain List:
      </div>
      <div className="table">
        <div className="thead">
          <div className="tr">
            <div className="td">name</div>
            <div className="td address">address</div>
            <div className="td time">time</div>
          </div>
        </div>
        <div className="tbody">
          {airdropAList.map((item, i) => {
            return (
              <div className="tr" key={i}>
                <div className="td">{item.name + ENS_END}</div>
                <div className="td address">{item.address}</div>
                <div className="td time">{item.createTime}</div>
                {/*<div className="td time">{dayjs(dayjs(item.expiryTime * 1000).valueOf()).format('YYYY-MM-DDTHH:mm:ss[Z]')}(UTC)</div>*/}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
export default Admin
