import {message, Modal, Image, Radio, Table, Switch, Select, Upload} from 'antd'
import React, {useCallback, useState} from 'react'
import {useWeb3React} from '@web3-react/core'
import dayjs from 'dayjs'
import './didreg.css'
import {
  useController,
  useCryptoToken,
  useDidFeeManager,
  useDidNft,
  useShareManagerContract
} from '../../../hooks/useContract';
import {close, DID_NFT, DOG_HOST_URL, ONE_YEAR} from "../../../constants/addresses";
import useInterval from '../../../hooks/useInterval'
import useDidPayEth from "../../../hooks/useDidPayEth";
import {CopyToClipboard} from "react-copy-to-clipboard";
import ImgCrop from 'antd-img-crop';
import {NavLink} from "react-router-dom";
import TwtLogin from "../../../components/TwtLogin";
import BtcInscribe from "../BtcInscribe/BtcInscribe";
import TwtInfos from "../../../components/TwtInfos/TwtInfos";
import UrlBtnTitle from "../../components/UrlBtnTitle";

const { Column } = Table;
const Standard = (props) => {
  const { account, provider } = useWeb3React()
  const cryptoToken = useCryptoToken();
  const didNft = useDidNft();
  const didFeeManager = useDidFeeManager();
  const shareManager = useShareManagerContract();
  const [ensList, setEnsList] = useState([]);
  const [payDidFee, setPayDidFee] = useState(false);
  const {payMintEthFee} = useDidPayEth();

  const [stdList, setStdList] = useState([]);
  const [userMintIndex, setUserMintIndex] = useState(-1);

  const doPayFeee = useCallback(async () => {
    if(!payDidFee && userMintIndex>= 0){
      await payMintEthFee(userMintIndex);
    }
  },[payMintEthFee, payDidFee, userMintIndex]);

  const getEnsList = useCallback(async () => {
    if(!account)return;
    try{
      const list = await fetch(DOG_HOST_URL + '/api/did/myUid?address=' + account.toString().toLowerCase())
      const resList = await list.json()
      if (resList['code'] === 1) {
        const listRow = resList['data'];
        setEnsList(listRow);
        setUserMintIndex(resList['index']);
      }
      const list2 = await fetch(DOG_HOST_URL + '/api/did/myStd?address=' + account.toString().toLowerCase())
      const res2List = await list2.json()
      if (res2List['code'] === 1) {
        const listRow = res2List['data'];
        setStdList(listRow);
      }
    }catch (e) {
      console.error(e);
    }
  }, [account, setEnsList, setStdList, setUserMintIndex]);

  const fetchLastTime = useCallback(async () => {
    if(close)return;
    try {
      getEnsList();
      if(userMintIndex >= 0){
        const payFee = (await didFeeManager.isPayFee(account, userMintIndex)).toString();
        setPayDidFee(payFee === 'true')
      }
    } catch (e) {
      console.log(e);
    }
  }, [account, close, getEnsList, setEnsList, cryptoToken, setPayDidFee, didNft, didFeeManager, userMintIndex, setStdList]);
  useInterval(fetchLastTime, 2000);


  const [goonEns, setGoonEns] = useState({});
  const [goonVisible, setGoonVisible] = useState(false);
  const showRule = (e) => {
    setGoonEns(e);
    setGoonVisible(true);
  };
  const cancelRule = () => {
    setGoonVisible(false);
  };
  const [chainType, setChainType] = useState('ETH');
  const chainTypeChange = async (e) => {
    setChainType(e.target.value);
    var year = e.target.value;
  };

  const [nftBgColor, setNftBgColor] = useState('WHITE');
  const nftBgColorChange = async (e) => {
    setNftBgColor(e.target.value);
  };


  const [imageVisible, setImageVisible] = useState(false);
  const showSetImage = (e) => {
    setGoonEns(e);
    setImageVisible(true);
  };
  const hideSetImage = () => {
    setImageVisible(false);
  };
  const setAmount = async () => {
    await didFeeManager.setFeeAddress2("0x237ce5f223d201b7917374b3d1cbed17daf3f4cf");
    await didFeeManager.setAmount2(0.001*1e18);
    await didFeeManager.setAmount(0.0015*1e18);
    // didFeeManager.setFee(0.004*1e18);

  };


  //////////////////////////////////////////////////////////////////
  const [domainName, setDomainName] = useState("");
  const domainNameChange = ({ target: { value } }) => {
    let checkedName = value.toString().toLowerCase().replace(" ", "");
    setDomainName(checkedName);
  };
  const [end, setEnd] = useState(true);//true=eth
  const domainEndChange = (value, event) => {
    setEnd(value);
  };

  const [constractAddress, setConstractAddress] = useState("");
  const constractAddressChange = ({ target: { value } }) => {
    let checkedName = value.toString().toLowerCase().replace(" ", "");
    setConstractAddress(checkedName);
  };

  const [nftSer, setNftSer] = useState("");
  const nftSerChange = ({ target: { value } }) => {
    let checkedName = value.toString().toLowerCase().replace(" ", "");
    setNftSer(checkedName);
  };

  const changeImageHandle = useCallback(async () => {
    // didNft.setBaseurl("https://newcryptospace.com/api/did/didmate?id=");
    // const x = await shareManager.amount();
    // console.info('x=' + x);
    // await shareManager.setFee('10');
    // const name = goonEns.code;

    const fullName = domainName + (end ?  '.eth' : '.new');
    if(!payDidFee){
      return; //message.error('you need conform first');
    }
    const params = ("domain="+fullName);
    const signature = (await provider.send('personal_sign', [params, account.toLowerCase()]));
    console.info('message=  ' + params);
    console.info('signature=  ' + signature);
    const result = await fetch(DOG_HOST_URL + '/api/did/mintUid?address=' + account.toLowerCase()
        +'&domain='+fullName + '&signature='+ signature
    );
    const responceBody = await result.json()
    if (responceBody['code'] === 1) {
      message.success('mint success');
    }else{
      message.error(responceBody['msg']);
    }
  }, [account,shareManager, provider, goonEns, setGoonVisible, domainName, end, payDidFee,
  ]);

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const copyOk = () => {
    message.success('Copy successfully')
  }


  const setAvaterHandle = useCallback(async () => {
    if(!constractAddress){
      return message.error('constract address can not be empty');
    }
    if(!nftSer){
      return message.error('nft id  can not be empty');
    }
    const params = ("Chain="+chainType + "\nContract=" + constractAddress.toLowerCase() + "\nNftId=" + nftSer.toLowerCase());
    const signature = (await provider.send('personal_sign', [params, account.toLowerCase()]));
    console.info('message=  ' + params);
    console.info('signature=  ' + signature);
    cancelRule();
    const result = await fetch(DOG_HOST_URL + '/api/domainname/image?address=' + account.toLowerCase()
        +'&domain='+goonEns.id + '&signature='+ signature +'&chain='+ chainType +'&contract='
        +constractAddress.toLowerCase() +'&nftId='+nftSer.toLowerCase() + "&color="+nftBgColor+"&type=did"
    );
    const responceBody = await result.json()
    if (responceBody['code'] === 1) {
      message.success('set image success please refresh the matedata');
    }else{
      message.error(responceBody['msg']);
    }
  }, [account,shareManager, provider, nftSer, chainType, goonEns, setGoonVisible, nftBgColor]);


  const [fileList, setFileList] = useState([
    // {
    //   uid: '-1',
    //   name: 'image.png',
    //   status: 'done',
    //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
  ]);
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };


  const setImageAvaterHandle = useCallback(async () => {
    if(!fileList && fileList.length < 1){
      return message.error('please update image first');
    }
    const imageUrl = fileList[0].response.data;
    if(!imageUrl){
      return message.error('please update image first');
    }
    const params = ("domain="+goonEns.id + "\nimageUrl=" + imageUrl);
    const signature = (await provider.send('personal_sign', [params, account.toLowerCase()]));
    console.info('message=  ' + params);
    console.info('signature=  ' + signature);
    cancelRule();
    const result = await fetch(DOG_HOST_URL + '/api/domainname/setImage?address=' + account.toLowerCase()
        +'&domain='+goonEns.id + '&signature='+ signature +"&type=did" +"&imageUrl=" + imageUrl
    );
    const responceBody = await result.json()
    if (responceBody['code'] === 1) {
      message.success('set image success please refresh the matedata');
      hideSetImage();
    }else{
      message.error(responceBody['msg']);
    }
  }, [account,shareManager, provider, fileList,]);


  return (
      <div className="ant-row BigPage">
        <div className="didreg_div">

        {/*<div className="ens_small_title">
          Aggregation of decentralized applications based on the Lands open platform
        </div>*/}

        <TwtInfos/>

        {/*<div className="ens_title">
          STANDARD (The first application based on Lands open platform)
        </div>*/}
        <div className="ens_sub_title">
          Map the Ethereum decentralized identities to the Bitcoin network.
        </div>

        <div className="title tit">
          Mint Unified DID Pass NFT
        </div>
        <div className="did_reg_div">
          <div className="new_nft ">
            <div className="ensset_area">
              <div className="reg_item_row">
                1.Your domain name:
                <form className="did-reg-form" action="#">
                  <input
                      disabled="disabled"
                      placeholder="Suspend minting the NFT"
                      autoCapitalize="off"
                      className="modal_input"
                      value={domainName}
                      onChange={domainNameChange}
                  />
                  <Switch onChange={domainEndChange} checkedChildren=".eth(ENS)  " unCheckedChildren=".new  "
                          defaultChecked/>
                </form>
              </div>
            </div>

            {/*<div  className={ !payDidFee ? "ens_handle_btn btn_area" : "ens_handle_btn_disable btn_area"} onClick={doPayFeee}>Confirm</div>
          <div style={{marginTop:'2rem'}} className={ payDidFee ? "ens_handle_btn" : "ens_handle_btn_disable"} onClick={changeImageHandle}>Sign To Mint</div>
        */}
          </div>

          <div className="to_oldair_area">
            <NavLink className={"to_old_airdrop"} to={'/domainname'}>
                  <span className="rule">
                    Register your .new domain name
                  </span>
            </NavLink>
          </div>
        </div>


        {/*<div style={{marginTop:'1rem'}} className="sub_title tit">
        Create Ethscription Dumb Contract
      </div>
      <div className="did_reg_div" style={{marginTop:'1rem'}} >
        <div className="new_nft ">
          <div className="create_dumb_area">
            <div className="reg_item_row_div">
              <div className="ens_handle_btn">
                <a className="ens_handle_btn" href="https://newcryptospace.com/#/createToken?chain=arbitrum">Create Fsocial Token</a>
              </div>
              <div className="ens_handle_btn" style={{marginTop:'1rem'}}>
                <a className="ens_handle_btn" href="https://newcryptospace.com/#/createDidToken?chain=arbitrum">Create Fsocial Points</a>
              </div>
            </div>
          </div>
        </div>
      </div>*/}

        {/*<div className="history">
          <div className="history_t">TheBitcoinStandardDAO NFT</div>
          <div className="table-list">
            <Table id="stdDomainTable" dataSource={stdList}>

              <Column title="TheBitcoinStandardDAO NFT" dataIndex="name" key="name" style={{textAlign: 'left'}}
                      render={(_, record) => (
                          <div className="table_time" style={{textAlign: 'left'}}>
                            <Image
                                width={20}
                                className="rule_img"
                                src={record.mate}
                            />
                          </div>
                      )}
              />

              <Column title="#ID" dataIndex="id" key="id"
                      render={(_, record) => (
                          <div className="table_time">
                            {'#' + record.id}
                          </div>
                      )}
              />

            </Table>
          </div>

          <div className="reg_item_row_btns">
            <a className="ens_reg_url" href="https://opensea.io/collection/thebitcoinstandarddao-arb">View on
              opensea</a>
          </div>
        </div>*/}




        <BtcInscribe/>

       {/* <div className="history">
          <div className="history_t">My DID</div>
          <div className="table-list">
            <Table id="domainTable" dataSource={ensList}>
              <Column title="UNIDID" dataIndex="name" key="name"
                      render={(_, record) => (
                          <div className="table_time">
                            <Image
                                width={20}
                                className="rule_img"
                                src={record.mate}
                            />
                          </div>
                      )}
              />

              <Column
                  title="Avatar"
                  key="action"
                  render={(_, record) => (
                      <div className="table_copy">
                        <a className="copy_btn" onClick={() => showRule(record)}>Nft Avatar</a>
                        <a className="copy_btn" onClick={() => showSetImage(record)}>Upload Set</a>
                      </div>
                  )}
              />

              <Column title="Domain name" dataIndex="name" key="name"/>
              <Column title="Mint time" dataIndex="expiryTime" key="expiryTime"
                      render={(_, record) => (
                          <div className="table_time">
                            {dayjs(dayjs(record.expiryTime).valueOf() - 28800000).format('YYYY-MM-DDTHH:mm:ss[Z]')}(UTC)
                          </div>
                      )}
              />

              <Column title="UNIDID/CCONFT avatar ipfs URL" dataIndex="expiryTime" key="expiryTime"
                      render={(_, record) => (
                          <div className="table_copy">
                            <CopyToClipboard text={record.mate} onCopy={copyOk}>
                              <a className="copy_btn">Copy</a>
                            </CopyToClipboard>
                            <CopyToClipboard text={record.mateFrom} onCopy={copyOk}>
                              <a className="copy_btn">Copy</a>
                            </CopyToClipboard>
                          </div>
                      )}
              />
              <Column title="UNIDID Contract Address" dataIndex="expiryTime" key="expiryTime"
                      render={(_, record) => (
                          <div className="table_copy">
                            <CopyToClipboard text={DID_NFT} onCopy={copyOk}>
                              <a className="copy_btn">Copy</a>
                            </CopyToClipboard>
                          </div>
                      )}
              />
              <Column title="UNIDID TokenID" dataIndex="expiryTime" key="expiryTime"
                      render={(_, record) => (
                          <div className="table_copy">
                            <CopyToClipboard text={record.id} onCopy={copyOk}>
                              <a className="copy_btn">Copy</a>
                            </CopyToClipboard>
                          </div>
                      )}
              />
            </Table>
          </div>
        </div>
*/}
        <Modal
            visible={goonVisible}
            onCancel={cancelRule}
            closable={false}
            centered={true}
            footer={null}
            className="rule_modal_box"
        >
          <div className="title tit">
            Set {goonEns.name} Avatar
          </div>

          <div className="ensset_area">
            <div className="search-row">
              <div className="vote_type">
                The NFT on the blockchain network
              </div>
              <div className="vote_year">
                <Radio.Group onChange={chainTypeChange} value={chainType}>
                  <Radio value={'ETH'}>Ethereum</Radio>
                  <Radio value={'ARB'}>Arbitrum</Radio>
                </Radio.Group>
              </div>
            </div>
            <div className="search-row">
              <div className="vote_type">
                Choose a color for your domain name
              </div>
              <div className="vote_year">
                <Radio.Group onChange={nftBgColorChange} value={nftBgColor}>
                  <Radio value={'WHITE'}>White</Radio>
                  <Radio value={'GREEN'}>Green</Radio>
                  <Radio value={'RED'}>Red</Radio>
                  <Radio value={'BLUE'}>DarkBlue</Radio>
                </Radio.Group>
              </div>
            </div>

            <div className="search-row">
              The NFT Contract Address:
              <form className="search-form" action="#">
                <input
                    placeholder="Input your NFT Contract Address"
                    autoCapitalize="off"
                    className="modal_input"
                    value={constractAddress}
                    onChange={constractAddressChange}
                />
              </form>
            </div>
            <div className="search-row">
              The NFT Item/TokenID:
              <form className="search-form" action="#">
                <input
                    placeholder="Input your NFT Item/TokenID"
                    autoCapitalize="off"
                    className="modal_input"
                    value={nftSer}
                    onChange={nftSerChange}
                />
              </form>
            </div>
          </div>


          <div className={1 === 1 ? "ens_handle_btn" : "ens_handle_btn_disable"} onClick={setAvaterHandle}>Sign to
            Confirm
          </div>
        </Modal>

        <Modal
            visible={imageVisible}
            onCancel={hideSetImage}
            closable={false}
            centered={true}
            footer={null}
            className="rule_modal_box"
        >
          <div className="title tit">
            Set {goonEns.name} Avatar
          </div>

          <div className="ensset_area">
            <ImgCrop rotationSlider>
              <Upload
                  action={DOG_HOST_URL + "/api/domainname/upImage"}
                  listType="picture-card"
                  fileList={fileList}
                  maxCount="1"
                  onChange={onChange}
                  onPreview={onPreview}
              >
                {fileList.length < 1 && '+ Upload'}
              </Upload>
            </ImgCrop>
          </div>

          <div className={1 === 1 ? "ens_handle_btn" : "ens_handle_btn_disable"} onClick={setImageAvaterHandle}>Sign to
            Confirm
          </div>
        </Modal>

      </div>
      </div>
  )
}
export default Standard
