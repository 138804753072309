import './airdrop.css'

import { Modal } from 'antd'
import React, { useCallback, useState } from 'react'

import logo from '../../assets/img/logo.png'
import against from '../../utils/against'
import { timestampToTime } from '../../utils/time'
const Airdrop = (props) => {
  const [dogeNum, setDogeNum] = useState(0)
  const [handleView, setHandleView] = useState(false)
  const [isFeed, setIsFeed] = useState(false)

  const [receiveType, setReceiveType] = useState(1)
  const [airdropList, setAirdropList] = useState([
    // {
    //   id: 0,
    //   address: "0xdasdasdadsadadsadadsdml",
    //   time: "1645084505",
    // },
  ])

  const handleCancel = () => {
    setHandleView(false)
  }

  return (
    <div className="airdrop">
      <div className="logo">
        <img src={logo} alt="" />
      </div>
      <div className="doge_num">{dogeNum} CRYPTO</div>
      <div className="receive">
        <div
          className={dogeNum == 0 ? 'receive_btn receive_btn_dis' : 'receive_btn'}
        >
          Claim
        </div>
      </div>

      {/*<div className="receive">
        <div className="receive_btn">Comming soon</div>
      </div>*/}
      {/* {receiveType === 0 ? "Connect wallet to Claim" : ""}
          {receiveType === 1 ? "Claim" : ""}
          {receiveType === 2 ? "You do not have permission to receive" : ""}
          {receiveType === 3 ? "The airdrop has been sent" : ""} */}

      {/* <div className="air_text">
        <p>
          Only the first 24 users who meet the requirements can claim the
          airdrop.Committed to being the portal of web3
        </p>
      </div> */}

      <div className="air_table">
        <div className="air_t">Latest Airdrop</div>
        <div className="table">
          <div className="thead">
            <div className="tr">
              <div className="td">Address</div>
              <div className="td">Amount</div>
              <div className="td">Time</div>
            </div>
          </div>
          <div className="tbody">
            {airdropList.map((item, i) => {
              return (
                <div className="tr" key={i}>
                  <div className="td">{against(item.address)}</div>
                  <div className="td">{item.amount} CRYPTO</div>
                  <div className="td">{timestampToTime(item.time)}</div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <Modal
        title="Connect Wallet"
        className="connect_modal"
        footer={null}
        visible={handleView}
        onCancel={handleCancel}
      >
        <div className="address_modal">
          <div className="address_btn">
            <div className="title">Send Fee</div>
            <div className="box metamask">
              {isFeed}
              {/*<img src={metamask} alt="" />*/}
            </div>
          </div>
          <div className="address_btn" >
            <div className="title">Get Coin</div>
            <div className="box metamask">{/*<img src={metamask} alt="" />*/}</div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
export default Airdrop
