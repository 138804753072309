import './Show.css'
import './eth.css'

import React, {useCallback, useEffect, useState} from 'react'
import {DOG_HOST_URL,} from '../../../constants/addresses'
import {useWeb3React} from '@web3-react/core'
import useInterval from "../../../hooks/useInterval";
import {message, Modal, Pagination} from 'antd'
import {formatAddress, formatToUTC, timestampToTime} from "../../../utils/time";
import defaultAvater from "../../../assets/img/default_avater.png";

const Home = () => {
  const { account } = useWeb3React()

    const [current, setCurrent] = useState(1);
    const [total, setTotal] = useState(0)
    const [pageSize, setPageSize] = useState(200)
    const [scriptionList, setScriptionList] = useState([])

    const [currentMy, setCurrentMy] = useState(1);
    const [totalMy, setTotalMy] = useState(0)
    const [pageSizeMy, setPageSizeMy] = useState(8)
    const [myScriptionList, setMyScriptionList] = useState([])
    const [myAvater, setMyAvater] = useState(false);
    const fetchLastTime = useCallback(async () => {
        try {
            const response = await fetch(DOG_HOST_URL + '/ethscription/page?pageSize='+pageSize+'&pageNum=' + current.toString())
            const res = await response.json()
            if (res['code'] === 200) {
                setScriptionList(res['data'])
                setTotal(res['total'])
            }
            if(account){
                const responseMine = await fetch(DOG_HOST_URL + '/ethscription/my?pageSize='+pageSizeMy+'&pageNum=' + currentMy.toString() +'&address=' + account.toLowerCase())
                const resMine = await responseMine.json()
                if (resMine['code'] === 200) {
                    setMyScriptionList(resMine['data'])
                    setTotalMy(resMine['total'])
                }
            }

            if(account){
                const list = await fetch(DOG_HOST_URL + '/api/did/myUid?address=' + account.toString().toLowerCase())
                const resList = await list.json()
                if (resList['code'] === 1) {
                    const listRow = resList['data'];
                    if(listRow.length > 0){
                        const image = listRow[0].mate;
                        setMyAvater(image);
                    }
                }
            }
        } catch (e) {
            console.log(e)
        }
    }, [account, current, pageSize, setTotal, setScriptionList,
        currentMy, pageSizeMy, setTotalMy, setMyScriptionList, setMyAvater])
    useInterval(fetchLastTime, 12000)

    useEffect(() => {
        fetchLastTime();
    }, []);

    const onChange = (page) => {
        setCurrent(page);
    };
    const onShowSizeChange = (current, pageSize) => {
        setPageSize(pageSize);
        setCurrent(current);
    };

    const [goonVisible, setGoonVisible] = useState(false);
    const [goonEns, setGoonEns] = useState({});
    const showRule = (e) => {
        setGoonEns(e);
        setGoonVisible(true);
    };
    const cancelRule = () => {
        setGoonVisible(false);
    };

    const calBlockArea = (x) => {
        if(parseInt(x) === 0)return '0';
        var end = (parseInt(x)) * 100;
        var start = end - 100 + 1;
        return start + '~' + end;
    };


    const onChangeMy = (page) => {
        setCurrentMy(page);
    };
    const onShowSizeChangeMy = (current, pageSize) => {
        setPageSizeMy(pageSize);
        setCurrentMy(current);
    };

  return (
    <div className="eth-all">
        <section className="flex flex-col w-full max-w-7xl">
            <div className="flex flex-col bg-white rounded-2xl shadow-sm overflow-hidden">
                <div className="flex flex-1 flex-col items-start gap-6 p-6 sm:gap-8 sm:p-8">
                    <div className="flex flex-1 flex-row w-full gap-6 sm:gap-8">
                        <div className="flex-1 flex-shrink-0">
                            <img className="common_user-avater" src={myAvater ? myAvater : defaultAvater} alt="" />
                        </div>
                        <div className="flex gap-4 items-start">
                            {/*<button type="button"
                                    className="btn btn-sm w-fit justify-center rounded-md text-sm font-semibold shadow-sm">Settings
                            </button>*/}
                        </div>
                    </div>
                    <div className="flex flex-1 flex-col gap-2"><h2
                        className="font-black tracking-tight mb-0 text-2xl md:text-4xl">{formatAddress(account)}</h2></div>
                </div>
            </div>
        </section>


        <div className="text">My Lands ({totalMy})</div>
        <div className="imageArea">

            <section className="flex flex-col w-full max-w-7xl gap-4">
                <div className="infinite-scroll-component__outerdiv">
                    <div className="infinite-scroll-component !overflow-visible" style1="height: auto; overflow: auto;">
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-6">

                            {myScriptionList.map((item, i) => {
                                return (
                                    /*<div className="imageMyItemDiv" key={i} onClick={() => showRule(item)}>
                                        <img className="imageMyItem" src={item.image} alt={item.name} />
                                        <p className="imageMyText">{item.name}.eth</p>
                                    </div>*/

                                <a key={i} onClick={() => showRule(item)}>
                                    <div
                                        className="flex bg-white flex-col items-start justify-start gap-4 tracking-tight w-full h-full shadow-sm rounded-xl overflow-hidden">
                                        <div className="flex flex-col gap-1 w-full h-full text-lg justify-between relative">
                                            <div className="w-full overflow-hidden">
                                                <div className="aspect-square hover:scale-[1.1] transition duration-300">
                                                    <div className="imageMyItemDiv pointer-events-none">
                                                        <img className="imageMyItem" src={"https://newcryptospace.com/scriptionimage/"+item.id+"_s.png"} alt={item.id + '.eth'} />
                                                        <p className="imageMyText">{item.id}.eth</p>
                                                        {/*<pre className="w-full font-mono aspect-square p-6 bg-gray-200 place-items-center whitspace-pre whitespace-pre-wrap   flex justify-center items-center  break-all overflow-x-auto overflow-y-hidden ">15000.eth</pre>*/}
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<div className="flex flex-col gap-1 p-4">
                                                <h6 className="font-black tracking-tight mb-0 text-sm md:text-md">Ethscription #{item.id}</h6>
                                                <h4 className="font-black tracking-tight mb-0 text-lg md:text-xl">&nbsp;</h4>
                                                <div className="text-xs text-gray-500">&nbsp;</div>
                                            </div>*/}
                                        </div>
                                    </div>
                                </a>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>

        </div>
        <Pagination
            className="pageView"
            total={totalMy}
            showSizeChanger={false}
            defaultPageSize={8}
            defaultCurrent={1}
            current={currentMy}
            onChange={onChangeMy}
            onShowSizeChange={onShowSizeChangeMy}
        />

      <div className="text">EthereumMetaverse Lands ({total})</div>

        <div className="imageArea">
        {scriptionList.map((item, i) => {
            return (
                <div className="imageMyItemDiv" key={i} onClick={() => showRule(item)}>
                    {/*<img className="imageItem" src={item.image} alt={item.name} />*/}
                    <img key={i} className="imageItem" alt={item.id + '.eth'} src={"https://newcryptospace.com/scriptionimage/"+item.id+"_s.png"}/>
                    <p>{item.id}.eth</p>
                </div>
            )
        })}
        </div>


        <Pagination
            className="pageView"
            total={total}
            defaultPageSize={200}
            defaultCurrent={1}
            current={current}
            onChange={onChange}
            onShowSizeChange={onShowSizeChange}
        />

        <Modal
            visible={goonVisible}
            onCancel={cancelRule}
            closable={false}
            centered={true}
            footer={null}
            id="eth_modal_box"
            className="eth_modal_box"
        >
            <div className="title">
                 {goonEns.id}.eth
            </div>

            <div className="image_area">

                <img className="image_pic" src={"https://newcryptospace.com/scriptionimage/"+goonEns.id+".png"} alt={goonEns.id +'.eth'} />

                <div className="image_desc">
                    <div className="desc_item">
                        Block: {calBlockArea(goonEns.id)}
                    </div>
                    <div className="desc_item">
                        Mint Time: {formatToUTC(goonEns.blockTime)}
                    </div>
                    <div className="desc_item">
                        Creator: {goonEns.creator}
                    </div>
                    <div className="desc_item">
                        Owner: {goonEns.owner}
                    </div>
                </div>
            </div>
            <div  className="close_handle_btn" onClick={cancelRule}>close</div>
        </Modal>

    </div>
  )
}
export default Home
