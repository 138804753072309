import {
    cPoint,
    Rubbings,
    standard,
    x20_BillGates,
    x20_CryptoHayes,
    x20_cz_binance,
    x20_elonmusk,
    x20_VitalikButerin
} from "../assets";

export const X20_FACTORY ='0x02739df3276e9412a034d43c17a824292afdac44'
export const INVITER_ADDR = "INVITER_ADDR";
export const TEST_CRYPTO_TOKEN ='0x837c489033271ca7097C4695FA9A5954b739Ab72'

//ALL，V神，奥特曼，赵长鹏，Arthur Hayes（Bitmex创始人，黑人，喊单王，SUSHI就是他喊起来的）
export const x20PubTokenList = [
    {
        id:0,
        name:"SOCIAL",
        symboy:"@SOCIAL(不通缩)",
        token: "0xc38327E970AE1E7958cF1C4096af0CA95Ff02E78",
        icon: "https://newcryptospace.com/favicon.png",
    },
    {
        id:1,
        name:"vitalik.eth",
        symboy:"@VitalikButerin(通缩1%)",
        token: "0x9148e52f6513dd61b7538b99aa71a2ef25729a6d",
        icon: x20_VitalikButerin,
    },
    {
        id:2,
        name:"CZ 🔶 Binance",
        symboy:"@cz_binance",
        token: "0x9b5E7C39295a1f87F4A47b2eE719D14E53730918",
        icon: x20_cz_binance,
    },
    {
        id:3,
        name:"Test Token Name",
        symboy:"Test Token Symbol",
        token: "0xcbdb2e74b01f45eac350634466799256170c81d3",
        icon: x20_CryptoHayes,
    },
    {
        id:4,
        name:"Bill Gates",
        symboy:"@BillGates",
        token: "0x7d1b33b00b7960d950c00b9183669bab3818b7b1",
        icon: x20_BillGates,
    },
    {
        id:5,
        name:"Elon Musk",
        symboy:"@elonmusk",
        token: "0x7d1b33b00b7960d950c00b9183669bab3818b7b1",
        icon: x20_elonmusk,
    },
];

export const x20CpointTokenList = [
    {
        id:-10,
        name:"C Scores",
        symboy:"Cpoints (Part for eligible address)",
        token: "0x7f5e4a77d746ef220156803832c52a0cc11e44be",
        icon: "https://pbs.twimg.com/profile_images/1748153260203229184/sXJIGMBk_400x400.jpg",
    },
    {
        id:0,
        name:"",
        symboy:"",
        token: "",
        icon: "",
    },
    {
        id:0,
        name:"",
        symboy:"",
        token: "",
        icon: "",
    },

];


export const DappsList = [
    {
        style:1,
        name:"STANDARD",
        desc:"STANDARD app",
        url: "/Standard",
        icon: standard,
        open: true
    },
    {
        style:1,
        name:"Cpoints",
        desc:"Cpoints",
        url: "/airdrop/vote",
        icon: cPoint,
        open: true
    },
    {
        style:1,
        name:"Rubbings",
        desc:"Rubbings app",
        url: "/Rubbings/eth",
        icon: Rubbings,
        open: true
    },

    {
        style:0,
        name:"Coming soon",
        desc:"",
        url: "",
        icon: "",
        open: false
    },

];
