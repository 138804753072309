import './admin.css'

import React, { useCallback, useState } from 'react'
import { DOG_HOST_URL } from '../../../constants/addresses'
import useInterval from '../../../hooks/useInterval'
const Admin = (props) => {

  const againstTwo = (t) => {
    if (!t) return "";
    if (t.length <= 25) {
      return t;
    }
    return t.substring(0, 20) + "****" + t.substring(t.length - 4, t.length);
  };

  const [total, setTotal] = useState({})
  const [airdropAList, setAirdropAList] = useState([])
  const [airdropBList, setAirdropBList] = useState([])
  const [airdropCList, setAirdropCList] = useState([])
  const fetchLastTime = useCallback(async () => {
    try {

      const total = await fetch(DOG_HOST_URL + '/api/vote/total')
      const totalInfo = await total.json()
      if (totalInfo['code'] === 1) {
        setTotal(totalInfo);
      }

      const listA = await fetch(DOG_HOST_URL + '/api/vote/userlist?size=100&type=1')
      const resListA = await listA.json()
      if (resListA['code'] === 1) {
        const listRow = resListA['data']
        var tempList = []
        for (var i = 0; i < listRow.length; i++) {
          const one = listRow[i]
          tempList.push({
            id: one['order'],
            address: one['address'] ,
            inviter: one['inviter'],
            types: 'Register',
            time: one['createTime'] + '',
            amount: (one['value'] + one['received']),
          })
        }
        setAirdropAList(tempList);
      }


      const list = await fetch(DOG_HOST_URL + '/api/vote/admnlog?size=100&type=1')
      const resList = await list.json()
      if (resList['code'] === 1) {
        const listRow = resList['data']
        var tempList = []
        for (var i = 0; i < listRow.length; i++) {
          const one = listRow[i]
          tempList.push({
            id: (totalInfo['data2'] - i),
            address: one['address'],
            types: one['type'],
            time: one['createTime'] + '',
            amount: one['value'],
          })
        }
        setAirdropBList(tempList);
      }


      const listc = await fetch(DOG_HOST_URL + '/api/vote/admnlog?size=100&type=2')
      const resListC = await listc.json()
      if (resListC['code'] === 1) {
        const listRow = resListC['data']
        var tempList = []
        for (var i = 0; i < listRow.length; i++) {
          const one = listRow[i]
          tempList.push({
            id: (totalInfo['data3'] - i),
            address: one['address'],
            types: one['type'],
            time: one['createTime'] + '',
            amount: one['value'],
          })
        }
        setAirdropCList(tempList);
      }
    } catch (e) {
      console.log(e)
    }
  }, [setAirdropAList, setAirdropBList, setAirdropCList, setTotal])
  useInterval(fetchLastTime, 3000)


  return (
    <div className="admin">
      <div className="total">
        Plant A Total:{total['data1']}
      </div>
      <div className="total">
        Plant B Total:{total['data2']}
      </div>
      <div className="total">
        Plant C Total:{total['data3']}
      </div>
      <div className="total">
        Plant D Total:{total['data5']}
      </div>
      <div className="total">
        Regist Not Much Total:{total['data4']}
      </div>

      <div className="total">
      </div>

      <div className="title">
        Plant A
      </div>
      <div className="table">
        <div className="thead">
          <div className="tr">
            <div className="td">#</div>
            <div className="td address">address</div>
            <div className="td">quantity</div>
            <div className="td">type</div>
            <div className="td address">time</div>
          </div>
        </div>
        <div className="tbody">
          {airdropAList.map((item, i) => {
            return (
              <div className="tr" key={i}>
                <div className="td">{item.id}</div>
                <div className="td address">
                  <p>{item.address}</p>
                  <p className="inviter">{item.inviter}</p></div>
                <div className="td">{item.amount}</div>
                <div className="td">{item.types}</div>
                <div className="td address">{item.time}</div>
              </div>
            )
          })}
        </div>
      </div>

      <div className="title">
        ETH Holder
      </div>
      <div className="table">
        <div className="thead">
          <div className="tr">
            <div className="td">#</div>
            <div className="td address">address</div>
            <div className="td">quantity</div>
            <div className="td">type</div>
            <div className="td address">time</div>
          </div>
        </div>
        <div className="tbody">
          {airdropBList.map((item, i) => {
            return (
              <div className="tr" key={i}>
                <div className="td">{item.id}</div>
                <div className="td address">{item.address}</div>
                <div className="td">{item.amount}</div>
                <div className="td">{item.types}</div>
                <div className="td address">{item.time}</div>
              </div>
            )
          })}
        </div>
      </div>

      <div className="title">
        BAYC Holder
      </div>

      <div className="table">
        <div className="thead">
          <div className="tr">
            <div className="td">#</div>
            <div className="td address">address</div>
            <div className="td">quantity</div>
            <div className="td">type</div>
            <div className="td address">time</div>
          </div>
        </div>
        <div className="tbody">
          {airdropCList.map((item, i) => {
            return (
              <div className="tr" key={i}>
                <div className="td">{item.id}</div>
                <div className="td address">{item.address}</div>
                <div className="td">{item.amount}</div>
                <div className="td">BAYC Holder</div>
                <div className="td address">{item.time}</div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
export default Admin
